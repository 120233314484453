import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, FormGroup, Row } from "reactstrap";
import BreadCrumb from "../Common/BreadCrumb";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../Utils/Loader";
import { customerIdData } from "../Customers/constants/constantFunctionsView";
import { getUserByName } from "../../slices/UserManagement/GetUserByName/thunk";
import { LabelText } from "../Utils/Input";

export default function ViewUser() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState();
    const isEdit = location.pathname.includes('view-user');
    const userId = customerIdData();
    const initialValues = {
        firstname: "",
        lastname: "",
        username: "",
        userrole: "",
        phone: "",
        email: "",
        status: ""
    };

    useEffect(() => {
        if (isEdit) {
            dispatch(getUserByName(userId, setLoading, initialValues));
        }
    }, [dispatch]);
    const user = useSelector((state) => state.GetAllUserByName.getUsersByName)
    return (
        <React.Fragment>
            <div id='csms' className='page-content'>
                <Container fluid>
                    <BreadCrumb title={"View User"} pageTitle={"User List"} />
                    <ToastContainer position='top-center' />
                    <Row>
                        <Col lg={12}>
                            <div className='listingjs-table' id='customerList'>
                                <Card>
                                    <CardBody className='card-body'>
                                        <div className='live-preview'>
                                            <Row className='gy-4 mt-2'>
                                                <Col xs={12} md={6}>
                                                    <FormGroup className="ms-md-5">
                                                        <p className=" fw-bolder">User ID</p>
                                                        <p>{userId}</p>
                                                    </FormGroup>
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <FormGroup className="mt-2 mt-md-0">
                                                        <p className=" fw-bolder">Email Address</p>
                                                        <p>{user?.email}</p>
                                                    </FormGroup>
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <FormGroup className="ms-md-5">
                                                        <p className=" fw-bolder">User Name</p>
                                                        <p>{user?.userDetails?.userName || ''}</p>
                                                    </FormGroup>
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <FormGroup>
                                                        <p className=" fw-bolder">Phone number</p>
                                                        <p>{user?.dialCode}-{user?.phone}</p>
                                                    </FormGroup>
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <FormGroup className="ms-md-5">
                                                    <p className=" fw-bolder">User Role</p>
                                                        <p>{user?.role}</p>
                                                    </FormGroup>
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <FormGroup>
                                                    <p className=" fw-bolder">Status</p>
                                                        <p>{user?.status === 'active' ? 'Active' : 'In-Active'}</p>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Container>
                {loading && <Loader />}
            </div>
        </React.Fragment>
    );
}
