import React, { useEffect, useState } from 'react'
import { Card, CardBody, Container } from 'reactstrap';
import { Row, Col, Button } from 'reactstrap'
import { MaterialReactTable } from 'material-react-table';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { PAGE_TITLES } from '../Common/constants';
import { setEditSuccessMessage, setSuccessMessage } from '../../slices/toastMessage/action';
import { Loader } from '../Utils/Loader';
import { fetchAllUom } from '../../slices/uom/thunk';
import BreadCrumb from '../Common/BreadCrumb';
import { operatorColoumns } from './Constant/OperatorColoumn';
import { extractedData } from './Constant/OperatorListingJson';
import { MenuItem } from '@mui/material';
import { fetchAllOperatorList } from '../../slices/OperatorManagement/thunk';

function OperatorList() {
    const [loading, setLoading] = useState(false)
    let navigate = useNavigate()
    const columns = operatorColoumns()
    const dispatch = useDispatch()
    const showSuccessMessage = useSelector((state) => state.SuccessToast.showSuccessMessage)
    const showEditSuccessMessage = useSelector((state) => state.SuccessToast.showEditSuccessMessage);

    useEffect(() => {
        dispatch(fetchAllOperatorList(setLoading))
    }, [dispatch])
    const OperatorList = useSelector((state) => state.GetOperatorList.OperatorList)
    const listingData = extractedData(OperatorList);
    const navigateToAddOperator = () => {
        let path = "/create-operator"
        navigate(path)
    }
    useEffect(() => {
        if (showSuccessMessage) {
            toast.success(`Operator Created Successfully`);
            dispatch(setSuccessMessage(false))
        }
        if (showEditSuccessMessage) {
            toast.success(`Operator Updated Successfully`);
            dispatch(setEditSuccessMessage(false));
        }
    }, [showSuccessMessage, showEditSuccessMessage, dispatch])

    const permissions = useSelector((state) => state.permissions.permissions);
    const operatorPermission = permissions.find((resource) => resource.resourceName === 'operator');
    return (
        <React.Fragment>
            <ToastContainer position="top-center" />
            <div id='csms' className='page-content'>
                <Container fluid>
                    <BreadCrumb title={PAGE_TITLES.OPERATOR_LIST} pageTitle={PAGE_TITLES.OPERATOR} />
                    <Row>
                        <Col lg={12}>
                            <div className="listingjs-table " id="customerList">
                                <Card>
                                    <CardBody>
                                        <Row className="mb-3 mt-1 table-responsive">
                                            {loading && (<Loader />)}
                                            {OperatorList ?
                                                <MaterialReactTable
                                                    icons={{
                                                        DragHandleIcon: () => <i className="ri-drag-move-fill" />,
                                                    }}
                                                    enableRowActions
                                                    displayColumnDefOptions={{
                                                        'mrt-row-actions': {
                                                            header: '',
                                                            enableHiding: true
                                                        },
                                                    }}
                                                    renderRowActionMenuItems={({ row, closeMenu }) =>
                                                        [
                                                            <MenuItem key={`${row.index}.1`} onClick={() => { navigate(`/view-operator/${row.original.operatorId}`) }}><i className="ri-eye-line me-2"></i>View</MenuItem>,
                                                            <MenuItem key={`${row.index}.2`} onClick={() => { navigate(`/edit-operator/${row.original.operatorId}`) }} ><i className="bx bxs-edit me-2" /> Edit</MenuItem>
                                                        ]
                                                    }
                                                    muiTableHeadCellProps={{
                                                        sx: {
                                                            fontFamily: "Inter,sans-serif"
                                                        }
                                                    }}
                                                    muiTableBodyCellProps={{
                                                        sx: {
                                                            fontFamily: "Inter,sans-serif"
                                                        }
                                                    }}
                                                    renderTopToolbarCustomActions={({ }) => (
                                                        <div className="d-flex flex-column flex-md-row align-items-md-center">
                                                            {/* <Button color="secondary" className="btn-label me-2 mb-2 mb-md-0" onClick={() => { handleExportData(uom) }}>
                                                                <i className="ri-upload-line label-icon align-middle fs-16 me-2 " ></i> Export Data
                                                            </Button> */}
                                                            {operatorPermission && operatorPermission.permissionList.includes('create') && <Button color="secondary" className="btn-label me-2" onClick={navigateToAddOperator}>
                                                                <i className="ri-add-fill label-icon align-middle fs-16 me-2"></i> Add Operator
                                                            </Button>}
                                                        </div>
                                                    )}
                                                    columns={columns}
                                                    data={listingData}
                                                    enableColumnOrdering
                                                    enableColumnDragging
                                                    enableDensityToggle={false}
                                                />
                                                : null
                                            }
                                        </Row>
                                    </CardBody>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default OperatorList;