import { fetchdeleteresourceSuccess, fetchdeleteresourceFailure } from "./reducer"
import { toast } from "react-toastify";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { DELETE_RESOURCE_API } from "../../constants/constants";

export const deleteresource = (id, setLoading, navigate) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${DELETE_RESOURCE_API}/${id.original.name}`, 'DELETE').then((response) => {
        if (!response.error) {
            dispatch(fetchdeleteresourceSuccess(response))
            setLoading(false)
            toast.success(` Resource Deleted Successfully`)
        }
        if (response.error) {
            dispatch(fetchdeleteresourceFailure(response.error.message))
            setLoading(false)
            toast.error(`${response.error.message}`)
        }
    }).catch((errorResp) => { })
}