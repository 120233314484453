import { Button } from "reactstrap"
import { handleExportData } from "../Utils/ExportToCsv"
import { navigateToAddUser } from "./Constant/JsonConstant"


export const UserCustomButtons = ({ navigate ,Policies}) => {
    return (
        <div className="d-flex flex-column flex-md-row align-items-md-center mb-3">
             <Button color="primary" className="btn-label me-2 mb-2 mb-md-0" onClick={() => navigateToAddUser(navigate)}>
                <i className="ri-add-fill label-icon align-middle fs-16 me-2"></i> Add User
            </Button>
                 <Button color="primary" className="btn-label me-2"   onClick={() => { handleExportData(Policies) }}>
                <i className="ri-download-line label-icon align-middle fs-16 me-2 " ></i> Export Data
            </Button>
        </div>
    )
}