import { Card, CardBody, Col, Container, Row } from "reactstrap"
import BreadCrumb from "../Common/BreadCrumb"
import { ToastContainer } from "react-toastify"
import { OfferFilterAccordion } from "./OfferFilterAccordion"
import React, { useEffect, useState } from "react"
import { PAGE_TITLES } from "../Common/constants"
import { toggleAccordion } from "../OrderManagement/constants/OrderFilterFunctions"
import { filteredResultFunction, groupedFunction, offerServiceData } from "./constants/offerServiceData"
import { Loader } from "../Utils/Loader"
import CommonButton from "../Utils/CommonButton"
import CommonModal from "../Utils/CommonModal"
import { onSubmitCustomerFunction } from "./constants/DocumentTableFunctions"
import { toggleModal, toggleTab } from "./constants/TelcoSubmitFunctions"

export const SubscriptionWhileCustomerCreation = ({ navigate,values,isEdit,subscriptionsLength,setParentLoading,dispatch,customerId,dialCode, customerPermission,roleName, activeTab, passedSteps, setactiveTab, setPassedSteps, setprogressbarvalue,searchableValues,nonSearchableValues }) => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState('1');
    const toggle = toggleAccordion(open, setOpen)
    const [result, setResult] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [startsOn, setStartsOn] = useState(null)
    const [endsOn, setEndsOn] = useState(null)
    const { offerResponse, serviceResponse } = offerServiceData(setLoading);
    const [modal, setModal] = useState(false);
    const [selectedOfferCode,setSelectedOfferCode] = useState()

    useEffect(() => {
        groupedFunction(offerResponse, serviceResponse, setResult)
    }, [offerResponse, serviceResponse]);

    const filteredResults = filteredResultFunction(result, searchTerm)

    return (
        <React.Fragment>
            {loading && (<Loader data-testid="loader" />)}
            <div id='csms'>
                <Container fluid>
                    <ToastContainer position='top-center' />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <div className='row mt-3'>
                                        <div className='col-xl-9 col-md-8 text-md-end'>
                                            <div className='search-box'>
                                                <span className='ri-search-line search-icon'></span>
                                                <input
                                                    type='text'
                                                    className='form-control bg-light'
                                                    placeholder='Search...'
                                                    value={searchTerm}
                                                    onChange={(e) => setSearchTerm(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                                <CardBody>
                                    <OfferFilterAccordion setSelectedOfferCode={setSelectedOfferCode} startsOn={startsOn} setStartsOn={setStartsOn} endsOn={endsOn} setEndsOn={setEndsOn} open={open} toggle={toggle} filteredResults={filteredResults} loading={loading} navigate={navigate} />
                                </CardBody>
                            </Card>
                            <Row lg={6} className=" justify-content-end mb-4">
                                <div className="live-preview">
                                    <div className="d-flex justify-content-end gap-2 ">
                                        <CommonButton outline={true} className={"cancel-button-background"} color={"main-color"} type={"reset"} buttonAction={() => toggleTab(1, 0,activeTab, passedSteps, setactiveTab, setPassedSteps, setprogressbarvalue)} buttonText={"Back"} />
                                        <CommonButton color={"main-color"} type={"button"} buttonAction={() => {
                                            if (startsOn && endsOn) {  onSubmitCustomerFunction(values,isEdit, subscriptionsLength, setParentLoading, dispatch, customerId, navigate, dialCode, roleName,searchableValues,nonSearchableValues,startsOn,endsOn,selectedOfferCode) }
                                            else { toggleModal(setModal,modal) }
                                        }} buttonText={"Confirm"} />
                                    </div>
                                </div>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div>
                <CommonModal messageForPopUp={'Do you want to create a customer without subscription?'} closeButtonAction={()=>{toggleModal(setModal,modal)}} closeButtonText={"No, Subscribe Offer"} fromCustomer={"Customer"} toggle={()=>{toggleModal(setModal,modal)}} open={modal} buttonText={"Yes, Proceed"} modalAction={() => {
                    toggleModal(setModal,modal)
                    onSubmitCustomerFunction(values,isEdit, subscriptionsLength, setParentLoading, dispatch, customerId, navigate, dialCode, roleName,searchableValues,nonSearchableValues)
                }} />
            </div>
        </React.Fragment>
    )
}