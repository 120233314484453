import { MenuItem } from "@mui/material";

export const UserRowOptions = ({navigate, row, setMessageForPopUp, toggleModal, closeMenu,setUserName}) => {
    return (
        [
            <MenuItem className='row-options' data-testid="update" key="view"  onClick={() => { navigate(`/view-user/${row.original.userDetails.userId}`) }} >
            <i className='ri-account-circle-line me-2' /> View </MenuItem>,
            <MenuItem className='row-options' data-testid="update" key="update"  onClick={() => { navigate(`/edit-user/${row.original.userDetails.userId}`) }} >
                <i className='bx bxs-edit me-2' /> Edit </MenuItem>,
            <MenuItem className='row-options' key="delete" onClick={() => { setMessageForPopUp(`Are you sure you want to Delete this User "${row.original.userDetails.userId}"?`), toggleModal(row), closeMenu(), setUserName(row.original.userDetails.userId) }}>
                <i className="ri ri-delete-bin-5-line me-2" />{" "}
                Delete
            </MenuItem>,
        ]
    )
}