import { fetchCreateCustomerSuccess, fetchCreateCustomerFailure } from "./reducer"
import { toast } from "react-toastify"
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { REGISTER_CUSTOMER_API } from "../../constants/constants";
import { setSuccessMessage } from "../../toastMessage/action";

export const createCustomer = (jsonObj, setLoading, navigate) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${REGISTER_CUSTOMER_API}`, 'POST', jsonObj).then((response) => {
        if (!response.error) {
            setLoading(false)
            dispatch(fetchCreateCustomerSuccess(response))
            navigate('/customer')
            dispatch(setSuccessMessage(true))
        }
        if (response.error) {
            setLoading(false)
            toast.error(
                `${response.error.message}`
            )
            dispatch(fetchCreateCustomerFailure(response.error.message))
        }
    }).catch((errorResp) => {
    })
}