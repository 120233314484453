import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    searchCustomer : [],
    loading : false,
    error : null,
};

const fetchSearchCustomerListSlice = createSlice({
    name:"searchCustomer",
    initialState,
    reducers: {
        fetchSearchCustomerSuccess: (state,action) => {
            state.loading = false;
            state.searchCustomer = action.payload
        },
        fetchSearchCustomerFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export const {
    fetchSearchCustomerFailure,
    fetchSearchCustomerSuccess
} = fetchSearchCustomerListSlice.actions

export default fetchSearchCustomerListSlice.reducer