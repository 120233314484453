import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    firstname: Yup.string().required('Kindly Enter the first name'),
    lastname: Yup.string().required('Kindly Enter the last name'),
    username: Yup.string().required('Kindly Enter the user name'),
    userrole: Yup.mixed().required('Kindly Enter the user role name'),
    phone: Yup.number().required('Kindly Enter the phone').test('len', 'Must be exactly 10 digits', val => val?.toString().length === 12),
    email: Yup.string().email('Kindly enter valid email').required('Kindly enter Email'),
    // status: Yup.mixed().required('Kindly Enter the status'),
});