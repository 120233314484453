import React, { useEffect, useState, useMemo } from 'react';
import { Container, Card, CardHeader, Nav, NavItem, NavLink, CardBody, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import { tabChange } from '../Utils/SetDefaultRowData';
import { PAGE_TITLES } from '../Common/constants';
import BreadCrumb from '../Common/BreadCrumb';
import OperatorDetails from './operatorViewTable';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOperatorbyId } from '../../slices/OperatorManagement/GetOperatorbyID/thunk';
import { customerIdData } from '../Customers/constants/constantFunctionsView';
import { OperatorOptionsFields } from './Constant/Options';
import { Loader } from '../Utils/Loader';
import UserListingOperator from './UserListingforOperator';

function OperatorView() {
    const [activeTab, setActiveTab] = useState('1');
    const [loading, setLoading] = useState();
    
    const dispatch = useDispatch()
    const operatorId = customerIdData();
    const initialValues= {}
    useEffect(() => {
        dispatch(fetchOperatorbyId(setLoading, operatorId, initialValues, OperatorOptionsFields));
    }, [dispatch, setLoading]);

    const operatorData = useSelector((state) => state?.GetOperator?.GetOperatorId ?? null);
    return (
        <div id="csms" className="page-content">
            <Container fluid>
                <BreadCrumb title={PAGE_TITLES.VIEW_OPERATOR} pageTitle={PAGE_TITLES.OPERATOR_LIST} />
                <Card className="">
                {loading && (<Loader />)}
                    <CardHeader className="">
                        <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0 " role="tablist">
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '1' })}
                                    onClick={() => {
                                        tabChange('1', activeTab, setActiveTab);
                                    }}
                                >
                                    <i className="fas fa-home"></i>
                                    Overview
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    to="#"
                                    className={classnames({ active: activeTab === '2' })}
                                    onClick={() => {
                                        tabChange('2', activeTab, setActiveTab);
                                    }}
                                    type="button"
                                >
                                    <i className="far fa-envelope"></i>
                                    Users
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </CardHeader>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1" className="border">
                            <OperatorDetails operatorData={operatorData} />
                        </TabPane>
                        <TabPane tabId="2" className="border">
                        <UserListingOperator/>
                            {/* <CardBody className="border-top">
                            </CardBody> */}
                        </TabPane>
                    </TabContent>
                </Card>
            </Container>
        </div>
    );
}

export default OperatorView;
