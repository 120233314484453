import React, { useState, useEffect } from 'react';
import { Card, CardBody, Container, Row, Col } from 'reactstrap';
import { ToastContainer } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';
import BreadCrumb from '../Common/BreadCrumb';
import { Loader } from '../Utils/Loader';
import { useOfferServiceData } from './constants/useOfferServiceData';
import { PAGE_TITLES } from '../Common/constants';
import { filteredResultFunction, groupedFunction, toggleAccordion } from './constants/OrderFilterFunctions';
import { OrderFilterAccordion } from './OrderFilterAccordion';

const OrderDetail = () => {
    const location = useLocation();
    const customerIdPath = location.pathname.slice(location.pathname.lastIndexOf("/"), location.pathname.length)
    const customerId = customerIdPath.slice(1, customerIdPath.length)
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const orderId = location.state?.orderId || ''
    const { offerResponse, serviceResponse, customerData } = useOfferServiceData(customerId, setLoading);
    const [open, setOpen] = useState('1');
    const toggle = toggleAccordion(open,setOpen)
    const [result, setResult] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        groupedFunction(offerResponse, serviceResponse,setResult,customerId,customerData,orderId)
    }, [offerResponse, serviceResponse]);

    const filteredResults = filteredResultFunction(result,searchTerm)

    return (
        <React.Fragment>
            {loading && (<Loader data-testid="loader" />)}
            <div id='csms' className='page-content'>
                <Container fluid>
                    <BreadCrumb title={PAGE_TITLES.CUSTOMER_SUBSCRIBE} pageTitle={PAGE_TITLES.CUSTOMER_LIST}/>
                    <ToastContainer position='top-center' />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <div className='row mt-3'>
                                        <div className='col-xl-9 col-md-8 text-md-end'>
                                            <div className='search-box'>
                                                <span className='ri-search-line search-icon'></span>
                                                <input
                                                    type='text'
                                                    className='form-control bg-light'
                                                    placeholder='Search...'
                                                    value={searchTerm}
                                                    onChange={(e) => setSearchTerm(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                                <CardBody>
                                    <OrderFilterAccordion open={open} toggle={toggle} filteredResults={filteredResults} loading={loading} navigate={navigate}/>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default OrderDetail;
