import { determineBaseUrlAndPerformApiCall } from "../interceptor/interceptor";
import { UOM_LISTING_API } from "../constants/constants";
import { fetchUomSuccess, fetchUomFailure } from "./reducer";

export const fetchAllUom = (setLoading) => async (dispatch) => {
    try {
        setLoading(true)
        const response = await determineBaseUrlAndPerformApiCall(`${UOM_LISTING_API}`,'GET')
        setLoading(false)
        dispatch(fetchUomSuccess(response.reverse()))
    }
    catch (error) {
        setLoading(false)
        dispatch(fetchUomFailure(error.message))
    }
}