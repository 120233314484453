import React, { useEffect, useState, useMemo } from 'react'
import { Card, CardBody, CardHeader, Container } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { Row, Col, Button } from 'reactstrap'
import { MRT_TopToolbar, MaterialReactTable } from 'material-react-table';
import { handleExportData } from '../../Utils/ExportToCsv';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllUom } from '../../../slices/uom/thunk';
import { uomColoumns } from './constants/coloumn';
import { Loader } from '../../Utils/Loader';
import { ToastContainer, toast } from 'react-toastify';
import { setSuccessMessage } from '../../../slices/toastMessage/action';
import { PAGE_TITLES } from '../../Common/constants';

function UomListings() {
    const [loading, setLoading] = useState(false)
    let navigate = useNavigate()
    const columns = uomColoumns()
    const dispatch = useDispatch()
    const showSuccessMessage = useSelector((state) => state.SuccessToast.showSuccessMessage)
    useEffect(() => {
        dispatch(fetchAllUom(setLoading))
    }, [dispatch])
    const uom = useSelector((state) => state.Uom.uom)
    const navigateToAddUnit = () => {
        let path = "/create-unit"
        navigate(path)
    }
    useEffect(() => {
        if (showSuccessMessage) {
            toast.success(`UOM Created Successfully`);
            dispatch(setSuccessMessage(false))
        }
    }, [showSuccessMessage, dispatch])

    const permissions = useSelector((state) => state.permissions.permissions);
    const uomPermission = permissions.find((resource) => resource.resourceName === 'uom');
    return (
        <React.Fragment>
            <div id='csms' className='page-content'>
                <Container fluid>
                    <BreadCrumb title={PAGE_TITLES.UOM_LIST} pageTitle={PAGE_TITLES.PRICING} />
                    <ToastContainer position='top-center' />
                    <Row>
                        <Col lg={12}>
                            <div className="listingjs-table " id="customerList">
                                <Card>
                                    <CardBody>
                                        <Row className="mb-3 mt-1 table-responsive">
                                            {loading && (<Loader />)}
                                            {uom ?
                                                <MaterialReactTable
                                                    icons={{
                                                        DragHandleIcon: () => <i className="ri-drag-move-fill" />,
                                                    }}
                                                    muiTableHeadCellProps={{
                                                        sx: {
                                                            fontFamily: "Inter,sans-serif"
                                                        }
                                                    }}
                                                    muiTableBodyCellProps={{
                                                        sx: {
                                                            fontFamily: "Inter,sans-serif"
                                                        }
                                                    }}
                                                    renderTopToolbarCustomActions={({ }) => (
                                                        <div className="d-flex flex-column flex-md-row align-items-md-center">
                                                            <Button color="secondary" className="btn-label me-2 mb-2 mb-md-0" onClick={() => { handleExportData(uom) }}>
                                                                <i className="ri-upload-line label-icon align-middle fs-16 me-2 " ></i> Export Data
                                                            </Button>
                                                            {uomPermission && uomPermission.permissionList.includes('create') && <Button color="secondary" className="btn-label me-2" onClick={navigateToAddUnit}>
                                                                <i className="ri-add-fill label-icon align-middle fs-16 me-2"></i> Add Unit
                                                            </Button>}
                                                        </div>
                                                    )}
                                                    columns={columns}
                                                    data={uom}
                                                    enableColumnOrdering
                                                    enableColumnDragging
                                                    enableDensityToggle={false}
                                                />
                                                : null
                                            }
                                        </Row>
                                    </CardBody>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default UomListings;