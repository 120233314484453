import { editGrantsAndChargesFunction, editGrantsAndChargesRecurringFunction } from "../../../Components/Pricings/Offers/constants/GrantsAndRecurringFunctions"
import { displayCeilingAndFloorForOptions, displayInfiniteForOptions, handleProrateState } from "../../../Components/Pricings/Offers/constants/displayInfinite"
import { SubscriptionEndsOnOptions, SubscriptionStartsOnOptions, TimePeriodEndOptions, TimePeriodOptions, pricingModelOptions, recurringFrequencyOptions } from "../../../Components/Pricings/Offers/constants/initialValues"
import { GET_OFFER_API } from "../../constants/constants"
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor"
import { fetchResourceSuccess } from "../../resources/reducer"
import { fetchEditOfferSuccess, fetchEditOfferFailure } from "./reducer"
import moment from "moment"

export const getOffer = (setLoading, offerCode, isEdit, initialValues, resourceData, serviceData, setInfinite, formValues, setCeiling, setFloor, setFormValues, setStop, stop, setChargeCeiling, setChargeFloor, setChargeInfinite, setRecurringCeiling, setRecurringInfinite, setRecurringFloor, setChargeRecurringCeiling, setChargeRecurringFloor, setChargeRecurringInfinite) => async (dispatch) => {
    setLoading(true)
    
    if (!stop) {
        await determineBaseUrlAndPerformApiCall(`${GET_OFFER_API}/${offerCode}`, 'GET').then((response) => {
            // dispatch(fetchEditOfferSuccess(response))

            if (!response.error) {
                setLoading(false)
                dispatch(fetchEditOfferSuccess(response))
                initialValues.offerName = response.offerName
                initialValues.offerCode = response.offerCode
                initialValues.description = response.description
                initialValues.offerStartsOn = moment(response.validity.from);
                if (response.validity?.to) {
                    initialValues.offerEndsOn = moment(response.validity.to);
                }

                if (response?.serviceCode) {
                    if (serviceData) {
                        const matchedService = serviceData.find(value => response?.serviceCode === value?.serviceCode);
                        if (matchedService) {
                            initialValues.serviceName = { value: matchedService.serviceCode, label: matchedService.name };
                        }
                    }
                }
                initialValues.policyName={ value: response?.policySpecification[0], label: response?.policySpecification[0]}
                initialValues.subscriptionStartsOn = SubscriptionStartsOnOptions.find(option => option.value === response.subscriptionValidity.startTimeType)
                initialValues.subscriptionEndsOn = SubscriptionEndsOnOptions.find(option => option.value === response.subscriptionValidity.endTimeType)
                initialValues.timePeriodStart = response.subscriptionValidity.relativeStartTimeValue
                initialValues.timePeriod = response.subscriptionValidity.relativeEndTimeValue
                initialValues.timePeriodOptionStart = TimePeriodOptions.find(option => parseInt(option.value) === response.subscriptionValidity.relativeStartTimeUnit)
                initialValues.timePeriodOption = TimePeriodEndOptions.find(option => parseInt(option.value) === response.subscriptionValidity.relativeEndTimeUnit)


                if ("Charges" in response) {
                    initialValues.charges = []
                    initialValues.recurringCharges = []
                    response.Charges.forEach((resp, index) => {
                        let ceiling = 0;
                        let floor = 0;
                        const matchingResource = resourceData.find(res => res.name === resp.resourceName);
                        if (matchingResource) {
                            ceiling = matchingResource.ceiling;
                            floor = matchingResource.floor;
                        }
                        if (resp.type === 1) {
                            editGrantsAndChargesFunction(initialValues.charges,resp,ceiling,floor)
                            displayInfiniteForOptions({ value: resp.resourceName, label: resp.resourceName }, index, resourceData, setChargeInfinite)
                            displayCeilingAndFloorForOptions({ value: resp.resourceName, label: resp.resourceName }, index, formValues?.charges[index], resourceData, setChargeCeiling, setChargeFloor)
                        }
                        else if (resp.type === 2) {
                            editGrantsAndChargesRecurringFunction(initialValues.recurringCharges,resp,ceiling,floor,recurringFrequencyOptions)
                            displayInfiniteForOptions({ value: resp.resourceName, label: resp.resourceName }, index, resourceData, setChargeRecurringInfinite)
                            displayCeilingAndFloorForOptions({ value: resp.resourceName, label: resp.resourceName }, index, formValues?.recurringCharges[index], resourceData, setChargeRecurringCeiling, setChargeRecurringFloor)
                        }
                    })
                }
                if ("Grants" in response) {
                    // let ceiling, floor
                    initialValues.grants = []
                    initialValues.recurringGrants = []
                    response.Grants.forEach((resp, index) => {

                        let ceiling = 0;
                        let floor = 0;
                        const matchingResource = resourceData.find(res => res.name === resp.resourceName);
                        if (matchingResource) {
                            ceiling = matchingResource.ceiling;
                            floor = matchingResource.floor;
                        }
                        if (resp.type === 1) {
                            editGrantsAndChargesFunction(initialValues.grants,resp,ceiling,floor)
                            displayInfiniteForOptions({ value: resp.resourceName, label: resp.resourceName }, index, resourceData, setInfinite)
                            displayCeilingAndFloorForOptions({ value: resp.resourceName, label: resp.resourceName }, index, formValues?.grants[index], resourceData, setCeiling, setFloor)
                        }
                        else if (resp.type === 2) {
                            editGrantsAndChargesRecurringFunction(initialValues.recurringGrants,resp,ceiling,floor,recurringFrequencyOptions)
                            handleProrateState(index,formValues)
                            displayInfiniteForOptions({ value: resp.resourceName, label: resp.resourceName }, index, resourceData, setRecurringInfinite)
                            displayCeilingAndFloorForOptions({ value: resp.resourceName, label: resp.resourceName }, index, formValues?.recurringGrants[index], resourceData, setRecurringCeiling, setRecurringFloor)
                        }
                    })
                }
                if ("usageRatePlan" in response) {
                    initialValues.usageRatePlan = []
                    response.usageRatePlan.forEach((resp, index) => {
                        initialValues.usageRatePlan.push({
                            "usageId": resp.id,
                            "eventType": resp.eventType,
                            "resourceName": { value: resp.resourceName, label: resp.resourceName },
                            "unitOfMeasurement": { value: resp.uomName, label: resp.uomName },
                            "priority": resp.priority,
                            "perUnitRate": resp.rateTier[0].perUnitRate,
                            "pricingModel": resp.rateTier[0].tierType
                            ? pricingModelOptions.find(option => option.value === resp.rateTier[0].tierType)
                            : null,
                                          "flatRate": (resp.rateTier[0].flatRate).toString()
                        })
                    })
                }
                setFormValues(initialValues)
                setStop(true)
                // dispatch(fetchResourceSuccess([]))
            }
        }).catch((error) => {
            dispatch(fetchEditOfferFailure(error.message))
        })
    }
    setLoading(false)
}