import { getCellStyle } from "../../Utils/DecideColor";
import { Box } from "@mui/material";

export const userColoumns = () => {
    const columns = [
      {
        accessorKey: 'userDetails.userId',
        header: 'User ID',
      },
      {
        accessorKey: 'userDetails.userName',
        header: 'User Name',
      },
      {
        accessorFn: (row) => `${row.profile.firstName} ${row.profile.lastName}`,
        header: 'Full Name',
      },
      {
        accessorKey: 'role',
        header: 'User Role',
      },
      {
        accessorKey: 'email',
        header: 'Email',
      },
      {
        accessorKey: 'phone',
        header: 'Phone',
      },
      {
        accessorKey: 'status',
        header: 'Status',
        Cell: ({ cell }) => {
          const value = cell.getValue();
          let displayValue;
          if (!value) {
            displayValue = ""; 
          } else {
            displayValue = value === 'active' ? 'Active' : 'Inactive';
          }
          return (
            <Box
              sx={(theme) => (value ? {
                ...getCellStyle(value, "User"),
              } : {})}
            >
              {displayValue}
            </Box>
          );
        }
      },
    ];
  
    return columns;
  };