import { fetchEditPartnerSuccess, fetchEditPartnerFailure } from "./reducer"
import { toast } from "react-toastify"
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { EDIT_PARTNER_API } from "../../constants/constants";
import { setEditSuccessMessage } from "../../toastMessage/action";

export const editPartner = (jsonObj, setLoading, navigate, PartnerId) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${EDIT_PARTNER_API}/${PartnerId}`, 'PATCH', jsonObj).then((response) => {
        if (!response.error) {
            setLoading(false)
            dispatch(fetchEditPartnerSuccess(response))
            navigate('/partner-listing')
            dispatch(setEditSuccessMessage(true))
        }
        if (response.error) {
            setLoading(false)
            toast.error(
                `${response.error.message}`
            )
            dispatch(fetchEditPartnerFailure(response.error.message))
        }
    }).catch((error) => { 
        setLoading(false);
        toast.error("An error occurred. Please try again later.");
    })
}