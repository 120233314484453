export const getCellStyle = (cellValue, module) => {
    let style = {};
    if (module !== "Service") {
        style.borderRadius = (module === "InvoiceForCustomer" || module === "InvoiceForAll" || module === "Device") ? '0.65rem' : (module === "BillsForCustomer" || module === "BillsForPartner") ? "0.60rem" : module === "Resource" ? "0.55rem" : '0.35rem'
        style.maxWidth = (module === "InvoiceForCustomer" || module === "InvoiceForAll") ? "auto" : module === "Device" ? "12ch" : (module === "ViewOrder" || module === "CreateOrder") ? "13ch" : module === "OverallOrders" ? "15ch" : module === "Resource" ? "14ch" : '9ch'
        style.display = "flex"
        style.justifyContent = "center"
        style.alignItems = "center"
        style.padding = module === "OverallOrders" ? "0px" : module === "Resource" ? "5px" : "1px"
        if (module === "Resource") {
            style.fontSize = "14px"
        }
    }
    switch (cellValue) {
        case 'validated':
        case 'Approved':
        case 'close':
        case 'paid':
        case 'Assigned':
        case 'completed':
        case 'Accepted':
        case 'Active':
        case 'active':
            style.border = '2px solid #0AB39C';
            style.color = '#0AB39C';
            break;
        case 'closed':
        case 'Rejected':
        case 'cancelled':
        case 'Closed':
        case 'failed':
        case 'Terminated':
        case 'Inactive':
            style.border = '2px solid #F06547';
            style.color = '#F06547';
            break;
        case 'initialized':
            style.border = '2px solid #FFB800';
            style.color = '#FFB800';
            break;
        case 'partially_paid':
            style.border = '2px solid #FFB800';
            style.color = '#FFB800';
            break;
        case 'unpaid':
        case 'Unassigned':
        case 'acknowledged':
        case 'Acknowledged':
        case 'In Progress':
        case 'inProgress':
            style.border = '2px solid #3478F1';
            style.color = '#3478F1';
            break;
        case 'pending':
        case 'inactive':
            style.border = '2px solid #F6B84B';
            style.color = '#F6B84B';
            break;
        case 'open':
            style.border = '2px solid #FF7D05'
            style.color = '#FF7D05'
            break;
        case 'Provisioning':
        case 'Usage':
        case 'Charge':
        case 'Grant':
            style.border = `2px solid ${module !== "Offer" && module !== "Service" ? '#3478F1' : '#0078A3'}`
            style.color = `${module !== "Offer" && module !== "Service" ? '#3478F1' : '#0078A3'}`
            break;
        case 'Non-Monetary':
        case 'Monetary':
            style.border = `2px solid #0078A3`
            style.color = `#0078A3`
            break;
        default:
            break;
    }

    return style;
}
