import { Container, Card, Col, Row, CardBody } from "reactstrap";
import { PAGE_TITLES } from "../Common/constants";
import BreadCrumb from "../Common/BreadCrumb";
import DonutChart from "./ApexpieChart";
import AreaChart from "./AreaChart";
import BarChart from "./BarChart";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getMvnoDashboard } from "../../slices/MvnoDashboard/thunk";
import { usePlans } from "../LightWeightCustomer/Constant/dashboardUtil";
import { Loader } from "../Utils/Loader";
import noData from '../../assets/images/NoData.gif';
import { setWelcomeToast } from "../../slices/welcomeToaster/action";
import { ToastContainer, toast } from "react-toastify";
import { formatNumber } from "./constantFunctions";

export default function MvnoDashboard() {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();
    let CombinedData = []
    useEffect(() => {
        dispatch(getMvnoDashboard(setLoading))
    }, []);
    const mvnodashbaordvalue = useSelector((state) => state.GetMvnoDashboard.getMvnoDashboard)
    const showWelcomeToast = useSelector(function (state) { return state.welcomeToast.showWelcomeToast; });

    const plans = usePlans(setLoading);
    const initialMonthlyData = mvnodashbaordvalue[0]
    if (mvnodashbaordvalue.length > 0) {
        CombinedData = [
            ...initialMonthlyData.activeCustomers.monthlyBreakdown,
        ];
    }
    CombinedData.sort((a, b) => {
        if (a.Year !== b.Year) {
            return a.Year - b.Year;
        } else {
            const monthsOrder = [
                "January", "February", "March", "April",
                "May", "June", "July", "August",
                "September", "October", "November", "December"
            ];
            return monthsOrder.indexOf(a.Month) - monthsOrder.indexOf(b.Month);
        }
    });
    const ActivecustomerData = CombinedData.map(data => ({
        x: data.Month,
        y: initialMonthlyData.activeCustomers.monthlyBreakdown.find(item => item.Month === data.Month && item.Year === data.Year)?.CustomerCount || 0,
    }));
    const inactiveCustomerdata = CombinedData.map(data => ({
        x: data.Month,
        y: initialMonthlyData.inactiveCustomers.monthlyBreakdown.find(item => item.Month === data.Month && item.Year === data.Year)?.CustomerCount || 0
    }));
    const offerNames = Array.isArray(plans) ? plans.map(offer => offer?.offerName) : [];
    const offerCounts = Array.isArray(plans) ? plans.map(offer => offer?.count) : [];
    const PieChartActiveCustomerData = initialMonthlyData?.activeCustomers?.totalCustomers || 0;
    const PieChartInactiveCustomerData = initialMonthlyData?.inactiveCustomers?.totalCustomers || 0;
    const PieChartTotalData = PieChartActiveCustomerData + PieChartInactiveCustomerData;
    
    useEffect(function () {
        if (showWelcomeToast) {
            toast.success(`Welcome ${localStorage.getItem('userId')}`);
            dispatch(setWelcomeToast(false));
        }
    }, [showWelcomeToast, dispatch]);

    const formattedNumber = formatNumber(PieChartTotalData);

    return (
        <div className="page-content">
            <Container fluid>
                <ToastContainer position='top-center' />
                {loading && <Loader />}
                <BreadCrumb title={PAGE_TITLES.MVNO_DASHBOARD} />
                <Row>
                    <Col xxl={4} className="mb-3">
                        <Card className="h-100">
                            <div className="">
                                <div className="d-flex justify-content-between">
                                    <div className="fs-5 me-3 ms-3 mt-3 fw-semibold text-black-50">Total Customers</div>
                                </div>
                                <Row className="mt-4 ms-1">
                                    <Col xxl={6}>
                                        <div className="mt-3 position-relative">
                                            <p className="fs-2 fw-semibold mvno-text-color position-absolute top-50 start-50 translate-middle mt-n2">{formattedNumber}</p>
                                            <p className="fs-6 fw-semibold mt-3 position-absolute top-50 start-50 translate-middle">customers</p>
                                            <DonutChart PieChartActiveCustomerData={PieChartActiveCustomerData} PieChartInactiveCustomerData={PieChartInactiveCustomerData} />
                                        </div>
                                    </Col>
                                    <Col xxl={6} className="text-center text-xxl-start">
                                        <div className="d-flex flex-column align-items-center align-items-xxl-start">
                                            <div className="d-flex align-items-center mt-5">
                                                <span className="color-box" style={{ backgroundColor: '#0078A3' }}></span>
                                                <div className="fs-6 fw-medium ms-2">Active Customers</div>
                                            </div>
                                            <div className="d-flex justify-content-center w-50 ">
                                                <div className="fs-2 fw-semibold mvno-text-color">
                                                    {mvnodashbaordvalue[0]?.activeCustomers?.totalCustomers || 0}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column mt-3 align-items-center align-items-xxl-start">
                                            <div className="d-flex align-items-center mt-3">
                                                <span className="color-box" style={{ backgroundColor: '#C5C5C5' }}></span>
                                                <div className="fs-6 fw-medium ms-2">Closed Customers</div>
                                            </div>
                                            <div className="d-flex justify-content-center w-50">
                                                <div className="fs-2 fw-semibold mvno-text-color">{mvnodashbaordvalue[0]?.inactiveCustomers?.totalCustomers || 0}</div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    </Col>
                    <Col xxl={4} className=" mb-3">
                        <Card className="h-100">
                            <div className="">
                                <div className="d-flex justify-content-between">
                                    <div className="fs-5 me-3 ms-3 mt-3 fw-semibold text-black-50">New Customers</div>
                                </div>
                            </div>
                            <Row className="px-4 pt-3 mvno-dashboard-height">
                                <Col xxl={6} className="mb-3 mb-xxl-0">
                                    <div className="mb-3 border border-1 border-dark-subtle rounded-2 h-100 align-content-center" >
                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                            <div className="fs-5 me-3 ms-3 mt-3 fw-semibold mvno-text-color">Month To Date</div>
                                            <div className="fs-1 me-3 ms-3 mt-3 fw-semibold mvno-text-color">{mvnodashbaordvalue[0]?.activeCustomers?.currentMonthTotal || 0}</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xxl={6}>
                                    <div className="mb-3 border border-1 border-dark-subtle rounded-2 h-100 align-content-center" >
                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                            <div className="fs-5 me-3 ms-3 mt-3 fw-semibold mvno-text-color">Year To Date</div>
                                            <div className="fs-1 me-3 ms-3 mt-3 fw-semibold mvno-text-color">{mvnodashbaordvalue[0]?.activeCustomers?.currentYearTotal || 0}</div>

                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col xxl={4} className="mb-3 ">
                        <Card className="h-100">
                            <div className="">
                                <div className="d-flex justify-content-between">
                                    <div className="fs-5 me-3 ms-3 mt-3 fw-semibold text-black-50">Closed Customers</div>
                                </div>
                            </div>
                            <Row className="px-4 pt-3 mvno-dashboard-height">
                                <Col xxl={6} className="mb-3 mb-xxl-0" >
                                    <div className="mb-3 border border-1 border-dark-subtle rounded-2 h-100 align-content-center" >
                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                            <div className="fs-5 me-3 ms-3 mt-3 fw-semibold mvno-text-color" >Month To Date</div>
                                            <div className="fs-1 me-3 ms-3 mt-3 fw-semibold mvno-text-color">{mvnodashbaordvalue[0]?.inactiveCustomers?.currentMonthTotal || 0}</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xxl={6}>
                                    <div className="mb-3 border border-1 border-dark-subtle rounded-2 h-100 align-content-center" >
                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                            <div className="fs-5 me-3 ms-3 mt-3 fw-semibold mvno-text-color">Year To Date</div>
                                            <div className="fs-1 me-3 ms-3 mt-3 fw-semibold mvno-text-color">{mvnodashbaordvalue[0]?.inactiveCustomers?.currentYearTotal || 0}</div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <div className=" d-flex justify-content-between">
                                    <p className=" ps-2 fw-semibold text-black-50 ">Newly Added and Closed Customers</p>
                                    <p className=" d-flex justify-content-center align-items-end text-black-50">Trailing 6 months <i className="ri-error-warning-fill ms-2 icon-lg"></i></p>
                                </div>
                                <div>
                                    {ActivecustomerData && inactiveCustomerdata && ActivecustomerData.length > 0 && inactiveCustomerdata.length > 0 ? (
                                        <AreaChart activeCustomerData={ActivecustomerData} inactiveCustomerData={inactiveCustomerdata} />
                                    ) : (
                                        <div style={{ height: '400px' }} className='d-flex align-items-center justify-content-center'>
                                            <img src={noData} width={320} alt="No Data" />
                                        </div>
                                    )}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <div className=" d-flex justify-content-between">
                                    <p className=" ps-2 fw-semibold text-black-50 ">Top Plans</p>
                                    <p className=" d-flex pe-2 justify-content-center align-items-end text-black-50"><i className="ri-error-warning-fill ms-2 icon-lg"></i> Data of last six months</p>
                                </div>
                                <div>
                                    {offerCounts && offerNames && offerCounts.length > 0 && offerNames.length > 0 ? (
                                        <BarChart offerNames={offerNames} offerCounts={offerCounts} />
                                    ) : (
                                        <div style={{ height: '400px' }} className='d-flex align-items-center justify-content-center'>
                                            <img src={noData} width={320} alt="No Data" />
                                        </div>
                                    )}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>

                </Row>
            </Container>
        </div>
    )
}