import React from 'react';
import { Card, CardBody, Col, Row, FormGroup, CardHeader } from 'reactstrap';
import formSchema from '../Utils/schema.json';
import { RenderField } from '../Utils/renderFields';
import { RenderErrorMessage } from '../Utils/renderErrorMessages';
import { LabelText } from '../Utils/Input';
import { userOptions } from './Constant/UserOptions';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

export default function UserDetails({ values, handleChange, handleBlur, errors, touched, setFieldTouched, setFieldValue, isEdit,user }) {

    const renderStatusBadge = (status) => {
        switch (status) {
            case 'inactive':
                return <span className="badge bg-warning-subtle text-warning">Inactive</span>;
            case 'active':
                return <span className="badge bg-success-subtle text-success">Active</span>;
            default:
                return <span className="badge bg-success-subtle text-success">Active</span>;
        }
    };
    return (

        <div className="live-preview">

            {formSchema.map((section, sectionIndex) => (
                <>
                    {section.title === "User" && section.sections &&
                        section.sections.map((subSection, subSectionIndex) => (
                            <Card>
                                {subSection?.sectionTitle && <CardHeader className="align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">
                                    {subSection?.sectionTitle}
                                    {isEdit && subSection?.sectionTitle === "User Information" && user?.status && (
                                                    <span style={{ marginLeft: '10px' }}>{renderStatusBadge(user?.status)}</span>
                                                )}</h4>
                                </CardHeader>}
                                <CardBody>
                                    <Row className='gy-4'>

                                        {subSection.fields.filter(field => {
                                            if (isEdit && (field.name === "username" || field.name === "email")) {
                                                field.disable = true
                                            }
                                            else {
                                                field.disable = false
                                            }
                                            if (field.name === "status" && !isEdit) {
                                                return false
                                            }
                                            return true
                                        }).map((field, fieldIndex) => (

                                            <Col
                                                xxl={field.name === "description" ? 12 : 6}
                                                md={field.name === "description" ? 12 : 6}
                                                lg={field.name !== "description" ? 12 : 6}
                                                key={`${subSectionIndex}-${fieldIndex}`}
                                            >
                                                <LabelText
                                                    htmlFor={field.name}
                                                    className={field.required ? "text-danger" : ""}
                                                    displayText={field.label}
                                                    important={field.required ? "*" : ""}
                                                />
                                                {field.type === 'phone' ? (
                                                    <FormGroup>
                                                        <PhoneInput
                                                            international
                                                            countryCallingCodeEditable={false}
                                                            className={`form-control ${touched[field.name] && errors[field.name] ? 'error-input' : ''}`}
                                                            placeholder={field.placeholder}
                                                            value={values[field.name]}
                                                            onChange={(value) => setFieldValue(field.name, value)}
                                                            onBlur={() => setFieldTouched(field.name, true)}
                                                            disabled={field?.autoload && field.isEdit}
                                                        />
                                                        {touched[field.name] && errors[field.name] && (
                                                            <div className="text-danger validation-font-size">
                                                                {errors[field.name]}
                                                            </div>
                                                        )}
                                                    </FormGroup>
                                                ) : (
                                                    <>
                                                        <RenderField
                                                            field={field}
                                                            handleChange={handleChange}
                                                            handleBlur={handleBlur}
                                                            values={values}
                                                            touched={touched}
                                                            errors={errors}
                                                            setFieldTouched={setFieldTouched}
                                                            setFieldValue={setFieldValue}
                                                            options={userOptions}
                                                        />
                                                        <RenderErrorMessage
                                                            field={field}
                                                            touched={touched}
                                                            errors={errors}
                                                        />
                                                    </>
                                                )}
                                            </Col>
                                        ))}
                                    </Row>
                                </CardBody>
                            </Card>
                        ))
                    }

                </>
            )
            )
            }
        </div>
    );
}