import { FieldArray } from "formik";
import { Button, Card, CardBody, CardHeader, Table } from "reactstrap";
import SelectField from "../Utils/SelectField";
import InputField from "../Utils/InputField";
import { TablesWithoutBorders } from "../../pages/Tables/BasicTables/BasicTablesCode";
import { isEmptyOrInvalidFieldForCommission } from "./Constants/ModifyDataFunctions";
import { ChargesDropDownOptions, CommissionTypeDropDownOptions } from "./Constants/DropdownOptions";

export const SettlementCommissionRules = ({ values, setTouched, touched, errors, initialValues, setFieldValue, handleChange }) => {
    return (
        <FieldArray name="commissionRules">
            {({ push, remove }) => (
                <Card>
                    <CardHeader className="align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">Commission Rules</h4>
                        <Button type="button" color="secondary" className="btn-label"
                            onClick={() => {
                                const lastIndex = values.commissionRules.length - 1;
                                if (lastIndex === -1 || !isEmptyOrInvalidFieldForCommission(lastIndex, values)) {
                                    push({ charge: "", type: "", value: "" });
                                    setTouched({
                                        ...touched,
                                        commissionRules: {
                                            ...touched.commissionRules,
                                            [lastIndex]: { charge: false, type: false, value: false, },
                                        },
                                    });
                                }
                            }}
                        >{" "}<i className="ri-add-fill label-icon align-middle fs-16 me-2"></i>{" "}Add{" "}
                        </Button>
                    </CardHeader>
                    <CardBody>
                        <div className="live-preview">
                            <div className="table-responsive">
                                <div className="scrollable-view">
                                    <Table className="table-borderless align-middle table-nowrap mb-0">
                                        <thead>
                                            <tr>
                                                <th scope="col ">Charges<span className="text-danger">*</span></th>
                                                <th scope="col">Commission Type<span className="text-danger">*</span></th>
                                                <th scope="col ">Commission Value<span className="text-danger">*</span></th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {values.commissionRules.length === 0 ? (<tr className="default-height"><td colSpan={4} className="text-center" >No Commission Rules</td></tr>)
                                                : (values.commissionRules.map((val, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td className="w-lg">
                                                                <SelectField
                                                                    className={(touched.commissionRules && touched.commissionRules[index] && !values.commissionRules[index].charge) || false ? 'error-input' : ''}
                                                                    name={`commissionRules[${index}].charge`}
                                                                    value={initialValues.commissionRules[index]?.charge ?
                                                                        ChargesDropDownOptions?.filter((charge) => [
                                                                            charge.label === initialValues.commissionRules[index]
                                                                        ]) : values.commissionRules[index].charge}
                                                                    handleChange={(selectedValue) => { values.commissionRules[index].charge = selectedValue, setFieldValue('charge', selectedValue) }}
                                                                    options={ChargesDropDownOptions} />
                                                                {errors.commissionRules && errors.commissionRules[index] && touched.commissionRules && touched.commissionRules[index] && touched.commissionRules[index].charge ? (
                                                                    <p className="text-danger">
                                                                        {errors.commissionRules[index].charge}
                                                                    </p>
                                                                ) : null}
                                                            </td>
                                                            <td>
                                                                <SelectField
                                                                    className={(touched.commissionRules && touched.commissionRules[index] && !values.commissionRules[index].type) || false ? 'error-input' : ''}
                                                                    name={`commissionRules[${index}].type`}
                                                                    value={initialValues.commissionRules[index]?.type ?
                                                                        CommissionTypeDropDownOptions?.filter((type) => [
                                                                            type.label === initialValues.commissionRules[index]
                                                                        ]) : values.commissionRules[index].type}
                                                                    handleChange={(selectedValue) => { values.commissionRules[index].type = selectedValue, setFieldValue('type', selectedValue) }}
                                                                    options={CommissionTypeDropDownOptions} />
                                                                {errors.commissionRules && errors.commissionRules[index] && touched.commissionRules && touched.commissionRules[index] && touched.commissionRules[index].type ? (
                                                                    <p className="text-danger">
                                                                        {errors.commissionRules[index].type}
                                                                    </p>
                                                                ) : null}
                                                            </td>
                                                            <td>
                                                                <InputField
                                                                    name={`commissionRules[${index}].value`}
                                                                    className={(touched.commissionRules && touched.commissionRules[index] && !values.commissionRules[index].value) || false ? 'is-invalid' : ''}
                                                                    placeholder="Enter your Value"
                                                                    type="text"
                                                                    handleChange={handleChange}
                                                                    value={values.commissionRules[index].value || ""}
                                                                    invalid={errors.commissionRules && errors.commissionRules[index] && touched.commissionRules && touched.commissionRules[index] && touched.commissionRules[index].value ? true : false}
                                                                />
                                                                {errors.commissionRules && errors.commissionRules[index] && touched.commissionRules && touched.commissionRules[index] && touched.commissionRules[index].value ? (
                                                                    <p className="text-danger">
                                                                        {errors.commissionRules[index].value}
                                                                    </p>
                                                                ) : null}
                                                            </td>
                                                            <td>
                                                                <i className="ri-delete-bin-5-line text-danger cursor-pointer fs-15" onClick={() => remove(index)}></i>
                                                            </td>
                                                        </tr>
                                                    );
                                                }))}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                        <div className="d-none code-view">
                            <pre className="language-markup default-height">
                                <code><TablesWithoutBorders /></code>
                            </pre>
                        </div>
                    </CardBody>
                </Card>
            )}
        </FieldArray>
    )
}

