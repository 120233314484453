import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

//import images
import profileimg1 from "../../assets/images/users/user-dummy-img.jpg";
import { createSelector } from 'reselect';
import { authConfig, UserManagerInstance } from './functions/authConfig';
const ProfileDropdown = () => {
    
    function clearAuth() {
        const environment = window.env.REACT_APP_LOGIN_ENVIRONMENT;
        if (environment === "Native") {
            window.location.href = process.env.PUBLIC_URL + "/logout";
        } else {
            UserManagerInstance.signoutRedirect({ post_logout_redirect_uri: authConfig.post_logout_redirect_uri });
        }
        localStorage.removeItem('token');
        localStorage.removeItem('userInfo');
        localStorage.removeItem('permissionss');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('userId');
        localStorage.removeItem('roleName');
        localStorage.removeItem('operatorId');
        localStorage.removeItem('customerId');
    }

    const handleLogout = () => {
        clearAuth()
        // localStorage.clear()
        // window.location.href = process.env.PUBLIC_URL + "/logout";
    };

    //Dropdown Toggle
    const [isProfileDropdown, setIsProfileDropdown] = useState(false);
    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };
    return (
        <React.Fragment>
            <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user">
                <DropdownToggle tag="button" type="button" className="btn shadow-none">
                    <span className="d-flex align-items-center">
                        <img className="rounded-circle header-profile-user" src={profileimg1}
                            alt="Header Avatar" />
                        <span className="text-start ms-xl-2">
                            <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{localStorage.getItem('userId')}</span>
                        </span>
                    </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    <h6 className="dropdown-header">Welcome {localStorage.getItem('userId')}!</h6>
                    {/* <DropdownItem ><i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                        <span className="align-middle">Profile</span></DropdownItem> */}
                    <DropdownItem ><i
                        className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle">Help</span></DropdownItem>
                    <div className="dropdown-divider"></div>
                    {/* <DropdownItem><i
                        className="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle">Balance : <b>$00.00</b></span></DropdownItem> */}
                    <DropdownItem onClick={handleLogout}>
                        <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
                        <span className="align-middle" data-key="t-logout">Logout</span>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default ProfileDropdown;