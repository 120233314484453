import { USER_MANAGEMENT_API } from "../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../interceptor/interceptor";
import { fetchGetAllUserFailure, fetchGetAllUserSuccess } from "./reducer";

export const getAllUser = (setLoading,operatorId) => async (dispatch) => {
    try {
        setLoading(true)
        const response = await determineBaseUrlAndPerformApiCall(`${USER_MANAGEMENT_API}/${operatorId}`,'GET')
        setLoading(false)
        dispatch(fetchGetAllUserSuccess(response.reverse()))
    }
    catch (error) {
        setLoading(false)
        dispatch(fetchGetAllUserFailure(error.message))
    }
}