import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Button, Card, CardBody, CardHeader, Col, Row, Table } from "reactstrap"
import { LabelText } from "../Utils/Input"
import { useState } from "react"
import { Link } from "react-router-dom"
import CommonDateTimePicker from "../Utils/CommonDateTimePicker"
import moment from "moment"

export const OfferFilterAccordion = ({ open, toggle, loading, filteredResults, navigate, startsOn, setEndsOn, setStartsOn, endsOn, setSelectedOfferCode }) => {
    const [selectedItemIndex, setSelectedItemIndex] = useState(null);
    const [selectedServiceIndex, setSelectedServiceIndex] = useState(null)
    const [attributes, setAttributes] = useState(false);
    return (
        <Accordion open={open} toggle={toggle}>
            {loading ? (
                <p></p>
            ) : (
                <>
                    {filteredResults.map(([serviceName, offers], index) => (
                        serviceName && (
                            <AccordionItem key={index}>
                                <AccordionHeader targetId={`${index}`}>
                                    {serviceName}
                                </AccordionHeader>
                                <AccordionBody accordionId={`${index}`}>
                                    {offers.map((offer, offerIndex) => (
                                        serviceName && (
                                            <>
                                                <Row className="gy-4 mb-2 ms-2">
                                                    <Col className="p-3" xxl={4} md={6}>
                                                        <div>
                                                            <div className="d-flex">
                                                                <h5 className="offer-name me-2 text-black">{offer.offerName}</h5>
                                                            </div>
                                                            <div className="d-flex">
                                                                {offer.Grants?.length > 0 && (
                                                                    <div className="badge-for-order me-2 p-1">
                                                                        <LabelText classLabelName="mb-0 me-1 ps-1 badge-for-offer-count" displayText={offer.Grants?.length} />
                                                                        <LabelText classLabelName="mb-0" displayText="Grant" />
                                                                    </div>
                                                                )}
                                                                {offer.Charges?.length > 0 && (
                                                                    <div className="badge-for-order me-2 p-1">
                                                                        <LabelText classLabelName="mb-0 me-1 ps-1 badge-for-offer-count" displayText={offer.Charges?.length} />
                                                                        <LabelText classLabelName="mb-0" displayText="Charge" />
                                                                    </div>
                                                                )}
                                                                {offer.Usage?.length > 0 && (
                                                                    <div className="badge-for-order me-2 p-1">
                                                                        <LabelText classLabelName="mb-0 me-1 ps-1 badge-for-offer-count" displayText={offer.Usage?.length} />
                                                                        <LabelText classLabelName="mb-0" displayText="Usage" />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col className="p-3" xxl={4} md={6}>
                                                        <h5 className="offer-name offer-code-color">Offer Code : <span className="text-black">{offer.offerCode}</span></h5>
                                                    </Col>
                                                    <Col xxl={3} md={6}>
                                                        <div className="d-flex flex-column justify-content-end align-items-start">
                                                            <div className="d-flex">
                                                                <h5 className="fs-6 pe-2 mt-3 fw-semibold offer-code-color">
                                                                    Term Validity:
                                                                </h5>
                                                                <h5 className="fs-6 mt-3 fw-lighter text-black">{offer.termValidity}</h5>
                                                            </div>
                                                            <div className="d-flex justify-content-center align-items-center mt-3">
                                                                <h5 className="fs-6 pe-2 fw-semibold offer-code-color" >
                                                                    Offer Validity:
                                                                </h5>
                                                                {offer.validityTo !== "Jan 01,0001" ? (
                                                                    <h5 className="fs-6 text-black fw-lighter">
                                                                        {offer.validityFrom} - {offer.validityTo}
                                                                    </h5>
                                                                ) : (
                                                                    <h5 className="fs-6 text-black fw-lighter">Never Expiring</h5>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={1} md={6}>
                                                        <div>
                                                            <Button
                                                                color="secondary"
                                                                className="mt-3"
                                                                onClick={() => {
                                                                    setSelectedItemIndex(offerIndex);
                                                                    setSelectedServiceIndex(index);
                                                                    setStartsOn(null);
                                                                    setEndsOn(null);
                                                                    setSelectedOfferCode(offer.offerCode);
                                                                    setAttributes(offer.provisioningAttributes.length > 0);
                                                                }}
                                                            >
                                                                Select
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                    <hr />
                                                </Row>

                                                {(selectedItemIndex === offerIndex && selectedServiceIndex === index) &&
                                                    <Card>
                                                        <CardHeader>
                                                            <h4 className="card-title mb-0 flex-grow-1">Subscription</h4>
                                                        </CardHeader>
                                                        <CardBody>
                                                            <Row>
                                                                <Col xxl={6} md={6}>
                                                                    <LabelText htmlFor={"startsOn"} displayText={"Starts On"} />
                                                                    <CommonDateTimePicker
                                                                        className={`form-control`}
                                                                        value={startsOn}
                                                                        name={`startsOn`}
                                                                        handleChange={(values) => { setStartsOn(values) }}
                                                                        format="DD/MM/YYYY"
                                                                        minDate={moment()}
                                                                        views={['year', 'month', 'day']}
                                                                    />
                                                                </Col>
                                                                <Col xxl={6} md={6}>
                                                                    <LabelText htmlFor={"endsOn"} displayText={"Ends On"} />
                                                                    <CommonDateTimePicker
                                                                        className={`form-control`}
                                                                        value={endsOn}
                                                                        name={`endsOn`}
                                                                        handleChange={(values) => { setEndsOn(values) }}
                                                                        format="DD/MM/YYYY"
                                                                        minDate={startsOn}
                                                                        views={['year', 'month', 'day']}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                }
                                            </>
                                        )
                                    ))}
                                </AccordionBody>
                            </AccordionItem>
                        )
                    ))}
                </>
            )}
        </Accordion>
    )
}