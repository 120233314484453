import React, { useEffect, useState } from "react";
import { CardBody, Col, Container, Row, Card, Button } from "reactstrap";
import BreadCrumb from "../Common/BreadCrumb";
import { MaterialReactTable } from "material-react-table";
import { policyColumns } from "./Constants/PolicyColumns";
import { useDispatch, useSelector } from "react-redux";
import { getAllPolicies } from "../../slices/PartnerMangement/policylist/thunk";
import { Loader } from "../Utils/Loader";
import { policyDataFunction } from "./Constants/ModifyDataFunctions";
import { MenuItem } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { setEditSuccessMessage, setSuccessMessage } from "../../slices/toastMessage/action";
import { closeModal, formattedDatesFunction, handleChangeStatus, toggleModal } from "./Constants/ModifyPolicyDataFunctions";
import CommonModal from "../Utils/CommonModal";
import { PAGE_TITLES } from "../Common/constants";
import { handleExportData } from "../Utils/ExportToCsv";

export const PolicyListing = () => {
    const [loading, setLoading] = useState(false);
    const [globalFilter, setGlobalFilter] = useState('');
    const [columnFilters, setColumnFilters] = useState([]);
    const [data, setData] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const partnerId = {
        isEdit: false
    };

    useEffect(() => {
        dispatch(getAllPolicies(setLoading));
    }, [dispatch]);

    const fetchAllPolicies = useSelector(state => state.PolicyList.policyList);
    const policyListData = policyDataFunction(fetchAllPolicies);
    const formattedDates = formattedDatesFunction(fetchAllPolicies);
    const columns = policyColumns(formattedDates);
    const showSuccessMessage = useSelector((state) => state.SuccessToast.showSuccessMessage);
    const showEditSuccessMessage = useSelector((state) => state.SuccessToast.showEditSuccessMessage);
    const permissions = useSelector((state) => state.permissions.permissions);
    const policyPermission = permissions.find((resource) => resource.resourceName === 'commissionPolicy');

    useEffect(() => {
        dispatch({ type: 'CLEAR_OFFERS' });
        if (showSuccessMessage) {
            toast.success("Policy Created Successfully");
            dispatch(setSuccessMessage(false));
        }
        if (showEditSuccessMessage) {
            toast.success("Policy Updated Successfully");
            dispatch(setEditSuccessMessage(false));
        }
    }, [showSuccessMessage, showEditSuccessMessage, dispatch]);

    const [modal, setModal] = useState(false);
    const [policyId, setpolicyId] = useState("");

    useEffect(() => {
        const filteredData = policyListData.filter(row => {
            const globalFilterLower = globalFilter.toLowerCase();
            const columnFilterChecks = columnFilters.map(filter => {
                const filterValue = filter?.value?.toLowerCase();
                const cellValue = row[filter.id]?.toString().toLowerCase();
                return cellValue?.includes(filterValue);
            });
            const matchesGlobalFilter = !globalFilter || (
                (row.id && typeof row.id === 'string' && row.id.toLowerCase().includes(globalFilterLower)) ||
                (row.name && typeof row.name === 'string' && row.name.toLowerCase().includes(globalFilterLower)) ||
                (row.priority && typeof row.priority === 'string' && row.priority.toLowerCase().includes(globalFilterLower)) ||
                (row.partner && Array.isArray(row.partner) && row.partner.some(partner => typeof partner === 'string' && partner.toLowerCase().includes(globalFilterLower))) ||
                (row.offer && Array.isArray(row.offer) && row.offer.some(offer => typeof offer === 'string' && offer.toLowerCase().includes(globalFilterLower))) ||
                (row.formattedDates && typeof row.formattedDates === 'string' && row.formattedDates.toLowerCase().includes(globalFilterLower)) ||
                (row.createdBy && typeof row.createdBy === 'string' && row.createdBy.toLowerCase().includes(globalFilterLower))
            );

            return matchesGlobalFilter && columnFilterChecks.every(Boolean);
        });
        setData(filteredData);
    }, [globalFilter, columnFilters, policyListData]);

    const handleCancel = () => {
        setGlobalFilter('');
        setColumnFilters([]);
        setData(policyListData);
    };

    const handleGlobalFilterChange = (filter) => {
        setGlobalFilter(filter);
        if (!filter) {
            handleCancel();
        }
    };

    return (
        <React.Fragment>
            <div id='csms' className='page-content'>
                <Container fluid>
                    {loading && <Loader />}
                    <BreadCrumb title={PAGE_TITLES.SETTLEMENT_POLICY_LIST} pageTitle={PAGE_TITLES.PARTNER_LIST} />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <ToastContainer position="top-center" />
                                <CardBody>
                                    <Row className="mb-3 mt-1 table-responsive">
                                        <MaterialReactTable
                                            icons={{
                                                DragHandleIcon: () => <i className="ri-drag-move-fill" />,
                                            }}
                                            muiTableHeadCellProps={{
                                                sx: {
                                                    fontFamily: "Inter,sans-serif"
                                                }
                                            }}
                                            muiTableBodyCellProps={{
                                                sx: {
                                                    fontFamily: "Inter,sans-serif"
                                                }
                                            }}
                                            renderTopToolbarCustomActions={() => (
                                                <div className="d-flex flex-column flex-md-row align-items-md-center">
                                                    <Button color="secondary" className="btn-label me-2 mb-2 mb-md-0" onClick={() => { handleExportData(fetchAllPolicies) }}>
                                                        <i className="ri-upload-line label-icon align-middle fs-16 me-2 " ></i> Export Data
                                                    </Button>
                                                    {policyPermission && policyPermission.permissionList.includes('create') && <Button color="secondary" className="btn-label me-2 mb-2 mb-md-0" onClick={() => {
                                                        navigate('/offer-for-policy', { state: partnerId });
                                                        dispatch({ type: 'CLEAR_PARTNERS' });
                                                        dispatch({ type: 'CLEAR_OFFERS' });
                                                    }} >
                                                        <i className="ri-add-fill label-icon align-middle fs-16 me-2"></i>Add Policy</Button>}
                                                </div>
                                            )}
                                            manualFiltering
                                            onColumnFiltersChange={setColumnFilters}
                                            onGlobalFilterChange={handleGlobalFilterChange}
                                            state={{ globalFilter, columnFilters }}
                                            enableRowActions
                                            enableDensityToggle={false}
                                            displayColumnDefOptions={{
                                                'mrt-row-actions': {
                                                    header: '',
                                                    enableHiding: true
                                                },
                                            }}
                                            renderRowActionMenuItems={({ row, closeMenu }) => (
                                                [
                                                    policyPermission && policyPermission.permissionList.includes('update') ? <MenuItem onClick={() => {
                                                        navigate(`/update-policy/${row.original.id}`);
                                                        dispatch({ type: 'CLEAR_PARTNERS', payload: [] });
                                                        dispatch({ type: 'CLEAR_OFFERS', payload: [] });
                                                    }}><i className=" bx bxs-edit me-2"></i>Edit</MenuItem> : null,
                                                    policyPermission && policyPermission.permissionList.includes('delete') ? <MenuItem onClick={() => {
                                                        closeMenu();
                                                        toggleModal(row, setpolicyId, setModal);
                                                        closeModal(setModal, modal);
                                                    }}><i className="ri-delete-bin-line me-2" /> Delete</MenuItem> : null
                                                ]
                                            )}
                                            columns={columns}
                                            data={data}
                                        />
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <CommonModal open={modal} toggle={() => closeModal(setModal, modal)} buttonText={"Yes, Delete It"} messageForPopUp={`Are you sure you want to delete policy "${policyId}"?`} modalAction={() => { handleChangeStatus(dispatch, policyId, setLoading), closeModal(setModal, modal); }} />
            </div>
        </React.Fragment>
    );
};
