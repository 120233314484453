import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { Col, Container, Form, Row, Button, Card, CardHeader, CardBody } from "reactstrap";
import BreadCrumb from "../Common/BreadCrumb";
import { ToastContainer } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import CommonButton from "../Utils/CommonButton";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../Utils/Loader";
import { validationSchema } from "./Constant/validationSchema";
import InputField from "../Utils/InputField";
import { isEmptyOrInvalidField } from "../Customers/constants/constantFunctions";
import { customerIdData } from "../Customers/constants/constantFunctionsView";
import SelectField from "../Utils/SelectField";
import { LabelText } from "../Utils/Input";
import OperatorDetails from "./OperatorDetails";
import { fetchCountries } from "../../slices/customer/countries/thunk";
import { countryOptionss } from "./Constant/Options";
import { fetchStates } from "../../slices/customer/states/thunk";
import { fetchCities } from "../../slices/customer/cities/thunk";
import { OperatorOptionsFields, OperatorStatusOptions } from "./Constant/Options";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { createOperator } from "../../slices/OperatorManagement/CreateOperator/thunk";
import { editOperator } from "../../slices/OperatorManagement/EditOperator/thunk";
import { fetchOperatorbyId } from "../../slices/OperatorManagement/GetOperatorbyID/thunk";
import AttributeBox from "./AttributeBox";

export default function CreateEditOperators() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState();
    const [stateData, setStatedata] = useState();
    const [cityData, setCityData] = useState()
    const [selectedCountry, setSelectedCountry] = useState()
    const [stateOptions, setStateOptions] = useState()
    const [selectedState, setSelectedState] = useState()
    const isEdit = location.pathname.includes('edit-operator');
    let optionState = []
    useEffect(() => {
        dispatch(fetchCountries())
    }, [dispatch]);
    const countries = useSelector(state => state.Countries.countries)
    const countryOptions = countryOptionss(countries?.data)

    const operatorOptions = [
        {
            name: "Domain",
            value: "Operator",
            options: OperatorOptionsFields
        },
        {
            name: "country",
            value: "Operator",
            options: countryOptions
        },
        {
            name: "Status",
            value: "Operator",
            options: OperatorStatusOptions
        }
    ]
    useEffect(() => {
        dispatch(fetchStates(countryOptions, isEdit, selectedCountry, optionState, "isOperator", setStatedata, setStateOptions))
    }, [dispatch, isEdit, selectedCountry]);

    const operatorId = customerIdData();

    useEffect(() => {
        dispatch(fetchCities(countryOptions, stateOptions, selectedCountry, selectedState, optionState, "isOperator", setCityData))
    }, [dispatch, selectedCountry, selectedState]);

    const initialValues = {
        businessName: "",
        Domain: "",
        state: "",
        city: "",
        zipCode: "",
        endDate: null,
        Address: "",
        Address2: "",
        country: "",
        firstname: "",
        lastname: "",
        username: "",
        phone: "",
        email: "",
        Status: "",
        // attributeList: []
    };
    //for Edit API Call for Get particular operator
    useEffect(() => {
        if (isEdit) {
            dispatch(fetchOperatorbyId(setLoading, operatorId, initialValues, operatorOptions,OperatorStatusOptions));
        }
    }, [dispatch, setLoading]);
    const operatorData = useSelector((state) => state?.GetOperator?.GetOperatorId ?? null);

    return (
        <React.Fragment>
            <div id='csms' className='page-content'>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    onSubmit={async (values) => {
                        if (
                            values?.firstname !== "" && values?.lastname !== "" && values?.email !== "" && values?.Status !== " " && values?.username !== " " && values?.userrole !== " " && values?.phone !== ""
                        ) {
                            const phoneNumber = formatPhoneNumberIntl(values?.phone)
                            let dialCodeWithPlus = phoneNumber.match(/^\+\d+(\s|$)/)[0];
                            let restOfNumber = phoneNumber.slice(dialCodeWithPlus?.length);
                            let mobileNumberWithoutSpaces = restOfNumber.replace(/\s/g, '');
                            let dialCodeWithoutSpaces = dialCodeWithPlus.replace(/\s/g, '');
                            const formattedValues = {
                                tradingName: values.businessName,
                                existsDuring: {
                                    endDateTime: values.endDate,
                                    // startDateTime: "2023-01-01T00:00:00Z"
                                },
                                domain: values.Domain?.value,
                                state: values.state?.value,
                                city: values.city?.value,
                                country: values.country?.value,
                                postcode: (values.zipCode).toString(),
                                street1: values.Address,
                                street2: values.Address2,
                                // partyCharacteristic: values.attributeList,
                                adminOperator: 
                                    {
                                        userName: values.username,
                                        email: values.email,
                                        profile: {
                                            firstName: values.firstname,
                                            lastName: values.lastname
                                        },
                                        dialCode: dialCodeWithoutSpaces,
                                        phone: mobileNumberWithoutSpaces,
                                    }
                                ,
                                ...(isEdit ? { status: values.Status?.value } : {})
                            };
                            if (!isEdit) {
                                dispatch(createOperator(formattedValues, setLoading, navigate));
                            } else {
                                dispatch(editOperator(formattedValues, operatorId, setLoading, navigate));
                            }
                        }
                    }}
                >
                    {({ handleChange, handleBlur, handleSubmit, errors, touched, values, setFieldValue, setFieldTouched }) => (
                        <Form className='needs-validation' onSubmit={handleSubmit}>
                            <Container fluid>
                                <BreadCrumb title={isEdit ? 'Edit Operator' : 'Add operator'} pageTitle='' />
                                <ToastContainer position='top-center' />
                                <Row>
                                    {loading && (<Loader />)}
                                    <Col lg={12}>
                                        <div className='listingjs-table ' id='OperatorList'>
                                            <OperatorDetails 
                                                isEdit={isEdit}
                                                values={values}
                                                errors={errors}
                                                touched={touched}
                                                setFieldTouched={setFieldTouched}
                                                setFieldValue={setFieldValue}
                                                handleBlur={handleBlur}
                                                handleChange={handleChange}
                                                countries={operatorOptions}
                                                setSelectedCountry={setSelectedCountry}
                                                setSelectedState={setSelectedState}
                                                stateData={stateData}
                                                cityData={cityData}
                                                operatorData={operatorData}
                                            />
                                            {/* <AttributeBox 
                                                values={values}
                                                errors={errors}
                                                touched={touched}
                                                setFieldValue={setFieldValue}
                                                setFieldTouched={setFieldTouched}
                                                handleChange={handleChange}
                                            /> */}
                                            {/* {isEdit?  <Card className=" mx-1">
                                                <CardBody>
                                                    <Col xxl={3} >
                                                        <div>
                                                            <LabelText displayText={"Status"} />
                                                            <SelectField
                                                                name="status"
                                                                value={values.status}
                                                                handleChange={(selectedDevice) => setFieldValue('status', selectedDevice)}
                                                                className={(errors.status && touched.status) || false ? 'error-input' : ''}
                                                                options={OperatorStatusOptions}
                                                            />
                                                            {touched.status && errors.status ? (
                                                                <p className="text-danger validation-font-size">
                                                                    {errors.status}
                                                                </p>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </CardBody>
                                            </Card>
                                            :null
                                            } */}
                                            <Row lg={6} className='justify-content-end mb-4'>
                                                <div className='live-preview'>
                                                    <div className='d-flex justify-content-end gap-2 '>
                                                        <CommonButton outline={true} className={"cancel-button-background"} color={"main-color"} type={"reset"} buttonAction={() => navigate('/operator-listing')} buttonText={"Cancel"} />
                                                        <CommonButton color={"main-color"} type={'submit'} buttonText={"Confirm"} />
                                                    </div>
                                                </div>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Form>
                    )}
                </Formik>
            </div>
        </React.Fragment>
    );
}