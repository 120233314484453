import React, { useEffect, useState } from "react";
import { Card, CardBody, Container, Row } from "reactstrap";
import BreadCrumb from "../../Common/BreadCrumb";
import { ToastContainer, toast } from "react-toastify";
import { MaterialReactTable } from "material-react-table";
import { Loader } from "../../Utils/Loader";
import { attributeColoumns } from "./constants/AttributeColumns";
import { useNavigate } from "react-router-dom";
import { getAllAttribute } from "../../../slices/services/addservice/provisionalattribute/thunk";
import { useDispatch, useSelector } from "react-redux";
import { setEditSuccessMessage, setSuccessMessage } from "../../../slices/toastMessage/action";
import CommonModal from "../../Utils/CommonModal";
import { deleteAttribute } from "../../../slices/attribute/deleteattribute/thunk";
import { AttributeRowOptions } from "./AttributeRowOptions";
import { AttributeCustomButtons } from "./AttributeCustomButtons";

export default function AttributeList() {
    const [loading, setLoading] = useState()
    const [messageForPopUp, setMessageForPopUp] = useState('')
    const [modal, setModal] = useState(false);
    const [attributeName, setAttributeName] = useState()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const columns = attributeColoumns()

    const toggleModal = (id) => {
        setAttributeName(id)
        setModal(!modal);
        document.body.style.overflow = 'auto'
    };

    useEffect(() => {
        dispatch(getAllAttribute(setLoading));
    }, [dispatch]);
    const attributes = useSelector((state) => state.Provisional.provisional);
    const showSuccessMessage = useSelector((state) => state.SuccessToast.showSuccessMessage)
    const showEditSuccessMessage = useSelector((state) => state.SuccessToast.showEditSuccessMessage);

    useEffect(() => {
        if (showSuccessMessage) {
            toast.success(`Attribute Created Successfully`);
            dispatch(setSuccessMessage(false))
        }
        if (showEditSuccessMessage) {
            toast.success(`Attribute Updated Successfully`);
            dispatch(setEditSuccessMessage(false));
        }
    }, [showSuccessMessage, showEditSuccessMessage, dispatch])

    return (
        <React.Fragment>
            <div id='csms' className='page-content'>
                <Container fluid>
                    <BreadCrumb title="Attribute List" pageTitle="Attributes" />
                    <ToastContainer position='top-center' />
                    <Row>
                        <Card>
                            <CardBody>
                                <Row className="mb-3 mt-1 table-responsive">
                                    {loading && (<Loader />)}
                                    {attributes ?
                                        <MaterialReactTable
                                            icons={{ DragHandleIcon: () => <i className="ri-drag-move-fill" />, }}
                                            muiTableHeadCellProps={{
                                                sx: {
                                                    fontFamily: "Inter,sans-serif"
                                                }
                                            }}
                                            muiTableBodyCellProps={{
                                                sx: {
                                                    fontFamily: "Inter,sans-serif"
                                                }
                                            }}
                                            renderTopToolbarCustomActions={({ }) => (
                                                <AttributeCustomButtons navigate={navigate} attributes={attributes} />
                                            )}
                                            displayColumnDefOptions={{
                                                'mrt-row-actions': {
                                                    header: '',
                                                    enableHiding: true
                                                }
                                            }}
                                            columns={columns}
                                            data={attributes}
                                            enableRowActions
                                            enableColumnOrdering
                                            enableColumnDragging
                                            enableDensityToggle={false}
                                            renderRowActionMenuItems={({ closeMenu, row }) => <AttributeRowOptions navigate={navigate} row={row} setMessageForPopUp={setMessageForPopUp} toggleModal={toggleModal} closeMenu={closeMenu} />}
                                        /> : null}
                                </Row>
                            </CardBody>
                        </Card>
                    </Row>
                </Container>
            </div>
            <div>
                <CommonModal open={modal} toggle={toggleModal} buttonText={"Yes, Delete It"} messageForPopUp={messageForPopUp} modalAction={() => {
                    dispatch(deleteAttribute(attributeName.original.name, setLoading))
                    toggleModal()
                }} />
            </div>
        </React.Fragment>
    )
}