import {  USER_MANAGEMENT_API, USER_MANAGEMENT_CREATE_API } from "../../constants/constants"
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor"
import { setEditSuccessMessage } from "../../toastMessage/action"
import { toast } from "react-toastify"
import { fetchUpdateUserFailure, fetchUpdateUserSuccess } from "./reducer"

export const updateUser = (setLoading,formattedValues,navigate,userId) => (dispatch) => {
    setLoading(true)
    determineBaseUrlAndPerformApiCall(`${USER_MANAGEMENT_CREATE_API}/${userId}`,'PATCH',formattedValues).then((response)=>{
        if (!response.error) {
            setLoading(false)
            dispatch(fetchUpdateUserSuccess(response))
            navigate('/user')
            dispatch(setEditSuccessMessage(true))
        }
        if (response.error) {
            setLoading(false)
            toast.error(
                `${response.error.message}`
            )
            dispatch(fetchUpdateUserFailure(response.error.message))
        }
    }).catch((error)=>{
        toast.error(
            `${error.message}`
        )
    })
}