import moment from 'moment';

export const handleAddItem = (values, selectedOffer, formData, navigate) => {
    const dataToSend = {
        serviceName: selectedOffer?.serviceName,
        offerName: selectedOffer?.offerName,
        offerCode: selectedOffer?.offerCode,
        termValidity: selectedOffer?.termValidity,
        validityFrom: selectedOffer?.validityFrom,
        validityTo: selectedOffer?.validityTo,
        totalChargeAmount: selectedOffer?.totalChargeAmount,
        unitPrice: selectedOffer?.unitPrice,
        subTotal:selectedOffer?.subTotal,
        description: selectedOffer?.description,
        CustomerId: selectedOffer?.CustomerId,
        CustomerName: selectedOffer?.CustomerName,
        ProvisionalAttributes: selectedOffer?.provisioningAttributes,
        orderId:selectedOffer?.orderId,
        formData: {
            subscriptionID: values.subscriptionID,
            selectedOffer: values.selectedOffer,
            selectedDevice: values.selectDevice.value,
            subscriptionStartsOn: moment(values.subscriptionStartsOn).format('MMM DD, YYYY'),
            subscriptionEndsOn: moment(values.subscriptionEndsOn).format('MMM DD, YYYY'),
            name: formData.name,
            value: formData.value,
        },
    };
    
    navigate('/order-details', { state: { ...dataToSend } });
};
