import { FieldArray } from "formik";
import { CardHeader, Button, CardBody, Table } from "reactstrap";
import { TablesWithoutBorders } from "../../../pages/Tables/BasicTables/BasicTablesCode";
import SelectField from "../../Utils/SelectField";
import InputField from "../../Utils/InputField";
import { Link } from "react-router-dom";
import { removeRowsFromGrantsAndCharges } from "./constants/checkEmptyFields";
import { grantsAndChargesHandle, handleGrantsAndChargesResourceValueAndUsageFields, handleInfiniteForGrantsAndCharges } from "./constants/GrantsAndRecurringFunctions";

export default function GrantsOneTime({
    touched, isEdit,
    errors,loading,setInfinite,
    values, infinite, floor, ceiling,resourceData,displayCeilingAndFloorForOptions,
    handleChange,  displayInfiniteForOptions,setCeiling,setFloor,
    infiniteOptions, grantResourceOptions,
    setTouched, setFieldTouched, setFieldValue,
    isEmptyorInvalidFieldForGrantsAndCharges
}) {
    return (
        <FieldArray name="grants">
            {({ push, remove }) => (
                <>
                    <CardHeader className="d-flex justify-content-center  align-items-center">
                        <h4 className="card-title mb-0 flex-grow-1 me-2">New Grant</h4>
                        {!isEdit && <Button  color="secondary" className="btn-label" onClick={() => {
                            const lastIndex = values.grants.length - 1;
                            if (lastIndex === -1 || !isEmptyorInvalidFieldForGrantsAndCharges(lastIndex, values.grants)) {
                                push({ resourceType: '', resourceValue: "0", ceiling: '', floor: '',prorate:false,type:"1",recurringFrequency:{value:'0',label:"false"} });
                                setTouched({
                                    ...touched,
                                    grants: {
                                        ...touched.grants,
                                        [lastIndex]: {
                                            resourceType: false,
                                        },
                                    },
                                });
                            }
                        }} > <i className="ri-add-fill label-icon align-middle fs-16 me-2"></i> Add Grants </Button>}
                    </CardHeader>
                    <CardBody>
                        <div className="live-preview">
                            <div className="table-responsize">
                                <div className="scrollable-view">
                                    <Table className=" table-borderless align-middle mb-0 ">
                                        <thead>
                                            <tr>
                                                <th scope="col"  className={isEdit ? "label-disable-color-edit-offer":""}>Resource Type<span className="text-danger"> *</span></th>
                                                <th scope="col">Resource Value</th>
                                                <th scope="col" className={isEdit ? "label-disable-color-edit-offer":""}>Infinite</th>
                                                <th scope="col" className={isEdit ? "label-disable-color-edit-offer":""}>Type of Resource</th>
                                                <th scope="col" className={isEdit ? "label-disable-color-edit-offer":""}>Ceiling</th>
                                                <th scope="col" className={isEdit ? "label-disable-color-edit-offer":""}>Floor</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            {values.grants.length === 0 ? (<tr className="default-height"><td colSpan={7} className=" text-center">No Grants</td></tr>) :
                                                (values.grants.map((val, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                <div className="field-length">
                                                                    <SelectField
                                                                        isLoading={loading}
                                                                        className={(
                                                                            touched.grants &&
                                                                            touched.grants[index] &&
                                                                            !values.grants[index].resourceType
                                                                        ) || false ? 'error-input' : '' }
                                                                        name={`grants[${index}].resourceType`} isDisabled={isEdit ? true : false}
                                                                        value={values.grants[index].resourceType}
                                                                        handleChange={grantsAndChargesHandle(`grants[${index}].resourceType`,values.grants[index],displayInfiniteForOptions,displayCeilingAndFloorForOptions,setFieldValue,setFieldTouched,index,resourceData,setInfinite,setCeiling,setFloor)}
                                                                        options={grantResourceOptions} />
                                                                </div>
                                                                {errors.grants &&
                                                                    errors.grants[index] &&
                                                                    touched.grants &&
                                                                    touched.grants[index] &&
                                                                    touched.grants[index].resourceType ? (
                                                                    <p className="text-danger">
                                                                        {errors.grants[index].resourceType}
                                                                    </p>
                                                                ) : null}
                                                            </td>
                                                            <td>
                                                                <InputField
                                                                    name={`grants[${index}].resourceValue`}
                                                                    className={'form-control field-length3'}
                                                                    placeholder="Enter Amount"
                                                                    type="number"
                                                                    handleChange={handleGrantsAndChargesResourceValueAndUsageFields(handleChange)}
                                                                    value={values.grants[index].resourceValue}
                                                                    invalid={
                                                                        errors.grants &&
                                                                            errors.grants[index] &&
                                                                            touched.grants &&
                                                                            touched.grants[index] &&
                                                                            touched.grants[index].resourceValue
                                                                            && errors.grants[index].resourceValue  ? true : false
                                                                    }
                                                                />
                                                            </td>
                                                            {infinite[index] ?
                                                                <td>
                                                                    <div className="field-length">
                                                                        <SelectField
                                                                            name={`grants[${index}].infinite`}
                                                                            value={values.grants[index].infinite} isDisabled={isEdit ? true : false}
                                                                            handleChange={handleInfiniteForGrantsAndCharges(`grants[${index}].infinite`,values.grants[index],setFieldValue)}
                                                                            options={infiniteOptions} />
                                                                    </div>
                                                                </td>
                                                                : <td />}
                                                            <td>
                                                                <InputField className={`field-length2 ${isEdit ? "label-disable-color-edit-offer":""}`} value={"Non-Currency"} disabled={true} />
                                                            </td>
                                                            {ceiling[index] || ceiling[index] === 0 ?
                                                                <td>
                                                                    <InputField className={`field-length3 ${isEdit ? "label-disable-color-edit-offer":""}`} value={values.grants[index].ceiling} disabled={true} />
                                                                </td>
                                                                : <td />
                                                            }
                                                            {floor[index] || floor[index] === 0 ?
                                                                <td>
                                                                    <InputField className={`field-length3 ${isEdit ? "label-disable-color-edit-offer":""}`} value={values.grants[index].floor} disabled={true} />
                                                                </td>
                                                                : <td />
                                                            }
                                                            {!isEdit && <td>
                                                                <div className="hstack gap-3 fs-15">
                                                                <Link to="#" className="link-danger" onClick={() => {
                                                                        remove(index);
                                                                        removeRowsFromGrantsAndCharges(setInfinite, index, setCeiling, setFloor)
                                                                    }} ><i data-testid="delete" className="ri-delete-bin-5-line"></i></Link>
                                                                </div>
                                                            </td>}
                                                        </tr>
                                                    )
                                                }))}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                        <div className="d-none code-view">
                            <pre className="language-markup default-height">
                                <code>
                                    <TablesWithoutBorders />
                                </code>
                            </pre>
                        </div>
                    </CardBody>
                </>
            )}
        </FieldArray>
    )
}