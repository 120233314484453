import { Box } from "@mui/material";
import { RatePlanOrAttributesColumn } from "../../Utils/RatePlanColumn";

export const policyColumns = (formattedDates) => {
  const column = [
    {
      accessorKey: 'id',
      header: "Policy ID"
    },
    {
      accessorKey: "name",
      header: "Policy Name"
    },
    {
      accessorKey: "priority",
      header: "Priority",
    },
    {
      accessorKey: "partner",
      header: "Partners",
      Cell: ({ cell }) => (
        <Box>
          {Array.isArray(cell.getValue()) ? (
            cell.getValue().length > 1 ? (
              <>
                {cell.getValue().slice(0, 1).reduce((prev, current) => `${prev},${current}`)}
                <span>...</span>
              </>
            ) : (
              cell.getValue().reduce((prev, current) => `${prev},${current}`)
            )
          ) : null}
        </Box>
      )
    },
    {
      accessorKey: "offer",
      header: "Offers",
      Cell: ({ cell }) => (
        <Box>
          {Array.isArray(cell.getValue()) ? (
            cell.getValue().length > 1 ? (
              <>
                {cell.getValue().slice(0, 1).reduce((prev, current) => `${prev},${current}`)}
                <span>...</span>
              </>
            ) : (
              cell.getValue().reduce((prev, current) => `${prev},${current}`)
            )
          ) : null}
        </Box>
      )
    },
    {
      accessorKey: `formattedDates`,
      header: "Created Date",
      Cell: ({ row }) => formattedDates[row.index],

    },
    {
      accessorKey: "createdBy",
      header: "Created By"
    },
  ]
  return column
}

export const offerListForPolicy = (grant, charge, usage, grantsCount, chargeCount, usageCount) => {
  const column = [
    {
      accessorKey: 'offerName',
      header: "Offer Name"
    },
    {
      accessorKey: "serviceName",
      header: "Service Name"
    },
    {
      accessorKey: "offerCode",
      header: "Code"
    },
    {
      accessorFn: (offer, index) => `${grantsCount[index]} ${grant[index]} ${chargeCount[index]} ${charge[index]} ${usageCount[index]} ${usage[index]} `,
      header: "Rate Plan",
      Cell: ({ cell }) => {
        const attributes = cell.getValue()
        const [grantsCount, grant, chargeCount, charge, usageCount, usage] = attributes.split(' ')
        return (<RatePlanOrAttributesColumn grant={grant} grantsCount={grantsCount} charge={charge} chargeCount={chargeCount} usageCount={usageCount} usage={usage} offerOrPolicy={true} />)
      },
      size: 275
    },
    {
      accessorKey: "GlobalScope",
      header: "Global Scope"
    },
    {
      accessorKey: "validityFrom",
      header: "Offer Starts On"
    },
    {
      accessorKey: "validityTo",
      header: "Offer Ends On"
    },
  ]
  return column
}

export const partnerListForPolicy = () => {
  const column = [
    {
      accessorKey: 'extractedId',
      header: "Partner ID"
    },
    {
      accessorKey: "businessName",
      header: "Business Name"
    },
    {
      accessorKey: "type",
      header: "Type"
    },
    {
      accessorKey: "email",
      header: "Email"
    },
    {
      accessorKey: "phone",
      header: "Phone"
    },
    {
      accessorKey: "createdOn",
      header: "Created On"
    },
    {
      accessorKey: "validityTo",
      header: "Account Balance"
    },
  ]
  return column
}