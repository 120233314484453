import { MaterialReactTable } from "material-react-table";
import { Card, CardBody, Row } from "reactstrap";
import { CustomerListRowOptions } from "./CustomerListRowOptions";
import { CustomerPreview } from "./CustomerPreview";
import Simulate from "./Simulate";
import React, { useEffect } from "react";

export const TableForTelco = ({ getTable, searchColumns, searchData, orderPermission, customerPermission, navigate, dispatch, setRenderSimulate, setLoading, simulateOrPreview, index, setIndex, setActiveTab, fetchCustomerDetails, stationPermission, reservationPermission, getCustomerData, storeRow, setDefault, renderSimulate, pagination, customerData, activeTab, tabChange, setOpen, toggle, noCurrencyArray, currencyArray, fetchCustomerSummaryData, reservationDataArray, duration, subscriptionsArray, convertNumberOfDaysToMonthYear, formatDate, getDayDiff, current, errorMessage,errorIcon }) => {
    return (
        <CardBody>
            {!getTable ?
                <div className="border rounded static-height d-flex align-items-center justify-content-center " >
                    <div className="flex-column" style={{ textAlign: 'center' }}>
                        <i className={`${errorIcon} icon-size flex-column`} />
                        <p className="text-color-telco ">
                            {errorMessage}
                        </p>
                    </div>
                </div>
                : <div className="container-fluid px-0" > <Card className="disable-margin-card w-100">
                    <CardBody>
                        <Row className="mb-3 mt-1 table-responsive">
                            <MaterialReactTable data={searchData} columns={searchColumns}
                                icons={{
                                    DragHandleIcon: () => (
                                        <i className="ri-drag-move-fill" />
                                    ),
                                }}
                                muiTableHeadCellProps={{
                                    sx: {
                                        fontFamily: "Inter,sans-serif",
                                    },
                                }}
                                muiTableBodyCellProps={{
                                    sx: {
                                        fontFamily: "Inter,sans-serif",
                                    },
                                }}
                                muiTableDetailPanelProps={{
                                    sx: {
                                        fontFamily: "Inter,sans-serif",
                                    },
                                }}
                                enableGlobalFilter={false}
                                enableColumnOrdering={true}
                                enableColumnDragging={true}
                                enableDensityToggle={false}
                                displayColumnDefOptions={{
                                    "mrt-row-actions": {
                                        header: "",
                                        enableHiding: true,
                                    },
                                }}
                                enableRowActions
                                initialState={{
                                    columnVisibility: {
                                        "mrt-row-expand": false,
                                    },
                                }}
                                renderRowActionMenuItems={({ closeMenu, row }) => (
                                    <CustomerListRowOptions orderPermission={orderPermission} customerPermission={customerPermission}
                                        navigate={navigate}
                                        row={row}
                                        closeMenu={closeMenu}
                                        dispatch={dispatch}
                                        setRenderSimulate={setRenderSimulate}
                                        setLoading={setLoading}
                                        simulateOrPreview={simulateOrPreview}
                                        index={index}
                                        setIndex={setIndex}
                                        setActiveTab={setActiveTab}
                                        fetchCustomerDetails={fetchCustomerDetails}
                                        stationPermission={stationPermission}
                                        reservationPermission={reservationPermission}
                                        getCustomerData={getCustomerData}
                                        storeRow={storeRow}
                                        setDefault={setDefault} />
                                )}
                                renderDetailPanel={({ row }) => (
                                    <React.Fragment>
                                        {useEffect(() => {
                                            row.toggleExpanded(false);
                                        }, [pagination])}
                                        {renderSimulate ? (
                                            <Simulate row={row} />
                                        ) : (
                                            <CustomerPreview
                                                customerData={customerData}
                                                row={row}
                                                activeTab={activeTab}
                                                setActiveTab={setActiveTab}
                                                tabChange={tabChange}
                                                open={open}
                                                setOpen={setOpen}
                                                toggle={toggle}
                                                noCurrencyArray={noCurrencyArray}
                                                currencyArray={currencyArray}
                                                fetchCustomerSummaryData={fetchCustomerSummaryData}
                                                reservationDataArray={reservationDataArray}
                                                duration={duration}
                                                subscriptionsArray={subscriptionsArray}
                                                convertNumberOfDaysToMonthYear={convertNumberOfDaysToMonthYear}
                                                formatDate={formatDate}
                                                getDayDiff={getDayDiff}
                                                current={current} />
                                        )}
                                    </React.Fragment>
                                )}
                            />
                        </Row>
                    </CardBody>
                </Card>
                </div>}
        </CardBody>
    )
}