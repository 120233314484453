import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UserManager } from "oidc-client";
import { fetchPermissions } from "../permissionsSlice";
import { handleLoginSuccess } from "./authUtils";

const Callback = ({ userManager, setAuth }) => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state.permissions.permissions);
  const roleName = localStorage.getItem("roleName");

  useEffect(() => {
    if (loading) {
      userManager
        .signinRedirectCallback()
        .then((user) => {
          if (user) {
            setAuth(true);
            const response = {
              accessToken: user.access_token,
              refreshToken: user.refresh_token,
              userId: atob(user.profile['urn:zitadel:iam:user:metadata'][0].userName),
              roleName: atob(user.profile['urn:zitadel:iam:user:metadata'][0].roleName),
              operatorId: atob(user.profile['urn:zitadel:iam:user:metadata'][0].operatorId),
              permissions: JSON.parse(localStorage.getItem("permissionss")) || []
            };
            handleLoginSuccess(response, dispatch, setLoading, navigate);
            dispatch(fetchPermissions());
          } else {
            setAuth(false);
            setLoading(false);
          }
        })
        .catch((error) => {
          setAuth(false);
          setLoading(false);
        });
    }
  }, [loading, userManager, setAuth, dispatch]);

  useEffect(() => {
    if (!loading && permissions.length > 0) {
      const hasDashboardPermission = permissions.some(permission => permission.resourceName === 'dashboard');
      const hasCustomerPermission = permissions.some(permission => permission.resourceName === 'customer');
      if (hasDashboardPermission) {
        navigate('/dashboard');
      }
      else if (hasCustomerPermission) {
        navigate('/customer');
      } else {
        navigate('/operator-listing');
      }
    }
  }, [loading, permissions, navigate, roleName]);

  return null;
};

export default Callback;