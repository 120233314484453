import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Button, Col, Row } from "reactstrap"
import { LabelText } from "../Utils/Input"
import { handleSelectOffer } from "./constants/OrderFilterFunctions"

export const OrderFilterAccordion = ({open,toggle,loading,filteredResults,navigate}) => {
    return (
        <Accordion open={open} toggle={toggle}>
            {loading ? (
                <p></p>
            ) : (
                <>
                    {filteredResults.map(([serviceName, offers], index) => (
                        serviceName && (
                            <AccordionItem key={index}>
                                <AccordionHeader targetId={`${index}`}>
                                    {serviceName}
                                </AccordionHeader>
                                <AccordionBody accordionId={`${index}`}>
                                    {offers.map((offer) => (
                                        serviceName && (
                                            <Row className="gy-4 mb-2 ms-2">
                                                <Col className='p-3' xxl={8} md={6}>
                                                    <div>
                                                        <div className='d-flex'>
                                                            <h5 className=' offer-name me-2'>{offer.offerName}</h5>
                                                            <h5 className='offer-name offer-code-color'>{offer.offerCode}</h5>
                                                        </div>
                                                        <div className='d-flex'>
                                                            {offer.Grants?.length > 0 && (
                                                                <div className='badge-for-order me-2 p-1'>
                                                                    <LabelText classLabelName={'mb-0 me-1 ps-1 badge-for-offer-count'} displayText={offer.Grants?.length} />
                                                                    <LabelText classLabelName={'mb-0'} displayText={"Grants"} />
                                                                </div>
                                                            )}
                                                            {offer.Charges?.length > 0 && (
                                                                <div className='badge-for-order me-2 p-1'>
                                                                    <LabelText classLabelName={'mb-0 me-1 ps-1 badge-for-offer-count'} displayText={offer.Charges?.length} />
                                                                    <LabelText classLabelName={'mb-0'} displayText={"Charges"} />
                                                                </div>
                                                            )}
                                                            {offer.Usage?.length > 0 && (
                                                                <div className='badge-for-order me-2 p-1'>
                                                                    <LabelText classLabelName={'mb-0 me-1 ps-1 badge-for-offer-count'} displayText={offer.Usage?.length} />
                                                                    <LabelText classLabelName={'mb-0 '} displayText={"Usage"} />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xxl={3} md={6}>
                                                    <div className='d-flex flex-column justify-content-end align-items-end '>
                                                        <div className='d-flex'>
                                                            <h5 className=' fs-6 pe-2 mt-3  text-black fw-semibold ' style={{ whiteSpace: 'nowrap' }}>
                                                                Term Validity:
                                                            </h5>
                                                            <h5 className=' fs-6 mt-3 fw-lighter  text-black'>{offer.termValidity}</h5>
                                                        </div>
                                                        <div>
                                                            {
                                                                offer.validityTo !== 'Jan 01,0001' ?
                                                                    <h5 className='fs-6 text-black fw-lighter  '>{offer.validityFrom} {"-"} {offer.validityTo}</h5> :
                                                                    <h5 className='fs-6 text-black fw-lighter  '> Never Expiring</h5>
                                                            }
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xxl={1} md={6}>
                                                    <div>
                                                        <Button color="secondary" className='mt-3' onClick={() => handleSelectOffer(offer, navigate)}>
                                                            Select
                                                        </Button>
                                                    </div>
                                                </Col>
                                                <hr></hr>
                                            </Row>
                                        )
                                    ))}
                                </AccordionBody>
                            </AccordionItem>
                        )
                    ))}
                </>
            )}
        </Accordion>
    )
}