import { fetchCreatePartnerSuccess, fetchCreatePartnerFailure } from "./reducer"
import { toast } from "react-toastify"
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { PARTNER_CREATE_API } from "../../constants/constants";
import { setSuccessMessage } from "../../toastMessage/action";

export const createPartners = (jsonObj, setLoading, navigate) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${PARTNER_CREATE_API}`, 'POST', jsonObj).then((response) => {
        if (!response.error) {
            setLoading(false)
            dispatch(fetchCreatePartnerSuccess(response))
            navigate('/partner-listing')
            dispatch(setSuccessMessage(true))
        }
        if (response.error) {
            setLoading(false)
            toast.error(
                `${response.error.message}`
            )
            dispatch(fetchCreatePartnerFailure(response.error.message))
        }
    }).catch((error) => {
        setLoading(false);
        toast.error("An error occurred. Please try again later.");

    })
}