import { convertNumberOfDaysToMonthYear, getDayDiff } from "./DateFormat"
import { useLocation } from "react-router-dom"

export const statusData = (data, status) => {
    data.forEach((value, index) => {
        if (value.status === "CANCEL") {
            status[index] = "IN-ACTIVE"
        }
        else {
            status[index] = "ACTIVE"
        }
    })
    return status
}

export const termValidityData = (data, termValidity) => {
    data?.forEach((value, index) => {
        const validFrom = new Date(value?.validFrom);
        const validTo = new Date(value?.validTo);

        if (validTo.toISOString() === "0001-01-01T00:00:00.000Z") {
            termValidity[index] = "Never Ending";
        } else {
            termValidity[index] = convertNumberOfDaysToMonthYear(getDayDiff(validFrom, validTo));
        }
    });
    return termValidity;
};
export const customerIdData = () => {
    const location = useLocation();
    const customerIdPath = location.pathname.slice(location.pathname.lastIndexOf("/"), location.pathname.length)
    const customerId = customerIdPath.slice(1, customerIdPath.length)
    return customerId
}

export const showPopUp = (id, setSubscriptionId, setOpenPopUp, openPopUp) => {
    setSubscriptionId(id)
    setOpenPopUp(!openPopUp)
    document.body.style.overflow = 'auto'
}

export const matchingOffersData = (offers, fetchSummaryData) => {
    const matchingOffers = offers?.filter(offer => {
        return fetchSummaryData?.resources?.some(resource => resource?.offerCode === offer?.offerCode)
    })
    return matchingOffers
}

export const urpOffersData = (matchingOffers) => {
    const urpOffers = matchingOffers
        ?.filter((offer) => offer?.usageRatePlan?.length > 0)
    return urpOffers
}
export const uomData = (offerCodeArray, offers) => {
    let uom = []
    offerCodeArray.forEach((value, index) => {
        offers.forEach((value, j) => {
            if (offerCodeArray[index] === offers[j].offerCode) {
                if ("usageRatePlan" in offers[j]) {
                    if (!uom.includes(offers[j].usageRatePlan[0].uomName)) {
                        uom.push(offers[j].usageRatePlan[0].uomName)
                    }
                }
            }
        })
    })
    return uom
}
export const uomOptions = (uom) => {
    let options = uom.map(item => {
        return {
            label: item,
            value: item
        };
    });
    return options
}

export const handleStartDateChange = (selectedDate, setStartDate) => {
    let startDateList = ''
    startDateList = selectedDate[0]
    setStartDate(startDateList)
}

export const subscriptionListData = (customerData, subscriptionList) => {
    if ("subscriptionList" in customerData) {
        subscriptionList = customerData.subscriptionList
    }
    else {
        subscriptionList = []
    }
    return subscriptionList
}

export const subscriptionsaArrayData = (subscriptionsArray, subscriptionList) => {
    const res = subscriptionList
    res.forEach((values, index) => {
        values?.subscribedOffers?.forEach(offer => {
            subscriptionsArray.push(offer);
        });
    })
    return subscriptionsArray
}

export const offerIDFunction = (offerData) => {
    return offerData?.map(
        (offer) => offer.offerCode
    ) ?? [];
}

export const optionOfferFunction = (offerID) => {
    return offerID.map((offerr) => ({
        value: offerr,
        label: offerr,
    }));
}

