import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, Container } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { Row, Col, Button } from 'reactstrap'
import { MaterialReactTable } from 'material-react-table';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllService } from '../../../slices/services/thunk';
import { useColumns } from './constants/coloumns';
import { Loader } from '../../Utils/Loader';
import moment from 'moment';
import { handleExportData } from '../../Utils/ExportToCsv';
import { setSuccessMessage } from '../../../slices/toastMessage/action';
import { ToastContainer, toast } from 'react-toastify';
import { exportDataForService } from './constants/exportDataForService';
import { PAGE_TITLES } from '../../Common/constants';

function ServicesListings() {
    const [services, setServices] = useState([])
    const [loading, setLoading] = useState(false)
    const allServiceData = [];
    const allStartDate = [];
    const allEndDate = [];
    const allModifyDate = [];
    const isBundle = [];
    const isProvisioning = [];
    const isUsageAttribute = [];
    let navigate = useNavigate()
    const dispatch = useDispatch();
    const showSuccessMessage = useSelector((state) => state.SuccessToast.showSuccessMessage)

    const permissions = useSelector((state) => state.permissions.permissions);
    const servicePermission = permissions.find((resource) => resource.resourceName === 'service');

    useEffect(() => {
        setLoading(true);
        dispatch(getAllService(setLoading))
    }, [dispatch])
    const serviceData = useSelector((state) => state.Services.services)
    useEffect(() => {
        if (Array.isArray(serviceData)) {
            serviceData.forEach((anotherElement) => {
                allServiceData.push(anotherElement);
            });
            setServices(allServiceData);
        }
    }, [serviceData]);

    const navigateToAddService = () => {
        let path = "/create-service"
        navigate(path)
    }
    const columns = useColumns(allStartDate, allEndDate, allModifyDate, isBundle, isProvisioning, isUsageAttribute);
    services.forEach((service, i) => {
        const EndDateObj = moment.utc(service?.Validity?.to);
        const endDate = EndDateObj.format('D MMM, YYYY');
        allEndDate[i] = endDate;
        const ModifiedDateObj = moment.utc(service?.modifiedDate);
        const modifyDate = ModifiedDateObj.format('D MMM, YYYY');
        allModifyDate[i] = modifyDate;
        const StartDateObj = moment.utc(service?.Validity?.from);
        const startTime = StartDateObj.format('D MMM, YYYY');
        allStartDate[i] = startTime;
        isBundle[i] = services[i]?.isBundle ? "Yes" : "No";
        isProvisioning[i] = services[i]?.provisioningAttributes ? "Provisioning" : "";
        isUsageAttribute[i] = services[i]?.usageAttributes ? "Usage" : "";
    });

    useEffect(() => {
        if (showSuccessMessage) {
            toast.success(`Service Created Successfully`);
            dispatch(setSuccessMessage(false))
        }
    }, [showSuccessMessage, dispatch])
    const exportData = exportDataForService(serviceData)
    return (
        <React.Fragment>
            <div id='csms' className='page-content'>
                <Container fluid>
                    <BreadCrumb title={PAGE_TITLES.SERVICE_LIST} pageTitle={PAGE_TITLES.PRICING} />
                    <ToastContainer position='top-center' />
                    <Row>
                        <Col lg={12}>
                            <div className="listingjs-table " id="customerList">
                                <Card>
                                    <CardBody>
                                        <Row className="mb-3 mt-1 table-responsive">
                                            {loading && (<Loader />)}
                                            {services ?
                                                <MaterialReactTable
                                                    columns={columns}
                                                    data={services}
                                                    enableColumnOrdering
                                                    enableColumnDragging
                                                    enableDensityToggle={false}
                                                    muiTableHeadCellProps={{
                                                        sx: {
                                                            fontFamily: "Inter,sans-serif"
                                                        }
                                                    }}
                                                    muiTableBodyCellProps={{
                                                        sx: {
                                                            fontFamily: "Inter,sans-serif"
                                                        }
                                                    }}
                                                    renderTopToolbarCustomActions={({ }) => (
                                                        <div className="d-flex flex-column flex-md-row align-items-md-center">
                                                            <Button color="secondary" className="btn-label me-2 mb-2 mb-md-0" onClick={() => { handleExportData(services) }}>
                                                                <i className="ri-upload-line label-icon align-middle fs-16 me-2 " ></i> Export Data
                                                            </Button>
                                                            {servicePermission && servicePermission.permissionList.includes('create') && <Button color="secondary" className="btn-label me-2" onClick={navigateToAddService}>
                                                                <i className="ri-add-fill label-icon align-middle fs-16 me-2"></i> Add Service
                                                            </Button>}
                                                        </div>
                                                    )}
                                                    icons={{
                                                        DragHandleIcon: () => <i className="ri-drag-move-fill" />,
                                                    }}
                                                />
                                                : null
                                            }
                                        </Row>
                                    </CardBody>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default ServicesListings;