export const userOptions = [
    {
        name: "status",
        value: "User",
        options: [
            { value: "active", label: 'Active' },
            { value: "inactive", label: 'Inactive' },
            
        ]
    },
    {
        name: "userrole",
        value: "User",
        options: [
            { value: "operatorAdmin", label: 'Operator Admin' },
            { value: "telcoAdmin", label: 'Telco Admin' },
            { value: "Customer", label: 'Customer' },

            
        ]
    },
]