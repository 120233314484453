import { toast } from "react-toastify";
import { fetchEditOperatorSuccess, fetchEditOperatorFailure } from "./reducer";
import { setEditSuccessMessage } from "../../toastMessage/action";
import { OPERATOR_CREATE_AND_EDIT_API } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";

export const editOperator = (jsonObj,operatorId, setLoading, navigate) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${OPERATOR_CREATE_AND_EDIT_API}/${operatorId}`, 'PATCH', jsonObj).then((response) => {
        if (response.error) {
            dispatch(fetchEditOperatorFailure(response.error.message))
            setLoading(false)
            toast.error(`${response.error.message}`)
        }
        if (!response.error) {
            setLoading(false)
            dispatch(fetchEditOperatorSuccess(response))
            navigate('/operator-listing');
            dispatch(setEditSuccessMessage(true))
        }
    }).catch((errorResp) => { })
};