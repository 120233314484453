import { Button } from "reactstrap"
import { handleExportData } from "../../Utils/ExportToCsv"
import { navigateToAddAttribute } from "./constants/AttributeJsonObj"

export const AttributeCustomButtons = ({ navigate, attributes }) => {
    return (
        <div className="d-flex flex-column flex-md-row align-items-md-center mb-3">
            <Button color="primary" className="btn-label me-2 mb-2 mb-md-0" onClick={() => { handleExportData(attributes) }}>
                <i className="ri-upload-line label-icon align-middle fs-16 me-2 " ></i> Export Data
            </Button>
            <Button color="primary" className="btn-label me-2" onClick={() => navigateToAddAttribute(navigate)}>
                <i className="ri-add-fill label-icon align-middle fs-16 me-2"></i> Add Attribute
            </Button>
        </div>
    )
}