import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllService } from '../../../slices/services/thunk';
import { fetchAllOffers } from '../../../slices/offer/thunk';
import { getAllResources } from '../../../slices/resources/thunk';
import moment from 'moment';
import { termValidity } from '../../Pricings/Offers/constants/checkEmptyFields';

export const offerServiceData = (setLoading) => {
    const dispatch = useDispatch();
    const [offerResponse, setOfferResponse] = useState([]);
    const [serviceResponse, setServiceResponse] = useState([]);
    const[resourceData,setResourceData]=useState("");

    useEffect(() => {
        dispatch(fetchAllOffers(setLoading));
        dispatch(getAllService(setLoading));
        dispatch(getAllResources(setLoading));
    }, [dispatch, setLoading]);

    const offer = useSelector((state) => state.Offer.offer);
    const serviceData = useSelector((state) => state.Services.services);
    const resource = useSelector((state) => state.Resources.resources);
    useEffect(() => {
        const sampleOfferResponse = offer;
        const sampleServiceResponse = serviceData;
        setOfferResponse(sampleOfferResponse);
        setServiceResponse(sampleServiceResponse);
        setResourceData(resource)
    }, [offer, serviceData,resource]);

    return { offerResponse, serviceResponse,resourceData };
}

export const groupedFunction = (offerResponse, serviceResponse,setResult) => {
    if (offerResponse.length > 0 && serviceResponse.length > 0) {
        const groupedResults = offerResponse.reduce((grouped, offer) => {
            const matchingService = serviceResponse.find((service) => service.serviceCode === offer.serviceCode);
            let provAttr = []
            if (matchingService) {
                serviceResponse.forEach((value, index) => {
                    if (offer.serviceCode === serviceResponse[index].serviceCode) {
                        provAttr.push(serviceResponse[index].provisioningAttributes)
                    }
                })
            }
            const serviceName = matchingService ? matchingService.name : '';
            if (!grouped[serviceName]) {
                grouped[serviceName] = [];
            }
            const validityFrom = moment(offer.validity.from).format('MMM DD,YYYY');
            const validityTo = moment(offer.validity.to).format('MMM DD,YYYY');
            const termValidityLabel = offer.subscriptionValidity && offer.subscriptionValidity.relativeEndTimeUnit
                ? `${termValidity(
                    offer.subscriptionValidity.relativeEndTimeUnit,
                    offer.subscriptionValidity.relativeEndTimeValue
                )?.props?.children[0]}${termValidity(
                    offer.subscriptionValidity.relativeEndTimeUnit,
                    offer.subscriptionValidity.relativeEndTimeValue
                )?.props?.children[1]}`
                : "Never Ending";
            const totalChargeAmount = offer.Charges ? offer.Charges.reduce((sum, charge) => sum + charge.amount, 0) : 0;
            const unitPrice = offer.Charges ? offer.Charges.reduce((sum, charge) => sum + charge.amount, 0) : 0;
            const subTotal = offer.Charges ? offer.Charges.reduce((sum, charge) => sum + charge.amount, 0) : 0;
            grouped[serviceName].push({
                offerCode: offer.offerCode,
                offerName: offer.offerName,
                validityFrom: validityFrom,
                validityTo: validityTo,
                termValidity: termValidityLabel,
                Grants: offer.Grants ? offer.Grants : [],
                Charges: offer.Charges ? offer.Charges : [],
                Usage: offer.usageRatePlan ? offer.usageRatePlan : [],
                serviceName: serviceName,
                provisioningAttributes: offer?.attributes ? offer?.attributes : [],
                totalChargeAmount: totalChargeAmount,
                unitPrice: unitPrice,
                subTotal: subTotal,
                description: offer.description
            });
            return grouped;
        }, {});
        setResult(Object.entries(groupedResults));
    }
}

export const filteredResultFunction = (result,searchTerm) => {
    return result.filter(([serviceName]) =>
        serviceName.toLowerCase().includes(searchTerm.toLowerCase())
    );
}