import React, { useEffect, useState } from "react";
import { Card, CardBody, Container, Row } from "reactstrap";
import { ToastContainer } from "react-toastify";
import { MaterialReactTable } from "material-react-table";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BreadCrumb from "../Common/BreadCrumb";
import { Loader } from "../Utils/Loader";
import { userColoumns } from "../UserManagement/Constant/UserManagementColoumn"; 
import { getAllUser } from "../../slices/UserManagement/thunk";
import { customerIdData } from "../Customers/constants/constantFunctionsView";

export default function  UserListingOperator() {
    const [loading, setLoading] = useState()
    const dispatch = useDispatch()
    const columns = userColoumns()
    const operatorId = customerIdData();
    useEffect(() => {
        dispatch(getAllUser(setLoading,operatorId));
    }, [dispatch]);
    const user=useSelector((state)=>state?.GetAllUser?.getAllUser)

    return (
        <React.Fragment>
            <div id='csms'>
                <Container fluid>
                                <Row className="table-responsive">
                                    {loading && (<Loader />)}
                                    {user?
                                        <MaterialReactTable
                                            icons={{ DragHandleIcon: () => <i className="ri-drag-move-fill" />, }}
                                            muiTableHeadCellProps={{
                                                sx: {
                                                    fontFamily: "Inter,sans-serif"
                                                }
                                            }}
                                            displayColumnDefOptions={{
                                                'mrt-row-actions': {
                                                    header: '',
                                                    enableHiding: true
                                                }
                                            }}
                                            muiTableBodyCellProps={{
                                                sx: {
                                                    fontFamily: "Inter,sans-serif"
                                                }
                                            }}
                                            columns={columns}
                                            data={user}
                                            enableColumnOrdering
                                            enableColumnDragging
                                            enableDensityToggle={false}
                                        /> : null}
                                </Row>                     
                </Container>
            </div>
        </React.Fragment>
    )
}