
import { mkConfig, generateCsv, download } from 'export-to-csv';

const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
});

export const handleExportData = (value) => {
    if (value.length > 0) {
        const csv = generateCsv(csvConfig)(value);
        download(csvConfig)(csv);
    }
};