import { fetchSearchCustomerFailure, fetchSearchCustomerSuccess } from "./reducer"
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { SEARCH_CUSTOMER_API } from "../../constants/constants";
import { toast } from "react-toastify";

export const getSearchedCustomer = (setLoading, msisdn, imsi, accountNumber, setGetTable, setErrorMessage, setErrorIcon) => async (dispatch) => {
    try {
        setLoading(true)
        const response = await determineBaseUrlAndPerformApiCall(`${SEARCH_CUSTOMER_API}?msisdn=${msisdn}&imsi=${imsi}&accountNumber=${accountNumber}`, 'GET').then((response) => {
            if (!response.error) {
                setLoading(false)
                setGetTable(true)
                dispatch(fetchSearchCustomerSuccess(response));
            }
            if (response.error) {
                setErrorMessage('No Customers Found')
                setErrorIcon('ri-user-unfollow-fill')
                setLoading(false)
                setGetTable(false)
            }
        })
    } catch (error) {
        dispatch(fetchSearchCustomerFailure(error.message));
    }
};

