import React from 'react';
import { Card, CardBody, Col, Row, FormGroup, CardHeader, Container } from 'reactstrap';
import formSchema from '../Utils/schema.json';
import { RenderField } from '../Utils/renderFields';
import { RenderErrorMessage } from '../Utils/renderErrorMessages';
import { LabelText } from '../Utils/Input';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

export default function OperatorDetails({ isEdit, values, handleChange, handleBlur, errors, touched, setFieldTouched, setFieldValue, countries, setSelectedCountry, stateData, cityData, setSelectedState, operatorData }) {

    const renderStatusBadge = (status) => {
        switch (status) {
            case 'initialized':
                return <span className="badge bg-warning-subtle text-warning">Inactive</span>;
            case 'validated':
                return <span className="badge bg-success-subtle text-success">Active</span>;
            case 'closed':
                return <span className="badge bg-danger-subtle text-danger">Closed</span>;
            default:
                return null;
        }
    };

    return (
        <Container fluid>
            <div className="live-preview">
                {formSchema.map((section, sectionIndex) => (
                    <>
                        {section.title === "Operator" && section.sections &&
                            section.sections.map((subSection, subSectionIndex) => (
                                <Card key={subSectionIndex} className='mb-4'>
                                    {subSection?.sectionTitle &&
                                        <CardHeader className="align-items-center d-flex">
                                            <h4 className="card-title mb-0 flex-grow-1">
                                                {subSection?.sectionTitle}
                                                {subSection?.sectionTitle === "Business Profile" && operatorData?.status && isEdit && (
                                                    <span style={{ marginLeft: '10px' }}>{renderStatusBadge(operatorData?.status)}</span>
                                                )}
                                            </h4>                                    </CardHeader>}
                                    <CardBody>
                                        <Row className="gy-4" key={sectionIndex}>
                                            {subSection.fields.filter(field => {
                                                if (field.name === "country" && stateData) {
                                                    countries.push(stateData)
                                                }
                                                if (field.name === "state" && cityData) {
                                                    countries.push(cityData)
                                                }
                                                if (isEdit && (field.name === "Domain" || field.name === "username" || field.name === "email")) {
                                                    field.disable = true
                                                }
                                                else {
                                                    field.disable = false
                                                }
                                                if (field.name === "Status") {
                                                    return isEdit;
                                                }
                                                return true
                                            }).map((field, fieldIndex) => (
                                                <Col
                                                    xxl={field.name === "businessName" ? 6 : field.name === "Domain" ? 3 : field.name === "EndDate" ? 3 : field.name === "Address" ? 6 : field.name === "Address2" ? 6 : field.name === "Address"}
                                                    md={field.name === "businessName" ? 3 : 6}
                                                    lg={field.name !== "businessName" ? 3 : 6}
                                                    key={`${subSectionIndex}-${fieldIndex}`}
                                                >
                                                    <LabelText
                                                        htmlFor={field.name}
                                                        className={field.required ? "text-danger" : ""}
                                                        displayText={field.label}
                                                        important={field.required ? "*" : ""}
                                                    />
                                                    {field.type === 'phone' ? (
                                                        <FormGroup>
                                                            <PhoneInput
                                                                international
                                                                countryCallingCodeEditable={false}
                                                                className={`form-control ${touched[field.name] && errors[field.name] ? 'error-input' : ''}`}
                                                                placeholder={field.placeholder}
                                                                value={values[field.name]}
                                                                onChange={(value) => setFieldValue(field.name, value)}
                                                                onBlur={() => setFieldTouched(field.name, true)}
                                                                disabled={field?.autoload && field.isEdit}
                                                            />
                                                            {touched[field.name] && errors[field.name] && (
                                                                <div className="text-danger validation-font-size">
                                                                    {errors[field.name]}
                                                                </div>
                                                            )}
                                                        </FormGroup>
                                                    ) : (
                                                        <>
                                                            <RenderField
                                                                field={field}
                                                                handleChange={handleChange}
                                                                handleBlur={handleBlur}
                                                                values={values}
                                                                touched={touched}
                                                                errors={errors}
                                                                setFieldTouched={setFieldTouched}
                                                                setFieldValue={setFieldValue}
                                                                options={countries}
                                                                setSelectedCountry={setSelectedCountry}
                                                                setSelectedState={setSelectedState}
                                                            />
                                                            <RenderErrorMessage
                                                                field={field}
                                                                touched={touched}
                                                                errors={errors}
                                                            />
                                                        </>
                                                    )}
                                                </Col>
                                            ))}
                                        </Row>
                                    </CardBody>
                                </Card>
                            ))
                        }
                    </>
                ))}
            </div>
        </Container>
    );
}