import { Card, CardBody, CardHeader, Col, FormFeedback, FormGroup, Row } from "reactstrap";
import { LabelText } from "../Utils/Input";
import InputField from "../Utils/InputField";
import { useState } from "react";
import { handleBlurForAttributes, handleInputChange, handleKeyPress, handleSelectInputChange } from "./constants/TelcoSubmitFunctions";
import SelectField from "../Utils/SelectField";

export function NonSearchableAttributes({ Attributes, setNonSearchableValues, nonSearchableValues, nonSearchableFormErrorsValues, isEdit }) {
    const filteredIdentifiers = Attributes?.filter(identifier =>
        nonSearchableValues.some(searchableItem => searchableItem.name === identifier.name)
    );
    const whichToRender = isEdit ? filteredIdentifiers : Attributes
    return (
        <Row>
            <Col lg={12}>
                <Card>
                    <CardHeader>
                        <h4 className="card-title mb-0 flex-grow-1">Attributes</h4>
                    </CardHeader>
                    <CardBody className="card-body">
                        <Row className="gy-4">
                            {whichToRender?.map((value, index) => {
                                const attrValue = nonSearchableValues.find(attr => attr.name === value.name) || {};
                                const checkIfKeyIsPresentInArray = () => nonSearchableFormErrorsValues && nonSearchableValues && Object.keys(nonSearchableFormErrorsValues).some(key => nonSearchableValues.some(element => element.name === key));
                                const check = checkIfKeyIsPresentInArray()
                                const convertedValue = value?.values ? value?.values.map(item => ({
                                    label: item,
                                    value: item
                                })) : [];
                                return (
                                    <Col xxl={3} md={6}>
                                        <FormGroup>
                                            <LabelText important={value.mandatory && "*"} className={value.mandatory && "text-danger"} displayText={value.name} />
                                            {(value?.values === null || !value?.values?.length) ? <InputField
                                                type="text" onKeyDown={handleKeyPress}
                                                value={nonSearchableValues[index]?.value}
                                                maxLength={value?.maxValue}
                                                minLength={value?.minValue}
                                                handleChange={(e) => handleInputChange(value.name, e.target.value, setNonSearchableValues)}
                                                handleBlur={() => handleBlurForAttributes(value.name, setNonSearchableValues)}
                                                invalid={(value.mandatory && nonSearchableValues[index]?.value == "" && nonSearchableValues[index]?.touched) || (check && nonSearchableValues[index]?.value == "" && value.mandatory) || (value.mandatory && check && value.valueType !== "list" && nonSearchableValues[index]?.value.length < value.minValue)}
                                            /> : <SelectField
                                                className={(value.mandatory && nonSearchableValues[index]?.value == "" && nonSearchableValues[index]?.touched) || (check && nonSearchableValues[index]?.value == "" && value.mandatory) || false
                                                    ? 'error-input'
                                                    : ""}
                                                options={convertedValue}
                                                value={nonSearchableValues[index]?.value}
                                                handleChange={(e) => { handleSelectInputChange(value.name, e, setNonSearchableValues) }} />}
                                            {((value.mandatory && nonSearchableValues[index]?.value === "" && nonSearchableValues[index]?.touched) ||
                                                (check && nonSearchableValues[index]?.value === "" && value.mandatory)) ? (
                                                <p className="validation-font-size text-danger">Please fill out this field.</p>
                                            ) : (value.mandatory && check && value.valueType !== "list" && nonSearchableValues[index]?.value.length < value.minValue) ? (
                                                <p className="validation-font-size text-danger">Minimum length is {value.minValue} characters.</p>
                                            ) : (value.mandatory && check && value.valueType !== "list" && nonSearchableValues[index]?.value.length > value.maxValue) ? (
                                                <p className="validation-font-size text-danger">Maximum length is {value.maxValue} characters.</p>
                                            ) : null}
                                        </FormGroup>
                                    </Col>
                                )
                            })}
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}