export const PAGE_TITLES = {
    PRICING:"Pricing",
    UOM_LIST:"Uom List",
    ADD_UOM:"Add Uom",
    RESOURCE_LIST:"Resource List",
    ADD_RESOURCE:"Add Resource",
    SERVICE_LIST:"Service List",
    ADD_SERVICE:"Add Service",
    OFFER_LIST:"Offer List",
    ADD_OFFER:"Add Offer",
    EDIT_OFFER:"Edit Offer",
    CUSTOMER:"Customer",
    CUSTOMER_LIST:"Customer List",
    ADD_CUSTOMER:"Add Customer",
    EDIT_CUSTOMER:"Edit Customer",
    VIEW_CUSTOMER:"View Customer",
    CUSTOMER_ORDER:"Order List",
    ORDER:"Order",
    VIEW_ORDER:"View Order",
    INVOICE:"Invoice",
    CANCEL_ORDER:"Cancel Order",
    CUSTOMER_INVOICE:"Invoice List",
    CUSTOMER_SUBSCRIBE:"Subscribe",
    RESERVATION:"Reservation",
    RESERVATION_LIST:"Reservation List",
    ADD_RESERVATION:"Add Reservation",
    EDIT_RESERVATION:"Edit Reservation",
    STATION:"Station",
    STATION_LIST:"Station List",
    EDIT_STATION:"Edit Station",
    PARTNER:"Partner",
    PARTNER_LIST:"Partner List",
    SETTLEMENT_POLICY_LIST:"Settlement Policy List",
    ADD_PARTNER:"Add Partner",
    VIEW_PARTNER:"View Partner",
    EDIT_PARTNER:"Edit Partner",
    ADD_SETTLEMENT_POLICY:"Add Settlement Policy",
    EDIT_SETTLEMET_POLICY:"Edit Settlement Policy",
    DEVICE:"Device",
    DEVICE_LIST:"Device List",
    EDIT_DEVICE:"Edit Device",
    ADD_DEVICE:"Add Device",
    PAYMENT:"Payment",
    PAYMENT_HISTORY:"Payment History",
    POLICY:"Policy",
    ADD_POLICY:"Add Policy",
    UPDATE_POLICY:"Update Policy",
    MVNO_DASHBOARD:"Dashboard",
    USER:"User",
    USER_LIST:"User List",
    OPERATOR:"Operator",
    VIEW_OPERATOR:"View Operator",
    OPERATOR_LIST:"Operator List"
}