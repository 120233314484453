import { createSettlementPolicy } from "../../../slices/settlementPolicy/AddPolicy/thunk";
import { editPolicy } from "../../../slices/settlementPolicy/EditPolicy/thunk";

export const settlementPolicyPayload = (values, orderdata, partnerDataArray, isEdit, dispatch, setLoading, navigate, uniqueOfferCodes, partnerIdsss, partnerdata, prefixedPartnerArray, partnerJson, getPolicyData, allOffers, allPartners, mergedArray, mergedPartners) => {
    let commissionArray = []
    values?.commissionRules?.forEach((val, index) => {
        commissionArray.push({
            "commissionType": val.type.value,
            "commissionValue": parseInt(val.value),
            "chargeType": val.charge.value
        })
    })
    const uniqueOfferCode = new Set();
    const mergedEditArray = mergedArray.reduce((acc, obj) => {
        if (!uniqueOfferCode.has(obj.offerCode)) {
            uniqueOfferCode.add(obj.offerCode);
            acc.push(obj);
        }
        return acc;
    }, []);

    const offerEditPayload = Array.from(uniqueOfferCode);

    const operatorId = localStorage.getItem('operatorId');
    let partnerEditPayload,partnerEditPayloadArray
    if (isEdit) {
        const uniquePartners = new Set();
        const mergedArray = mergedPartners.reduce((acc, obj) => {
            if (!uniquePartners.has(obj.extractedId)) {
                uniquePartners.add(obj.extractedId);
                acc.push(obj);
            }
            return acc;
        }, []);
        partnerEditPayload = Array.from(uniquePartners);
        partnerEditPayloadArray = partnerEditPayload.map(element => `${operatorId}/${element}`);
        partnerDataArray = partnerdata.map(partner => partner.partnerId);
        prefixedPartnerArray = partnerDataArray.map(element => `${operatorId}/${element}`);
        let partnerArray = JSON.parse(partnerJson)
    }
    const jsonObj = JSON.stringify({
        "name": values.policyName,
        ...(isEdit && {
            "id": getPolicyData.id
        }),
        "precedence": parseInt(values.priority),
        "allOffers": allOffers,
        ...(!allOffers && { "offers": isEdit ? offerEditPayload : uniqueOfferCodes }),
        "allPartners": allPartners,
        ...(!allPartners && { "partners": isEdit ? partnerEditPayloadArray : partnerIdsss }),
        "commissions": commissionArray
    })
    if (!isEdit) {
        dispatch(createSettlementPolicy(jsonObj, setLoading, navigate))
    }
    else {
        dispatch(editPolicy(jsonObj, setLoading, navigate))
    }
}