import { Formik } from "formik"
import { Form, Card, Container, Row, CardBody, Col } from "reactstrap"
import { ToastContainer } from "react-toastify"
import BreadCrumb from "../Common/BreadCrumb"
import { useNavigate } from "react-router-dom"
import { validationSchemaForCommissionRules } from "./Constants/validationSchema"
import { createPolicyDataFunction, editPolicyDataFunction } from "./Constants/ModifyDataFunctions"
import { useLocation, } from "react-router-dom"
import { OfferList } from "./OfferList"
import { PartnerList } from "./PartnerList"
import CommonButton from "../Utils/CommonButton"
import { useDispatch } from "react-redux"
import { useState } from "react"
import { Loader } from "../Utils/Loader"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { getPolicy } from "../../slices/settlementPolicy/Getpolicy/thunk"
import { customerIdData } from "../Customers/constants/constantFunctionsView"
import { getAllService } from "../../slices/services/thunk"
import { settlementPolicyPayload } from "./Constants/PolicyJsonObj"
import { SettlementCommissionRules } from "./SettlementCommissionRules"
import { PolicyDetails } from "./PolicyDetails"
import { setInitialValues } from "./Constants/ModifyFilterColumns"
import { PAGE_TITLES } from "../Common/constants"

export const CreateEditPolicy = () => {
    let location, location1
    location = useLocation();
    const isEdit = location.pathname.includes("update-policy")
    const policyId = customerIdData()
    location1 = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const selectedOffers = location1?.state?.selectedOffers;
    const partnerId = location1?.state?.partnerId
    let values = location.state?.values
    let allOffers = location.state?.allOffers
    let allPartners = location.state?.selectAllPartners
    const [isAllOffersForEdit, setIsAllOffersForEdit] = useState(false)
    const [isAllPartnersForEdit, setIsAllPartnersForEdit] = useState(false)
    const [mergedArray, setMergedArray] = useState([])
    const [mergedPartners, setMergedPartners] = useState([]);

    const initialValues = {
        policyName: '',
        priority: '',
        commissionRules: []
    }
    useEffect(() => {
        setInitialValues(values, initialValues)
    }, [])

    let uniqueOfferCodes, partnerIdsss, getPolicyData, partnerJson, offerEditArray, prefixedPartnerArray, uniqueOffCodesEditPayload, partnerEditArray, partnerCreateArray, partnerIdArray
    if (!isEdit) {
        const partnerdatas = useSelector((state) => state.SelectedPartner.settlementPartner);
        const fetchPartner = useSelector((state) => state.Partner.partner)
        const orderData = useSelector((state) => state.SelectedOffer.settlementOffer);
        uniqueOfferCodes = createPolicyDataFunction(useSelector, uniqueOfferCodes, partnerIdsss, partnerIdArray, partnerdatas, fetchPartner, orderData)?.uniqueOfferCodes
        partnerIdsss = createPolicyDataFunction(useSelector, uniqueOfferCodes, partnerIdsss, partnerIdArray, partnerdatas, fetchPartner, orderData)?.partnerIdsss
        partnerIdArray = createPolicyDataFunction(useSelector, uniqueOfferCodes, partnerIdsss, partnerIdArray, partnerdatas, fetchPartner, orderData)?.partnerArray
        partnerCreateArray = createPolicyDataFunction(useSelector, uniqueOfferCodes, partnerIdsss, partnerIdArray, partnerdatas, fetchPartner, orderData)?.partnerIdArray
    }

    //UPDATE
    if (isEdit) {
        useEffect(() => {
            dispatch(getPolicy(policyId, setLoading, initialValues, setIsAllOffersForEdit, setIsAllPartnersForEdit))
            dispatch(getAllService(setLoading))
        }, [dispatch])
        getPolicyData = useSelector((state) => state.GetSettlementPolicy.getPolicy)
        const getPolicyData2 = useSelector((state) => state.GetSettlementPolicy.getPolicy)
        const fetchPartners = useSelector((state) => state.GetSettlementPolicy.partnerId)
        const serviceData = useSelector((state) => state.Services.services)
        const PartnerData = useSelector((state) => state.Partner.partner)
        const fetchOffers = useSelector((state) => state.Offer.offer)
        dispatch({ type: 'CLEAR_OFFERS_DUPLICATE' });
        offerEditArray = editPolicyDataFunction(partnerJson, partnerId, useSelector, selectedOffers, isAllOffersForEdit, isAllPartnersForEdit,getPolicyData2,fetchPartners,serviceData,PartnerData,fetchOffers).offerEditArray
        dispatch({ type: 'ADD_OFFER_DUPLICATE', payload: offerEditArray });
        dispatch({ type: 'CLEAR_PARTNERS_DUPLICATE' });
        partnerJson = editPolicyDataFunction(partnerJson, partnerId, useSelector, selectedOffers, isAllOffersForEdit, isAllPartnersForEdit,getPolicyData2,fetchPartners,serviceData,PartnerData,fetchOffers).partnerJson
        partnerEditArray = editPolicyDataFunction(partnerJson, partnerId, useSelector, selectedOffers, isAllOffersForEdit, isAllPartnersForEdit,getPolicyData2,fetchPartners,serviceData,PartnerData,fetchOffers).partnerEditArray
        dispatch({ type: 'ADD_PARTNER_DUPLICATE', payload: partnerEditArray });
        uniqueOffCodesEditPayload = editPolicyDataFunction(partnerJson, partnerId, useSelector, selectedOffers, isAllOffersForEdit, isAllPartnersForEdit,getPolicyData2,fetchPartners,serviceData,PartnerData,fetchOffers).uniqueOffCodesEditPayload
    }

    let partnerDataArray = []
    let offerDataArray = []
    const partnerdata = useSelector((state) => state.SelectedPartner.partnerselect);
    const orderdata = useSelector((state) => state.SelectedOffer.offersselect);

    return (
        <div>
            <Formik initialValues={initialValues} validationSchema={validationSchemaForCommissionRules} onSubmit={async (values) => {
                settlementPolicyPayload(values, orderdata, partnerDataArray, isEdit, dispatch, setLoading, navigate, uniqueOfferCodes, partnerIdsss, partnerdata, prefixedPartnerArray, partnerJson, getPolicyData, allOffers, allPartners, mergedArray, mergedPartners)
            }}>
                {({ handleChange, handleSubmit, values, touched, errors, setFieldTouched, setFieldValue, setTouched, handleBlur }) => (
                    <Form className="needs-validation" onSubmit={handleSubmit}>
                        <div>
                            <ToastContainer position="top-center" />
                            {loading && <Loader />}
                            <div className="page-content">
                                <Container fluid>
                                    <BreadCrumb title={isEdit ? PAGE_TITLES.EDIT_SETTLEMET_POLICY :PAGE_TITLES.ADD_SETTLEMENT_POLICY} pageTitle={PAGE_TITLES.SETTLEMENT_POLICY_LIST} />
                                    <Row>
                                        <Col lg={12}>
                                            <Card >
                                                <CardBody>
                                                    <PolicyDetails values={values} handleBlur={handleBlur} handleChange={handleChange} touched={touched} errors={errors} getPolicyData={getPolicyData} isEdit={isEdit} />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Card>
                                        <CardBody>
                                            <div className="d-flex justify-content-between align-items-center ">
                                                <PartnerList values={values} isEdit={isEdit} mergedPartners={mergedPartners} setMergedPartners={setMergedPartners} partnerId={isEdit ? partnerEditArray : partnerId} selectedOffers={isEdit ? offerEditArray : selectedOffers} />
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <Card>
                                        <CardBody>
                                            <div className="d-flex justify-content-between align-items-center ">
                                                <OfferList values={values} isEdit={isEdit} mergedArray={mergedArray} setMergedArray={setMergedArray} offerDataArray={offerDataArray} selectedOffers={isEdit ? offerEditArray : selectedOffers} />
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <SettlementCommissionRules values={values} setFieldValue={setFieldValue} handleChange={handleChange} setTouched={setTouched} initialValues={initialValues} touched={touched} errors={errors} />
                                    <Row lg={6} className=" justify-content-end mb-4">
                                        <div className="live-preview">
                                            <div className="d-flex justify-content-end gap-2 ">
                                                <CommonButton outline={true} className={"cancel-button-background"} color={"main-color"} type={"reset"} buttonAction={() => { navigate('/policy') }} buttonText={"Cancel"} />
                                                <CommonButton color={"main-color"} type={'submit'} buttonText={"Confirm"} />
                                            </div>
                                        </div>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}