import moment from "moment";
import { termValidity } from "../../Pricings/Offers/constants/checkEmptyFields";

export const groupedFunction = (offerResponse, serviceResponse,setResult,customerId,customerData,orderId) => {
    if (offerResponse.length > 0 && serviceResponse.length > 0) {
        const groupedResults = offerResponse.reduce((grouped, offer) => {
            const matchingService = serviceResponse.find((service) => service.serviceCode === offer.serviceCode);
            let provAttr = []
            if (matchingService) {
                serviceResponse.forEach((value, index) => {
                    if (offer.serviceCode === serviceResponse[index].serviceCode) {
                        provAttr.push(serviceResponse[index].provisioningAttributes)
                    }
                })
            }
            const serviceName = matchingService ? matchingService.name : '';
            if (!grouped[serviceName]) {
                grouped[serviceName] = [];
            }
            const validityFrom = moment(offer.validity.from).format('MMM DD,YYYY');
            const validityTo = moment(offer.validity.to).format('MMM DD,YYYY');
            const termValidityLabel = offer.subscriptionValidity && offer.subscriptionValidity.relativeEndTimeUnit
                ? `${termValidity(
                    offer.subscriptionValidity.relativeEndTimeUnit,
                    offer.subscriptionValidity.relativeEndTimeValue
                )?.props?.children[0]}${termValidity(
                    offer.subscriptionValidity.relativeEndTimeUnit,
                    offer.subscriptionValidity.relativeEndTimeValue
                )?.props?.children[1]}`
                : "Never Ending";
            const totalChargeAmount = offer.Charges ? offer.Charges.reduce((sum, charge) => sum + charge.amount, 0) : 0;
            const unitPrice = offer.Charges ? offer.Charges.reduce((sum, charge) => sum + charge.amount, 0) : 0;
            const subTotal = offer.Charges ? offer.Charges.reduce((sum, charge) => sum + charge.amount, 0) : 0;
            grouped[serviceName].push({
                offerCode: offer.offerCode,
                offerName: offer.offerName,
                validityFrom: validityFrom,
                validityTo: validityTo,
                termValidity: termValidityLabel,
                Grants: offer.Grants ? offer.Grants : [],
                Charges: offer.Charges ? offer.Charges : [],
                Usage: offer.usageRatePlan ? offer.usageRatePlan : [],
                serviceName: serviceName,
                provisioningAttributes: offer?.attributes ? offer?.attributes : [],
                totalChargeAmount: totalChargeAmount,
                unitPrice: unitPrice,
                subTotal: subTotal,
                description: offer.description,
                CustomerId: customerId,
                CustomerName: customerData?.firstName,
                orderId: orderId
            });
            return grouped;
        }, {});
        setResult(Object.entries(groupedResults));
    }
}

export const handleSelectOffer = (selectedOffer,navigate) => {
    navigate('/order-select', { state: { selectedOffer } });
};

export const filteredResultFunction = (result,searchTerm) => {
    return result.filter(([serviceName]) =>
        serviceName.toLowerCase().includes(searchTerm.toLowerCase())
    );
}

export const toggleAccordion = (open, setOpen) => {
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };
    return toggle
}