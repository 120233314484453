import React, { useState } from 'react';
import { Card, CardBody, Container, Form } from 'reactstrap';
import { Row, Col, Button } from 'reactstrap';
import BreadCrumb from '../Common/BreadCrumb';
import { useLocation, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { initialValues } from './constants/initialValues';
import { useFormData, validationForSelectedOrder } from './constants/Formdata';
import { useDeviceData } from './constants/UnassignedDevice';
import { PAGE_TITLES } from '../Common/constants';
import { handleFormSubmit } from './constants/OrderListingFunctions';
import { Loader } from '../Utils/Loader';
import { SelectOrderDetails } from './SelectOrderDetails';
import { SubscriptionDetails } from './SubscriptionDetail';
import { DateAndAttributeDetails } from './DateAndAttributeDetails';

function SelectedOrders() {
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const { selectedOffer } = location.state || {};
    const [startDate, setStartDate] = useState()
    const { formData, setFormData } = useFormData();
    const idArray = useDeviceData(setLoading);
    return (
        <React.Fragment>
            <div id='csms' className='page-content'>
                <Formik initialValues={initialValues} validationSchema={validationForSelectedOrder} onSubmit={(values) => handleFormSubmit(values, selectedOffer, formData, navigate)} >
                    {({ handleChange, handleSubmit, values, touched, setFieldTouched, setFieldValue, errors }) => (
                        <Form className="needs-validation" onSubmit={handleSubmit}>
                            <Container fluid>
                                {loading && (<Loader />)}
                                <BreadCrumb title={PAGE_TITLES.CUSTOMER_SUBSCRIBE} pageTitle={PAGE_TITLES.CUSTOMER_LIST} />
                                <Row lg={12}>
                                    <Col>
                                        <SelectOrderDetails selectedOffer={selectedOffer}/>
                                    </Col>
                                </Row>
                                <Row lg={12}>
                                    <Col>
                                        <Card>
                                            <CardBody>
                                                <h5 className='d-inline '>Subscription Details</h5>
                                                <SubscriptionDetails values={values} setFieldValue={setFieldValue} touched={touched} errors={errors} handleChange={handleChange} idArray={idArray} />
                                                <DateAndAttributeDetails values={values} selectedOffer={selectedOffer} setFieldTouched={setFieldTouched} setFieldValue={setFieldValue} setFormData={setFormData} setStartDate={setStartDate} initialValues={initialValues} startDate={startDate} formData={formData} />
                                            </CardBody>
                                        </Card>
                                        <Row className='mt-4'>
                                            <Col className='d-flex align-items-center justify-content-end mb-3'>
                                                <Button color='secondary' type='submit' className='me-2' >
                                                    Add Item
                                                </Button>
                                                <Button color='secondary' className='' onClick={() => navigate(`/order-filtering/${selectedOffer?.CustomerId}`)} >
                                                    Discard
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Container>
                        </Form>
                    )}
                </Formik>
            </div>
        </React.Fragment>
    );
}

export default SelectedOrders;
