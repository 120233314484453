import { Col, FormFeedback, FormGroup, Row } from "reactstrap"
import { LabelText } from "../Utils/Input"
import InputField from "../Utils/InputField"
import SelectField from "../Utils/SelectField"

export const SubscriptionDetails = ({values,touched,errors,handleChange,setFieldValue,idArray}) => {
    return (
        <Row lg={12}>
            <Col xxl={6} md={6} className='mt-4'>
                <FormGroup>
                    <LabelText htmlFor={'Subscription ID'} className={'text-danger'} displayText={'Subscription ID'} important={'*'} />
                    <InputField
                        name={"subscriptionID"}
                        placeholder={"Enter the Subscription ID "}
                        type={"text"}
                        className={"form-control"}
                        id={"code"}
                        value={values.subscriptionID}
                        invalid={touched.subscriptionID && errors.subscriptionID ? true : false}
                        handleChange={handleChange}
                    />
                    {touched.subscriptionID && errors.subscriptionID ? (
                        <FormFeedback type="invalid">{errors.subscriptionID}</FormFeedback>
                    ) : null}
                </FormGroup>
            </Col>
            <Col xxl={6} md={6} className='mt-4'>
                <FormGroup>
                    <LabelText htmlFor={'Select Device'} displayText={'Select Device'} />
                    <SelectField
                        name={"selectDevice"}
                        isClearable={true}
                        handleChange={(selectedDevice) => setFieldValue('selectDevice', selectedDevice)}
                        options={idArray}
                        placeholder={"Search a Device"}
                        value={values.selectDevice}
                    />
                </FormGroup>
            </Col>
        </Row>
    )
}