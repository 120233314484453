import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Container, FormFeedback, Input } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Row, Col, Button } from "reactstrap";
import { MaterialReactTable } from "material-react-table";
import { handleExportData } from "../Utils/ExportToCsv";
import { useNavigate } from "react-router-dom";
import {
    formatDate,
    convertNumberOfDaysToMonthYear,
    getDayDiff,
    duration,
} from "./constants/DateFormat";
import { setDefault, storeRow, tabChange } from "../Utils/SetDefaultRowData";
import Simulate from "./Simulate";
import {
    currencyDataFormat,
    noCurrencyArrayDataFormat,
    reservationDataFormat,
    subscriptionDataFormat,
    toggleAccordion,
    fetchCustomerDetails,
} from "./constants/constantFunctions";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomerList } from "../../slices/customer/customerlist/thunk";
import { fetchCustomerSummary } from "../../slices/customer/simulate/thunk";
import { getAllStation } from "../../slices/stations/thunk";
import { getAllReservation } from "../../slices/reservations/thunk";
import { fetchCustomer } from "../../slices/customer/fetchcustomer/thunk";
import { fetchCustomerEvents } from "../../slices/customer/events/thunk";
import { ToastContainer, toast } from "react-toastify";
import { CustomerPreview } from "./CustomerPreview";
import { customerColumns, customerSearchColumns } from "./constants/CustomerTableColumns";
import { CustomerListRowOptions } from "./CustomerListRowOptions";
import { Loader } from "../Utils/Loader";
import { setWelcomeToast } from "../../slices/welcomeToaster/action";
import {
    setSuccessMessage,
    setEditSuccessMessage,
} from "../../slices/toastMessage/action";
import Select from "react-select"
import { lists } from "./constants/constantValues"
import { getCustomerData, handleClearSearch, handleSearch, handleSelectChange, navigateToCreateCustomer, handleInputChange } from "./constants/ListingCustomerFunctions";
import { exportDataForCustomer } from "./constants/exportDataForCustomer";
import { PAGE_TITLES } from "../Common/constants";
import { CustomerTableCustomTableButtons } from "./CustomerTableCustomTableButtons";
import InputField from "../Utils/InputField";
import { TableForTelco } from "./TableForTelco"
import { TelcoHeaderForCustomer } from "./TelcoHeaderForCustomer"
import { fetchCustomerListForReservation } from "../../slices/reservations/customerlist/thunk"
import { searchCustomerJsonData } from "./constants/CustomerJsonObj";

export default function ListingCustomers() {
    const [loading, setLoading] = useState(false);
    const [renderSimulate, setRenderSimulate] = useState(false);
    const [index, setIndex] = useState(null);
    const [open, setOpen] = useState("1");
    const [activeTab, setActiveTab] = useState("0.1");
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [sorting, setSorting] = useState(false);
    const [rowCount, setRowCount] = useState(100);
    const [isError, setIsError] = useState(false);
    const [searchValue, setSearchValue] = useState();
    const [columnFilters, setColumnFilters] = useState([]);
    const [selectedValue, setSelectedValue] = useState();
    const [msisdn, setMsisdn] = useState('');
    const [imsi, setImsi] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [searchFieldError, setSearchFieldError] = useState('');
    const [getTable, setGetTable] = useState(false)
    const [errors, setErrors] = useState({ msisdn: '', imsi: '', accountNumber: '' });
    const [errorMessage,setErrorMessage] = useState('Search for customer using the parameters above.')
    const [errorIcon,setErrorIcon] = useState('ri-user-fill')

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const current = new Date();
    const dispatch = useDispatch();
    let navigate = useNavigate();
    let simulateOrPreview = "";
    let subscriptionsArray = [];
    let noCurrencyArray = [];
    let currencyArray = [];
    let reservationDataArray = [];
    let exportData
    const toggle = toggleAccordion(open, setOpen);
    const permissions = useSelector((state) => state.permissions.permissions);
    const showWelcomeToast = useSelector(
        (state) => state.welcomeToast.showWelcomeToast
    );
    const showSuccessMessage = useSelector(
        (state) => state.SuccessToast.showSuccessMessage
    );
    const showEditSuccessMessage = useSelector(
        (state) => state.SuccessToast.showEditSuccessMessage
    ); const reservationPermission = permissions.find((resource) => resource.resourceName === 'reservation');
    const stationPermission = permissions.find((resource) => resource.resourceName === 'station');
    const customerPermission = permissions.find((resource) => resource.resourceName === 'customer');
    const orderPermission = permissions.find((resource) => resource.resourceName === 'order');
    const allCustomerData = useSelector((state) => state.CustomerAllData.customerListForReservation)
    let list = lists
    const stationData = useSelector((state) => state.Stations.station);
    const reservationData = useSelector(
        (state) => state.Reservations.reservations
    );
    const customerData = useSelector(
        (state) => state.FetchCustomer.fetchCustomer
    );
    const fetchCustomerSummaryData = useSelector(
        (state) => state.Simulate.simulate
    );
    noCurrencyArray = noCurrencyArrayDataFormat(
        fetchCustomerSummaryData,
        noCurrencyArray
    );
    let attributeDataArray = customerData?.customerInfo?.attributeList || [];

    currencyArray = currencyDataFormat(fetchCustomerSummaryData, currencyArray);
    subscriptionsArray = subscriptionDataFormat(customerData, subscriptionsArray);
    reservationDataArray = reservationDataFormat(
        reservationData,
        customerData,
        stationData,
        reservationDataArray
    );
    const customers = useSelector((state) => state?.CustomerList?.customerList?.Customers);
    const TotalCustomers = useSelector((state) => state?.CustomerList?.customerList);
    const columns = customerColumns();
    useEffect(() => {
        if (!customerPermission?.autoload) {
            const [lastFilter] = [...columnFilters].slice(-1);
            if (lastFilter) {
                const selectedId = lastFilter.id === "Full Name" ? 'Name' : lastFilter.id;
                const value = lastFilter.value;

                setSelectedValue(selectedId);
                setSearchValue(value);

                dispatch(fetchCustomerList(setLoading, 1, TotalCustomers?.TotalCount, selectedId, value));
            } else {
                setSearchValue('');
                dispatch(fetchCustomerList(setLoading, 1, pagination.pageSize));
            }
        }
    }, [columnFilters]);
    useEffect(() => {
        if (!customerPermission?.autoload) {
            dispatch(fetchCustomerListForReservation(setLoading))
        }
    }, [])
    useEffect(() => {
        if (showWelcomeToast) {
            toast.success(`Welcome ${localStorage.getItem("userId")}`);
            dispatch(setWelcomeToast(false));
        }
        if (showSuccessMessage) {
            toast.success(`Customer Created Successfully`);
            dispatch(setSuccessMessage(false));
        }
        if (showEditSuccessMessage) {
            toast.success(`Customer Updated Successfully`);
            dispatch(setEditSuccessMessage(false));
        }
    }, [showSuccessMessage, showWelcomeToast, dispatch]);

    useEffect(() => {
       if(!customerPermission?.autoload){setIsLoading(true);
        setIsRefetching(true);
        const fetchData = async () => {
            setIsLoading(true);
            setIsRefetching(true);

            try {
                await dispatch(
                    fetchCustomerList(
                        setLoading,
                        pagination.pageIndex + 1,
                        pagination.pageSize,
                    )
                );
                setRowCount(customers.length);

            } catch (error) {
                setIsError(true);
            } finally {
                setIsLoading(false);
                setIsRefetching(false);
            }
        };
        fetchData();}
    }, [pagination.pageIndex, pagination.pageSize]);
    if (customers) {
        exportData = exportDataForCustomer(allCustomerData)
    }

    const searchCustomerData = useSelector((state) => state.SearchedCustomer.searchCustomer)
    const searchedCustomerDataJsonObj = searchCustomerJsonData(searchCustomerData)

    const searchColumns = customerSearchColumns()

    return (
        <React.Fragment>
            <div id="csms" className="page-content">
                <Container fluid>
                    <BreadCrumb title={PAGE_TITLES.CUSTOMER_LIST} pageTitle={PAGE_TITLES.CUSTOMER} />
                    <Row style={{ marginTop: '10px' }}>
                        <ToastContainer position="top-center" />
                        <Col lg={12}>
                            <div className="listingjs-table " id="customerList">
                                <Card>
                                    <CardBody>
                                        {!customerPermission?.autoload ?
                                            <Row className="mb-3 mt-1 table-responsive">
                                                {customers ? (
                                                    <MaterialReactTable
                                                        icons={{
                                                            DragHandleIcon: () => (
                                                                <i className="ri-drag-move-fill" />
                                                            ),
                                                        }}
                                                        renderTopToolbarCustomActions={({ }) => (
                                                            <CustomerTableCustomTableButtons navigate={navigate} customerPermission={customerPermission} exportData={exportData} />
                                                        )}
                                                        muiTableHeadCellProps={{
                                                            sx: {
                                                                fontFamily: "Inter,sans-serif",
                                                            },
                                                        }}
                                                        muiTableBodyCellProps={{
                                                            sx: {
                                                                fontFamily: "Inter,sans-serif",
                                                            },
                                                        }}
                                                        muiTableDetailPanelProps={{
                                                            sx: {
                                                                fontFamily: "Inter,sans-serif",
                                                            },
                                                        }}
                                                        enableGlobalFilter={false}
                                                        enableColumnOrdering={true}
                                                        enableColumnDragging={true}
                                                        enableDensityToggle={false}
                                                        columns={columns}
                                                        data={customers}
                                                        manualPagination={true}
                                                        onPaginationChange={setPagination}
                                                        {...(searchValue === '' ? { rowCount: TotalCustomers?.TotalCount } : { rowCount: TotalCustomers?.Count })}
                                                        positionGlobalFilter="right"
                                                        muiSearchTextFieldProps={{
                                                            placeholder: `Search ${customers.length} rows`,
                                                            sx: { minWidth: "300px" },
                                                            variant: "outlined",
                                                        }}
                                                        manualFiltering
                                                        onColumnFiltersChange={setColumnFilters}

                                                        enableGlobalFilterModes
                                                        state={{
                                                            isLoading,
                                                            ...(searchValue === '' ? { pagination } : {}),
                                                            showProgressBars: isRefetching,
                                                            columnFilters,
                                                            showColumnFilters: true
                                                        }}
                                                        displayColumnDefOptions={{
                                                            "mrt-row-actions": {
                                                                header: "",
                                                                enableHiding: true,
                                                            },
                                                        }}
                                                        enableRowActions
                                                        initialState={{
                                                            columnVisibility: {
                                                                "mrt-row-expand": false,
                                                            },
                                                        }}
                                                        renderRowActionMenuItems={({ closeMenu, row }) => (
                                                            <CustomerListRowOptions orderPermission={orderPermission} customerPermission={customerPermission}
                                                                navigate={navigate}
                                                                row={row}
                                                                closeMenu={closeMenu}
                                                                dispatch={dispatch}
                                                                setRenderSimulate={setRenderSimulate}
                                                                setLoading={setLoading}
                                                                simulateOrPreview={simulateOrPreview}
                                                                index={index}
                                                                setIndex={setIndex}
                                                                setActiveTab={setActiveTab}
                                                                fetchCustomerDetails={fetchCustomerDetails}
                                                                stationPermission={stationPermission}
                                                                reservationPermission={reservationPermission}
                                                                getCustomerData={getCustomerData}
                                                                storeRow={storeRow}
                                                                setDefault={setDefault} />
                                                        )}
                                                        renderDetailPanel={({ row }) => (
                                                            <React.Fragment>
                                                                {useEffect(() => {
                                                                    row.toggleExpanded(false);
                                                                }, [pagination])}
                                                                {renderSimulate ? (
                                                                    <Simulate row={row} />
                                                                ) : (
                                                                    <CustomerPreview
                                                                        customerData={customerData}
                                                                        row={row}
                                                                        activeTab={activeTab}
                                                                        setActiveTab={setActiveTab}
                                                                        tabChange={tabChange}
                                                                        open={open}
                                                                        setOpen={setOpen}
                                                                        toggle={toggle}
                                                                        noCurrencyArray={noCurrencyArray}
                                                                        currencyArray={currencyArray}
                                                                        fetchCustomerSummaryData={fetchCustomerSummaryData}
                                                                        reservationDataArray={reservationDataArray}
                                                                        duration={duration}
                                                                        subscriptionsArray={subscriptionsArray}
                                                                        convertNumberOfDaysToMonthYear={convertNumberOfDaysToMonthYear}
                                                                        formatDate={formatDate}
                                                                        getDayDiff={getDayDiff}
                                                                        current={current} />
                                                                )}
                                                            </React.Fragment>
                                                        )} />
                                                ) : (
                                                    <MaterialReactTable
                                                        renderTopToolbarCustomActions={({ }) => (
                                                            <CustomerTableCustomTableButtons navigate={navigate} customerPermission={customerPermission} exportData={exportData} />
                                                        )}
                                                        icons={{
                                                            DragHandleIcon: () => (
                                                                <i className="ri-drag-move-fill" />
                                                            ),
                                                        }}
                                                        muiTableHeadCellProps={{
                                                            sx: {
                                                                fontFamily: "Inter,sans-serif",
                                                            },
                                                        }}
                                                        muiTableBodyCellProps={{
                                                            sx: {
                                                                fontFamily: "Inter,sans-serif",
                                                            },
                                                        }}
                                                        muiTableDetailPanelProps={{
                                                            sx: {
                                                                fontFamily: "Inter,sans-serif",
                                                            },
                                                        }}
                                                        columns={columns}
                                                        data={[]}
                                                        enableRowActions
                                                        enableColumnOrdering={true}
                                                        enableColumnDragging={true}
                                                        enableDensityToggle={false}
                                                        displayColumnDefOptions={{
                                                            "mrt-row-actions": {
                                                                header: "",
                                                                enableHiding: true,
                                                            },
                                                        }}
                                                        enablePagination={false}
                                                        state={{
                                                            showColumnFilters: true
                                                        }}
                                                    />
                                                )}
                                            </Row>
                                            : <Row className="mb-3 mt-1 table-responsive">
                                                <TelcoHeaderForCustomer setErrorMessage={setErrorMessage} setErrorIcon={setErrorIcon} msisdn={msisdn} imsi={imsi} accountNumber={accountNumber} errors={errors} dispatch={dispatch} setLoading={setLoading} setGetTable={setGetTable} setMsisdn={setMsisdn} setImsi={setImsi} setAccountNumber={setAccountNumber} customerPermission={customerPermission} navigate={navigate} setErrors={setErrors} />
                                                <TableForTelco errorMessage={errorMessage} errorIcon={errorIcon} getTable={getTable} searchColumns={searchColumns} searchData={searchedCustomerDataJsonObj} orderPermission={orderPermission} customerPermission={customerPermission} navigate={navigate} dispatch={dispatch} setRenderSimulate={setRenderSimulate} setLoading={setLoading} simulateOrPreview={simulateOrPreview} index={index} setIndex={setIndex} setActiveTab={setActiveTab} fetchCustomerDetails={fetchCustomerDetails} stationPermission={stationPermission} reservationPermission={reservationPermission} getCustomerData={getCustomerData} storeRow={storeRow} setDefault={setDefault} renderSimulate={renderSimulate} pagination={pagination} customerData={customerData} activeTab={activeTab} tabChange={tabChange} setOpen={setOpen} toggle={toggle} noCurrencyArray={noCurrencyArray} currencyArray={currencyArray} fetchCustomerSummaryData={fetchCustomerSummaryData} reservationDataArray={reservationDataArray} duration={duration} subscriptionsArray={subscriptionsArray} convertNumberOfDaysToMonthYear={convertNumberOfDaysToMonthYear} formatDate={formatDate} getDayDiff={getDayDiff} current={current} />
                                            </Row>}
                                    </CardBody>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            {loading && <Loader />}
        </React.Fragment>
    );
}