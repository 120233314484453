import { toast } from "react-toastify";
import { onSubmit } from "./DocumentTableFunctions";

export const bdomDataFunction = () => {
    let bdomOptionData = Array.from({ length: 28 }, (_, index) => ({
        value: index + 1,
        label: index + 1
    }));
    return bdomOptionData;
}

export const toggleModal = (setModal, modal) => {
    setModal(!modal);
    document.body.style.overflow = 'auto'
};

export function toggleTab(tab, value, activeTab, passedSteps, setactiveTab, setPassedSteps, setprogressbarvalue) {
    if (activeTab !== tab) {
        var modifiedSteps = [...passedSteps, tab];

        if (tab >= 1 && tab <= 4) {
            setactiveTab(tab);
            setPassedSteps(modifiedSteps);
        }
    }
    setprogressbarvalue(value);
}

export const handleInputChange = (name, value, setAttributeValues) => {
    setAttributeValues(prevState =>
        prevState.map(attr =>
            attr.name === name ? { ...attr, value: value } : attr
        )
    );
};
export const handleSelectInputChange = (name, value, setAttributeValues) => {
    setAttributeValues(prevState =>
        prevState.map(attr =>
            attr.name === name ? { ...attr, value } : attr
        )
    );
};

export const handleBlurForAttributes = (name, setAttributeValues) => {
    setAttributeValues(prevState =>
        prevState.map(attr =>
            attr.name === name ? { ...attr, touched: true } : attr
        )
    );
};

export const validateForm = (Attributes,values) => {
    let formErrors = {};
    Attributes.forEach((identifier) => {
        const value = values.find(attr => attr.name === identifier.name)?.value || '';
        if (identifier.mandatory && !value) {
            formErrors[identifier.name] = "Please fill out this field.";
        }
    });
    return formErrors;
};

export const handleKeyPress = (event, valueType) => {
    const key = event.key;
    if (['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Enter'].includes(key)) {
        return;
    }

    if (valueType === "integer") {
        const isNumeric = /^[0-9]$/;
        if (!isNumeric.test(key)) {
            event.preventDefault();
        }
    } else {
        const isAlphanumeric = /^[a-zA-Z0-9]$/;
        if (!isAlphanumeric.test(key)) {
            event.preventDefault();
        }
    }
};


export const checkMandatoryFields = (attributes, values) => {
    return attributes.some(attr => attr.mandatory && values.find(val => val.name === attr.name)?.value === '');
};

export function checkConditions(values, attributes) {
    const results = {};
    attributes.forEach(attr => {
        const correspondingValue = values.find(val => val.name === attr.name);
        if (correspondingValue && attr.mandatory && correspondingValue.value === "") {
            results[attr.name] = false;
        } else {
            results[attr.name] = true;
        }
    });
    
    return results;
}

function arrayToObject(array, matchingAttribute) {
    let result = {};
    const matchingValue = matchingAttribute.value;
    if (array.includes(matchingValue)) {
        result.label = matchingValue;
        result.value = matchingValue;
    }
    return result;
}

export function updateValues(values, attributeNamesAndValues, attributes) {
    return values.map(searchableItem => {
        const matchingAttribute = attributeNamesAndValues?.find(attributeItem => attributeItem.name === searchableItem.name);
        if (matchingAttribute) {
            const attributeType = attributes?.find(attribute => attribute.name === searchableItem.name)?.valueType;
            const attributeValues = attributes?.find(attribute => attribute.name === searchableItem.name)?.values;
            const attributeValue = attributeType === "list" && Array.isArray(attributeValues)
                ? arrayToObject(attributeValues,matchingAttribute)
                : matchingAttribute.value.toString();
            return {
                ...searchableItem,
                value: attributeValue
            };
        }
        return searchableItem;
    });
}
