import { Card, CardHeader, CardBody, Row, Col, Label, Progress } from "reactstrap"
import { LabelText } from "../Utils/Input"

export const CustomerProfile = ({ customerData, noCurrencyArray, currencyArray, fetchCustomerSummaryData, formatDate, customerPermission }) => {
    return (
        <Row lg={12} >
            <Col lg={6}>
                <Card>
                    <CardHeader className="d-flex align-items-center">
                        <i className="ri-account-circle-line fs-3 me-2 invoice-percent-and-icon-color"></i>
                        <Label className="invoice-percent-and-icon-color fs-5 mt-2">Customer Details</Label>
                    </CardHeader>
                    <CardBody>
                        <Row lg={6}>
                            <Col lg={6} >
                                <div className="container">
                                    <div className="row">
                                        <div className="row mb-3" >
                                            <div className="col fs-5"><Label>{customerPermission?.autoload ? "Account Number" : "Customer Id"}</Label></div>
                                            <div> {customerData?.customerInfo?.customerId}</div>
                                        </div>
                                        <div className="row mb-3" >
                                            <div className="col fs-5"><Label>Firstname</Label></div>
                                            <div >{customerData?.customerInfo?.firstName}</div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col fs-5" ><Label>Lastname</Label></div>
                                            <div >{customerData?.customerInfo?.lastName}</div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="container">
                                    <div className="row" >
                                        <div className="row mb-3">
                                            <div className="col fs-5"><Label>Email</Label></div>
                                            <div >{customerData?.customerInfo?.email}</div>
                                        </div>
                                        <div className="row mb-3" >
                                            <div className="col fs-5"><Label>{customerPermission?.autoload ? "MSISDN" : "Contact No"}</Label></div>
                                            <div >{customerData?.customerInfo?.dialCode + '-' + customerData?.customerInfo?.mobile}</div>
                                        </div>
                                        {customerPermission?.autoload &&
                                            <div className="row mb-3">
                                                <div className="col fs-5"><Label>IMSI</Label></div>
                                                <div >{customerData?.customerInfo?.attributeList?.find(attr => attr.name === "imsi")?.value || "IMSI not found"}</div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
            <Col lg={6}>
                <Card>
                    <CardHeader className="d-flex align-items-center">
                        <i className="bx bx-map fs-3 me-2 invoice-percent-and-icon-color"></i>
                        <Label className="invoice-percent-and-icon-color fs-5 mt-2" >Address</Label>
                    </CardHeader>
                    <CardBody>
                        <Row lg={6}>
                            <Col lg={6}>
                                <div className="container">
                                    <div className="row" >
                                        <div className="row mb-3">
                                            <div className="col fs-5"><Label>Address 1</Label></div>
                                            <div >{customerData?.customerInfo?.addressLine1}</div>
                                        </div>
                                        <div className="row mb-3" >
                                            <div className="col fs-5"><Label>Address 2</Label></div>
                                            <div>{customerData?.customerInfo?.addressLine2}</div>
                                        </div>
                                        <div className="row mb-3" >
                                            <div className="col fs-5"><Label>Country</Label></div>
                                            <div >{customerData?.customerInfo?.country}</div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="container">
                                    <div className="row" >
                                        <div className="row mb-3" >
                                            <div className="col fs-5"><Label>State</Label></div>
                                            <div>{customerData?.customerInfo?.state}</div>
                                        </div>
                                        <div className="row mb-3" >
                                            <div className="col fs-5"><Label>City</Label></div>
                                            <div >{customerData?.customerInfo?.city}</div>
                                        </div>
                                        <div className="row mb-3" >
                                            <div className="col fs-5"><Label>Zip Code</Label></div>
                                            <div>{customerData?.customerInfo?.zipCode}</div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
            <Col lg={6}>
                <Card>
                    <CardHeader className="d-flex align-items-center">
                        <Label className="invoice-percent-and-icon-color fs-5">Non-Currency</Label>
                    </CardHeader>
                    <CardBody className="scrollable-view">
                        {noCurrencyArray.length === 0 ? <h5 className="d-flex justify-content-center align-items-center default-height" >No Records Found</h5> : noCurrencyArray.map((value, index) => (
                            <Card className='p-2' key={index}>
                                {value.type === "Prepaid" ?
                                    <div>
                                        <div className='d-flex justify-content-between'>
                                            <LabelText classLabelName={"fw-bold fs-4"} displayText={value.resourceName} />
                                            <LabelText classLabelName={"fw-bold"} displayText={`${typeof value.remainingBalance === 'number' ? value.remainingBalance === 1.7976931348623157e+308 ? '' : value.remainingBalance > 100000 ? (value.remainingBalance / 100000).toFixed(2) + ' lakh' : value.remainingBalance.toLocaleString() : ''} ${value.remainingBalance === 1.7976931348623157e+308 ? '' : 'Credits Left'}`} />
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            {value.consumedBalance ? (<LabelText displayText={`${value.consumedBalance === 1.7976931348623157e+308
                                                ? ''
                                                : value.consumedBalance !== undefined
                                                && `${value.consumedBalance} Credits Consumed`}`} />) : <LabelText displayText={"0 Credits Consumed"} />}
                                            <LabelText displayText={`${value.originalBalance === 1.7976931348623157e+308 ? "Unlimited Data" : value.originalBalance > 10000 ? (value.originalBalance / 100000).toFixed(2) + ' lakh' : value.originalBalance.toLocaleString()} Balance`} />
                                        </div>
                                        {value.originalBalance !== 1.7976931348623157e+308 && (
                                            <Progress max={value.originalBalance} value={value.consumedBalance ? value.consumedBalance : 0} ></Progress>
                                        )}
                                        <div className='d-flex justify-content-end mt-2'>
                                            <LabelText displayText={`${formatDate(value.validFrom)} - ${formatDate(value.validTo)}`} />
                                        </div>
                                    </div>
                                    : <div>
                                        <div className='d-flex justify-content-between'>
                                            <LabelText classLabelName={"fw-bold fs-4"} displayText={value.resourceName} />
                                            <LabelText classLabelName={"fw-bold"} displayText={`${typeof value.remainingBalance === 'number' ? value.remainingBalance === 1.7976931348623157e+308 ? '' : value.remainingBalance > 100000 ? (value.remainingBalance / 100000).toFixed(2) + ' lakh' : value.remainingBalance.toLocaleString() : ''} ${value.remainingBalance === 1.7976931348623157e+308 ? '' : 'Credits Left'}`} />
                                        </div>
                                        <div className='d-flex justify-content-end mt-2'>
                                            <LabelText displayText={`${formatDate(value.validFrom)} - ${formatDate(value.validTo)}`} />
                                        </div>
                                    </div>}
                            </Card>
                        ))}
                    </CardBody>
                </Card>
            </Col>
            <Col lg={6}>
                <Card>
                    <CardHeader className="d-flex align-items-center">
                        <Label className="invoice-percent-and-icon-color fs-5">Currency</Label>
                    </CardHeader>
                    <CardBody className="scrollable-view">
                        {currencyArray.length === 0 ? <h5 className="d-flex justify-content-center align-items-center default-height" >No Records Found</h5> :
                            currencyArray.map((value, index) => (
                                <div key={index}>
                                    <LabelText classLabelName={"fw-bold fs-4"} displayText={value.resourceName} />
                                    <div className='d-flex justify-content-between'>
                                        <div>
                                            <LabelText classLabelName={"fw-bold"} displayText={`${value.currencySymbol} `} />
                                            <LabelText classLabelName={"ms-1"} displayText={`${value.originalBalance}`} />
                                        </div>
                                        {fetchCustomerSummaryData.resources.filter((otherItem) =>
                                            otherItem.subscriptionId === value.subscriptionId &&
                                            otherItem.offerCode === value.offerCode).length !== 1
                                            ? (
                                                <LabelText classLabelName={"bg-success p-1 text-white rounded-2"} displayText={"Paid"} />
                                            )
                                            : null}
                                    </div>
                                </div>
                            ))
                        }
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}