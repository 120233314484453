import { useState } from "react";
import React from "react";
import { MaterialReactTable } from "material-react-table";
import { MenuItem } from '@mui/material';
import { useDispatch } from "react-redux";
import { cancelSubscription } from "../../slices/customer/cancelsubscription/thunk";
import { ToastContainer } from "react-toastify";
import { SubscriptionsFormatData, convertNumberOfDaysToMonthYear, getDayDiff } from "./constants/DateFormat";
import { subscriptionColumns } from "./constants/CustomerTableColumns";
import { subscriptionsJsonObj } from "./constants/CustomerJsonObj";
import { statusData, termValidityData, showPopUp, customerIdData } from "./constants/constantFunctionsView";
import { Loader } from "../Utils/Loader";
import CommonModal from "../Utils/CommonModal";
import { unsubscribeOffer } from "./constants/DocumentTableFunctions";

export default function ViewSubscription({ offer, customerData, subscriptionPermission }) {
    let termValidity = []
    let validFrom = []
    let validTo = []
    let status = []
    const [subscriptionId, setSubscriptionId] = useState('')
    const [messageForPopUp, setMessageForPopUp] = useState('')
    const [loading, setLoading] = useState(false)
    const [openPopUp, setOpenPopUp] = useState(false)
    const customerId = customerIdData()
    const dispatch = useDispatch()

    validFrom = SubscriptionsFormatData(offer, validFrom, "validFrom")
    validTo = SubscriptionsFormatData(offer, validTo, "validTo")
    status = statusData(offer, status)
    termValidity = termValidityData(offer, termValidity)

    const columns = subscriptionColumns(termValidity, validFrom, validTo, status)

    return (
        <React.Fragment>
            <ToastContainer position="top-center" />
            {offer ?
                <MaterialReactTable
                    columns={columns}
                    enableDensityToggle={false}
                    data={offer}
                    enableColumnOrdering
                    manualFiltering
                    enableColumnDragging
                    enableRowActions={(subscriptionPermission && subscriptionPermission.permissionList.includes("unsubscribe")) ? true : false}
                    icons={{
                        DragHandleIcon: () => <i className="ri-drag-move-fill" />,
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                            fontFamily: "Inter,sans-serif"
                        }
                    }}
                    muiTableBodyCellProps={{
                        sx: {
                            fontFamily: "Inter,sans-serif"
                        }
                    }}
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            header: '',
                            enableHiding: true
                        },
                    }}
                    renderRowActionMenuItems={({ closeMenu, row }) =>
                        [
                            subscriptionPermission && subscriptionPermission.permissionList.includes("unsubscribe") ? <MenuItem key="view" onClick={() => { setMessageForPopUp(`Are you sure you want to cancel this subscription "${row.original.offerName}"?`), showPopUp(row, setSubscriptionId, setOpenPopUp, openPopUp), closeMenu() }}>
                                <i className='ri  ri-delete-bin-5-line me-2 text-danger' />
                                Cancel Subscription
                            </MenuItem> : null
                        ]
                    }
                />
                : null
            }
            {openPopUp && (
                <CommonModal open={openPopUp} toggle={() => setOpenPopUp(!openPopUp)} messageForPopUp={messageForPopUp} buttonText={"Yes, Cancel It"} modalAction={() => { unsubscribeOffer(customerData, customerId, subscriptionId, setLoading, dispatch), setOpenPopUp(!openPopUp) }} />
            )}
            {loading && (<Loader />)}
        </React.Fragment>
    )
}