import { DEVICE_UPDATE_API } from "../../constants/constants"
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor"
import { setDocumentSuccessMessage } from "../../toastMessage/action"
import { fetchDocumentUploadFailure } from "../documentupload/reducer"
import { viewDoc } from "../documentview/thunk"
import { fetchDocumentUpdateFileFailure, fetchDocumentUpdateFileSuccess } from "./reducer"
import { toast } from "react-toastify";

export const documentUpdateFile = (setLoading, jsonObj, PartnerId, businessName, partnerOrCustomer, setUpdate, setFileSelected) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${DEVICE_UPDATE_API}`, 'PUT', jsonObj).then((response) => {
        if (!response.error) {
            setUpdate(false)
            setFileSelected(false)
            setLoading(false)
            dispatch(fetchDocumentUpdateFileSuccess(response))
            dispatch(viewDoc(setLoading, PartnerId, businessName, partnerOrCustomer))
            toast.success(
                'Logo Updated Successfully'
            )
            dispatch(setDocumentSuccessMessage(true))
        }
        if (response.error) {
            setLoading(false)
            dispatch(fetchDocumentUpdateFileFailure(`${response.error.message}`))
            toast.error(
                `${response.error.message}`
            )
            dispatch(fetchDocumentUploadFailure(response.error.message))
        }
    })
}