import { setSuccessMessage } from "../../toastMessage/action";
import { toast } from "react-toastify";
import { fetchCreateOperatorSucess, fetchCreateOperatorFailure } from "./reducer";
import { OPERATOR_CREATE_AND_EDIT_API } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";

export const createOperator = (jsonObj, setLoading, navigate) => (dispatch) => {
    setLoading(true)
    determineBaseUrlAndPerformApiCall(`${OPERATOR_CREATE_AND_EDIT_API}`, 'POST', jsonObj).then((response) => {
        if (!response.error) {
            setLoading(false)
            dispatch(fetchCreateOperatorSucess(response))
            navigate('/operator-listing');
            dispatch(setSuccessMessage(true))
        }
        if (response.error) {
            dispatch(fetchCreateOperatorFailure(response.error.message))
            setLoading(false)
            toast.error(`${response.error.message}`)
        }
    }).catch((errorResp) => { })
}
