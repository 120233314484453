import moment from "moment";

  export function extractedData(data) {
    return data.map(item => ({
      endDateTime: moment.utc(item.existsDuring.endDateTime).format("DD MMM, YYYY"),
      startDateTime: moment.utc(item.existsDuring.startDateTime).format("DD MMM, YYYY"),
      organizationType: item.organizationType,
      tradingName: item.tradingName,
      operatorId:item.operatorId,
      status:item.status
    }));
  }