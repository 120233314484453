import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Container } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Row, Col, Button } from "reactstrap";
import { MaterialReactTable } from "material-react-table";
import { handleExportData } from "../../Utils/ExportToCsv";
import { useNavigate } from "react-router-dom";
import { MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getAllResources } from "../../../slices/resources/thunk";
import { ResourcecolumnData } from "./constants/ColumnForResource";
import { deleteresource } from "../../../slices/resources/deleteresource/thunk";
import { ToastContainer, toast } from "react-toastify";
import { Loader } from "../../Utils/Loader";
import { setSuccessMessage } from "../../../slices/toastMessage/action";
import CommonModal from "../../Utils/CommonModal";
import { PAGE_TITLES } from "../../Common/constants";

function ResourcesListings() {
  const [resources, setResources] = useState([]);
  const [messageForPopUp, setMessageForPopUp] = useState('')
  const allResourceData = [];
  const isCurrency = [];
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const [resourceName, setResourceName] = useState("");
  const showSuccessMessage = useSelector((state) => state.SuccessToast.showSuccessMessage)
  const permissions = useSelector((state) => state.permissions.permissions);
  const resourcePermission = permissions.find((resource) => resource.resourceName === 'resource');
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const toggleModal = (id) => {
    setResourceName(id);
    setModal(!modal);
    document.body.style.overflow = 'auto'
  };
  useEffect(() => {
    dispatch(getAllResources(setLoading));
  }, [dispatch]);
  const resourceData = useSelector((state) => state.Resources.resources);
  useEffect(() => {
    resourceData.forEach((anotherElement) => {
      allResourceData.push(anotherElement);
    });
    setResources(allResourceData);
  }, [resourceData]);
  const navigateToAddResources = () => {
    let path = "/create-resources";
    navigate(path);
  };
  let columns = ResourcecolumnData(isCurrency);
  const handleDeleteResource = async (id) => {
    dispatch(deleteresource(id, setLoading, navigate));
    const updatedResources = resources.filter(
      (resource) => resource.name !== id.original.name
    );
    setResources(updatedResources);
  };
  resources.forEach((resource, index) => {
    if (resource && resource.isCurrency === true) {
      isCurrency[index] = "Monetary";
    } else {
      isCurrency[index] = "Non-Monetary";
    }
  });
  useEffect(() => {
    if (showSuccessMessage) {
      toast.success(`Resource Created Successfully`);
      dispatch(setSuccessMessage(false))
    }
  }, [showSuccessMessage, dispatch])
  return (
    <React.Fragment>
      <div id="csms" className="page-content">
        <Container fluid>
          <BreadCrumb title={PAGE_TITLES.RESOURCE_LIST} pageTitle={PAGE_TITLES.PRICING} />
          <ToastContainer position="top-center" />
          <Row>
            <Col lg={12}>
              <div className="listingjs-table" id="customerList">
                <Card>
                  <CardBody>
                    <Row className="mb-3 mt-1 table-responsive">
                      {loading && (<Loader />)}
                      {resources ? (
                        <MaterialReactTable
                          columns={columns}
                          data={resources}
                          enableDensityToggle={false}
                          enableColumnOrdering
                          enableColumnDragging
                          enableRowActions
                          muiTableHeadCellProps={{
                            sx: {
                              fontFamily: "Inter,sans-serif"
                            }
                          }}
                          muiTableBodyCellProps={{
                            sx: {
                              fontFamily: "Inter,sans-serif"
                            }
                          }}
                          icons={{
                            DragHandleIcon: () => <i className="ri-drag-move-fill" />,
                          }}
                          displayColumnDefOptions={{
                            'mrt-row-actions': {
                              header: '',
                              enableHiding: true
                            }
                          }}
                          renderTopToolbarCustomActions={({ }) => (
                            <div className="d-flex flex-column flex-md-row align-items-md-center">
                              <Button color="secondary" className="btn-label me-2 mb-2 mb-md-0" onClick={() => { handleExportData(resources) }}>
                                <i className="ri-upload-line label-icon align-middle fs-16 me-2 " ></i> Export Data
                              </Button>
                              {resourcePermission && resourcePermission.permissionList.includes('create') && <Button
                                color="secondary"
                                className="btn-label me-2"
                                onClick={navigateToAddResources}
                              >
                                <i className="ri-add-fill label-icon align-middle fs-16 me-2"></i>{" "}
                                Add Resource
                              </Button>}
                            </div>
                          )}
                          renderRowActionMenuItems={({ closeMenu, row }) => [
                            <MenuItem className='row-options'
                              key="view"
                              onClick={() => {
                                setMessageForPopUp(`Are you sure you want to Delete this resource "${row.original.name}"?`)
                                toggleModal(row);
                                closeMenu();
                              }}
                            >
                              <i className="ri ri-delete-bin-5-line me-2" />{" "}
                              Delete
                            </MenuItem>,
                          ]}
                        />
                      ) : null}
                    </Row>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <CommonModal messageForPopUp={messageForPopUp} toggle={toggleModal} open={modal} buttonText={"Yes, Delete It"} modalAction={() => {
          handleDeleteResource(resourceName), toggleModal();
        }} />
      </div>
    </React.Fragment>
  );
}
export default ResourcesListings;
