import { FieldArray } from "formik";
import { CardHeader,CardBody,Button,Table,FormGroup,Input,UncontrolledTooltip } from "reactstrap";
import { TablesWithoutBorders } from "../../../pages/Tables/BasicTables/BasicTablesCode";
import SelectField from "../../Utils/SelectField";
import InputField from "../../Utils/InputField";
import { LabelText } from "../../Utils/Input";
import { Link } from "react-router-dom";
import { removeRowsFromGrantsAndCharges } from "./constants/checkEmptyFields";
import { grantsAndChargesHandle, handleGrantsAndChargesRecurringFrequencyAndUsageOptions,handleGrantsAndChargesResourceValueAndUsageFields, handleInfiniteForGrantsAndCharges } from "./constants/GrantsAndRecurringFunctions";

export default function GrantsRecurring({
    values,
    errors,isEdit,
    recurringFrequencyOptions,
    handleProrateState,
    touched,
    setTouched,
    handleChange,
    prorateState,
    setRecurringInfinite,
    displayInfiniteForOptions,displayCeilingAndFloorForOptions,setRecurringFloor,setRecurringCeiling,
    resourceData,
    grantResourceOptions,
    recurringInfinite,
    recurringCeiling,
    recurringFloor,
    setFieldValue,
    setFieldTouched,
    infiniteOptions,
    isEmptyorInvalidFieldForGrantsAndCharges
}) {
    return (
        <FieldArray name="recurringGrants">
            {({ push, remove }) => (
                <>
                    <CardHeader className="d-flex justify-content-center  align-items-center">
                        <h4 className="card-title mb-0 flex-grow-1 me-2">New Grant</h4>
                        {!isEdit && <Button color="secondary" className="btn-label" onClick={() => {
                            const lastIndex = values.recurringGrants.length - 1;
                            if (lastIndex === -1 || !isEmptyorInvalidFieldForGrantsAndCharges(lastIndex, values.recurringGrants)) {
                                push({recurringFrequency:recurringFrequencyOptions[0], resourceType: '', resourceValue: "0", infinite: '', ceiling: '', floor: '',type: "2", prorate:prorateState });
                                setTouched({
                                    ...touched,
                                    recurringGrants: {
                                        ...touched.recurringGrants,
                                        [lastIndex]: {
                                            resourceType: false,
                                        },
                                    },
                                });
                            }
                        }}  > <i className="ri-add-fill label-icon align-middle fs-16 me-2"></i> Add Grants </Button>}
                    </CardHeader>
                    <CardBody>
                        <div className="live-preview">
                            <div className="table-responsize">
                                <div className="scrollable-view">
                                    <Table className=" table-borderless align-middle mb-0 ">
                                        <thead>
                                            <tr>
                                                <th scope="col" className={isEdit ? "label-disable-color-edit-offer":""}>Recurring Frequency</th>
                                                <th scope="col" className={isEdit ? "label-disable-color-edit-offer":""}>Resource Type<span className="text-danger"> *</span></th>
                                                <th scope="col">Resource Value</th>
                                                <th scope="col" className={isEdit ? "label-disable-color-edit-offer":""}>Infinite</th>
                                                <th scope="col" className={isEdit ? "label-disable-color-edit-offer":""}>Type of Resource</th>
                                                <th scope="col" className={isEdit ? "label-disable-color-edit-offer":""}>Ceiling</th>
                                                <th scope="col" className={isEdit ? "label-disable-color-edit-offer":""}>Floor</th>
                                                <th scope="col" className={isEdit ? "label-disable-color-edit-offer":""}>Prorate</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            {values.recurringGrants.length === 0 ? (<tr className="default-height"><td colSpan={9} className=" text-center">No Grants</td></tr>) :
                                                (values.recurringGrants.map((val, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                <div className="field-length">
                                                                    <SelectField
                                                                        name={`recurringGrants[${index}].recurringFrequency`}
                                                                        value={values.recurringGrants[index].recurringFrequency} isDisabled={isEdit ? true : false}
                                                                        handleChange={handleGrantsAndChargesRecurringFrequencyAndUsageOptions(values.recurringGrants[index].recurringFrequency,`recurringGrants[${index}].recurringFrequency`,setFieldValue,setFieldTouched)}
                                                                        defaultValue={recurringFrequencyOptions[0]}
                                                                        options={recurringFrequencyOptions}
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="field-length">
                                                                    <SelectField
                                                                        className={(
                                                                            touched.recurringGrants &&
                                                                            touched.recurringGrants[index] &&
                                                                            !values.recurringGrants[index].resourceType
                                                                        ) || false  ? 'error-input' : '' }
                                                                        name={`recurringGrants[${index}].resourceType`} isDisabled={isEdit ? true : false}
                                                                        value={values.recurringGrants[index].resourceType}
                                                                        handleChange={grantsAndChargesHandle(`recurringGrants[${index}].resourceType`,values.recurringGrants[index],displayInfiniteForOptions, displayCeilingAndFloorForOptions, setFieldValue, setFieldTouched, index, resourceData, setRecurringInfinite, setRecurringCeiling, setRecurringFloor)}
                                                                        options={grantResourceOptions} />
                                                                </div>
                                                                {errors.recurringGrants &&
                                                                    errors.recurringGrants[index] &&
                                                                    touched.recurringGrants &&
                                                                    touched.recurringGrants[index] &&
                                                                    touched.recurringGrants[index].resourceType ? (
                                                                    <p className="text-danger">
                                                                        {errors.recurringGrants[index].resourceType}
                                                                    </p>
                                                                ) : null}
                                                            </td>
                                                            <td>
                                                                <InputField
                                                                    name={`recurringGrants[${index}].resourceValue`}
                                                                    className={'form-control field-length3'}
                                                                    placeholder="Enter Amount"
                                                                    type="number"
                                                                    handleChange={handleGrantsAndChargesResourceValueAndUsageFields(handleChange)}
                                                                    value={values.recurringGrants[index].resourceValue}
                                                                    invalid={
                                                                        errors.recurringGrants &&
                                                                            errors.recurringGrants[index] &&
                                                                            touched.recurringGrants &&
                                                                            touched.recurringGrants[index] &&
                                                                            touched.recurringGrants[index].resourceValue &&
                                                                            errors.recurringGrants[index].resourceValue  ? true : false
                                                                    }
                                                                />
                                                            </td>
                                                            {recurringInfinite[index] ?
                                                                <td>
                                                                    <div className="field-length">
                                                                        <SelectField
                                                                            name={`recurringGrants[${index}].infinite`}
                                                                            value={values.recurringGrants[index].infinite} isDisabled={isEdit ? true : false}
                                                                            handleChange={handleInfiniteForGrantsAndCharges(`recurringGrants[${index}].infinite`,values.recurringGrants[index],setFieldValue)}
                                                                            options={infiniteOptions} />
                                                                    </div>
                                                                </td>
                                                                : <td />}
                                                            <td>
                                                                <InputField className={`field-length2 ${isEdit ? "label-disable-color-edit-offer":""}`}value={"Non-Currency"} disabled={true} />
                                                            </td>
                                                            {recurringCeiling[index] || recurringCeiling[index] === 0 ?
                                                                <td>
                                                                    <InputField className={`field-length3 ${isEdit ? "label-disable-color-edit-offer":""}`} value={values.recurringGrants[index].ceiling} disabled={true} />
                                                                </td>
                                                                : <td />
                                                            }
                                                            {recurringFloor[index] || recurringFloor[index] === 0 ?
                                                                <td>
                                                                    <InputField className={`field-length3 ${isEdit ? "label-disable-color-edit-offer":""}`} value={values.recurringGrants[index].floor} disabled={true} />
                                                                </td>
                                                                : <td />
                                                            }
                                                            {values.recurringGrants[index].recurringFrequency?.label !== "Monthly" ?
                                                                <td>
                                                                    <FormGroup switch className="d-flex align-items-start switch-state">
                                                                        <div className="me-2">
                                                                            <Input
                                                                                type={"switch"}
                                                                                disabled={isEdit ? true : false}
                                                                                onClick={() => handleProrateState(index,values)}
                                                                            />
                                                                        </div>
                                                                        <div className="flex-column">
                                                                            <LabelText id={"UncontrolledTooltipExample"} htmlFor={"globalOffer"} className={"text-danger"} displayText={"Prorate"} check />
                                                                            <UncontrolledTooltip target={"UncontrolledTooltipExample"} >Enable rating user based on their service consumption</UncontrolledTooltip>
                                                                        </div>
                                                                    </FormGroup>
                                                                </td> : <td />
                                                            }
                                                            {!isEdit && <td>
                                                                <div className="hstack gap-3 fs-15">
                                                                <Link to="#" className="link-danger" onClick={() => {
                                                                        remove(index)
                                                                        removeRowsFromGrantsAndCharges(setRecurringInfinite, index, setRecurringCeiling, setRecurringFloor)
                                                                    }} ><i data-testid="delete" className="ri-delete-bin-5-line"></i></Link>
                                                                </div>
                                                            </td>}
                                                        </tr>
                                                    )
                                                }))}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                        <div className="d-none code-view">
                            <pre className="language-markup default-height">
                                <code>
                                    <TablesWithoutBorders />
                                </code>
                            </pre>
                        </div>
                    </CardBody>
                </>
            )}
        </FieldArray>
    )
}