import { Button, CardBody, FormFeedback } from "reactstrap"
import InputField from "../Utils/InputField"
import { handleClearSearch, handleInputChange, handleSearch, navigateToCreateCustomer } from "./constants/ListingCustomerFunctions"

export const TelcoHeaderForCustomer = ({ msisdn, imsi, accountNumber, errors, dispatch, setLoading, setGetTable, setMsisdn, setImsi, setAccountNumber, customerPermission, navigate, setErrors, setErrorMessage, setErrorIcon }) => {
    return (
        <CardBody>
            <div className="row align-items-center">
                <div className="col-12 col-md-8 d-flex flex-wrap align-items-center">
                    <div className="col-12 col-md mb-2 me-md-3">
                        <InputField
                            placeholder="Enter MSISDN"
                            value={msisdn}
                            handleChange={(e) => handleInputChange('msisdn', e.target.value, setErrors, setMsisdn, setImsi, setAccountNumber)}
                            className={`form-control ${errors.msisdn ? 'is-invalid' : ''}`}
                        />
                        {errors.msisdn && <FormFeedback>{errors.msisdn}</FormFeedback>}
                    </div>
                    <div className="col-12 col-md mb-2 me-md-3">
                        <InputField
                            placeholder="Enter IMSI"
                            value={imsi}
                            handleChange={(e) => handleInputChange('imsi', e.target.value, setErrors, setMsisdn, setImsi, setAccountNumber)}
                            className={`form-control ${errors.imsi ? 'is-invalid' : ''}`}
                        />
                        {errors.imsi && <FormFeedback>{errors.imsi}</FormFeedback>}
                    </div>
                    <div className="col-12 col-md mb-2 me-md-3">
                        <InputField
                            placeholder="Enter Account Number"
                            value={accountNumber}
                            handleChange={(e) => handleInputChange('accountNumber', e.target.value, setErrors, setMsisdn, setImsi, setAccountNumber)}
                            className={`form-control ${errors.accountNumber ? 'is-invalid' : ''}`}
                        />
                        {errors.accountNumber && <FormFeedback>{errors.accountNumber}</FormFeedback>}
                    </div>
                    <div className="col-12 col-md-auto mb-2 me-md-3">
                        <Button
                            onClick={() => handleSearch(dispatch, setLoading, msisdn, imsi, accountNumber, setGetTable,setErrors, setErrorMessage, setErrorIcon)}
                            disabled={!msisdn && !imsi && !accountNumber}
                            color='main-color'
                            className="btn w-100"
                        >
                            Search
                        </Button>
                    </div>
                    <div className="col-12 col-md-auto mb-2 me-md-3">
                        <Button
                            onClick={() => handleClearSearch(setMsisdn, setImsi, setAccountNumber, dispatch, setLoading, setGetTable,setErrors, setErrorMessage, setErrorIcon)}
                            outline={true} className={"cancel-button-background btn w-100"} color={"main-color"}
                            style={{ flex: 'none' }}
                        >
                            Clear
                        </Button>
                    </div>
                </div>
                <div className="col-12 col-md-auto mb-2 ms-md-auto">
                    {customerPermission && customerPermission.permissionList.includes('create') &&
                        <Button
                            color="secondary"
                            className="btn-label me-2 w-100"
                            onClick={() => navigateToCreateCustomer(navigate)}
                        >
                            <i className="ri-add-fill label-icon align-middle fs-16 me-2"></i>
                            New Customer
                        </Button>
                    }
                </div>
            </div>

        </CardBody>
    )
}