import { fetchSimulateFailure, fetchSimulateSuccess, fetchOfferSuccess } from "./reducer";
import moment from "moment";
import {toast} from "react-toastify"
import { BALANCE_API } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";

export const fetchCustomerSummary = (id, setLoading, setRenderSimulate, simulateOrPreview) => async (dispatch) => {
    setLoading(true)
    const time = moment().format('YYYY-MM-DDTHH:mm:ss[Z]')
    let userId = ""
    if(simulateOrPreview === "View"){
        userId = id
    }
    else{
        userId = id.original.CustomerId
    }
    await determineBaseUrlAndPerformApiCall(`${BALANCE_API}/${userId}?mode=summary&startTime=${time}`,'GET').then((response) => {
        if (response.error) {
            dispatch(fetchSimulateSuccess(response))
            if (simulateOrPreview === "Simulate") {
                setLoading(false)
                setRenderSimulate(false)
                id.toggleExpanded(false)
                toast.error(`${response.error.message}`)
                dispatch(fetchSimulateFailure(response.error.message))
            }
        }
        if (!response.error) {
            if(simulateOrPreview === "View"){
                userId =id
            }
            else{
                id.toggleExpanded(true)
            }
            dispatch(fetchSimulateSuccess(response))
            let offerCode = [];
            response.resources.forEach((value, index) => {
                offerCode.push(response.resources[index].offerCode)
            })
            dispatch(fetchOfferSuccess(offerCode))
            
            if (simulateOrPreview === "Simulate") {
                setLoading(false)
                setRenderSimulate(true)
            }
            if(simulateOrPreview === "Preview"){
                setLoading(false)
                setRenderSimulate(false)
            }
        }
    }).catch((errorResp) => {})
}