import React from 'react';
import { Row, Col, Card, CardBody, CardHeader, FormGroup, FormFeedback, Button } from 'reactstrap';
import { LabelText } from '../Utils/Input';
import InputField from '../Utils/InputField';
import SelectField from '../Utils/SelectField';
import { handleChangeForCity, handleChangeForCountry, handleChangeForState } from "../Customers/constants/HandleChangeFunctions";
import { TypeOptions } from './Constants/PartnerUtils';
const BusinessProfileForm = ({
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    setFieldValue,
    initialValues,
    options,
    setSelectedCountry,
    setFieldTouched,
    setSelectedState,
    setSelectedCity,
    setCities,
    optionState,
    optionCity,
    handleFileChange,
    fileInputRef,
    isCreate,
    handleRemoveLogo,
    logoFile,
    profile,
    imageOn
}) => {
    return (
        <Row>
            <Col lg={12}>
                <Card>
                    <CardBody className="card-body">
                        <div className="live-preview">
                            <Row className="gy-1">
                                <CardHeader >
                                    <h4 className="card-title flex-grow-1">Business Profile</h4>
                                </CardHeader>
                                <Col xxl={9}>
                                    <Row className=' mt-2 '>
                                        <Col xxl={8}>
                                            <div>
                                                <FormGroup >
                                                    <LabelText htmlFor={"businessName"} className={"text-danger"} displayText={"Business Name"} important={"*"} />
                                                    <InputField name={"businessName"} placeholder={"Enter your Business Name"} type={"text"} className={"form-control"} id={"businessName"} handleChange={handleChange} handleBlur={handleBlur} value={values.businessName}
                                                        invalid={touched.businessName && errors.businessName
                                                            ? true
                                                            : false} />
                                                    {touched.businessName &&
                                                        errors.businessName ? (
                                                        <FormFeedback type="invalid">
                                                            {errors.businessName}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </div>
                                        </Col>
                                        <Col xxl={4} >
                                            <div>
                                                <LabelText htmlFor={"choices-single-default"} className={"text-danger"} displayText={"Type"} important={"*"} />
                                                <SelectField className={(errors.type && touched.type) || false ? 'error-input' : ''}
                                                    value={values.type}
                                                    placeholder={"Select Type"}
                                                    handleChange={(selectedVal) => setFieldValue("type", selectedVal)}
                                                    isClearable={true}
                                                    options={[
                                                        ...TypeOptions,
                                                    ]} />
                                                {touched.type && errors.type ? (
                                                    <p className="text-danger">
                                                        {errors.type}
                                                    </p>
                                                ) : null}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='mt-2'>
                                        <Col xxl={4} >
                                            <div >
                                                <FormGroup >
                                                    <LabelText htmlFor={"email"} className={"text-danger"} displayText={"Email Address"} important={"*"} />
                                                    <InputField name={"email"} placeholder={"Enter your Email"} type={"email"} className={"form-control"} id={"email"} handleChange={handleChange} handleBlur={handleBlur} value={values.email}
                                                        invalid={touched.email && errors.email
                                                            ? true
                                                            : false} />
                                                    {touched.email &&
                                                        errors.email ? (
                                                        <FormFeedback type="invalid">
                                                            {errors.email}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </div>
                                        </Col>
                                        <Col xxl={4} >
                                            <div>
                                                <FormGroup >
                                                    <LabelText htmlFor={"mobile"} className={"text-danger"} displayText={"Phone Number"} important={"*"} />
                                                    <InputField name={"mobile"} placeholder={"Enter your Phone Number"} type={"number"} className={"form-control"} id={"mobile"} handleChange={handleChange} handleBlur={handleBlur} value={values.mobile}
                                                        invalid={touched.mobile && errors.mobile
                                                            ? true
                                                            : false} />
                                                    {touched.mobile &&
                                                        errors.mobile ? (
                                                        <FormFeedback type="invalid">
                                                            {errors.mobile}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </div>
                                        </Col>
                                        <Col xxl={4} >
                                            <div>
                                                <FormGroup >
                                                    <LabelText htmlFor={"websiteLink"} className={"text-danger"} displayText={"Website Link"} important={"*"} />
                                                    <InputField name={"websiteLink"} placeholder={"Enter your Website Link"} type={"text"} className={"form-control"} id={"websiteLink"} handleChange={handleChange} handleBlur={handleBlur} value={values.websiteLink}
                                                        invalid={touched.websiteLink && errors.websiteLink
                                                            ? true
                                                            : false} />
                                                    {touched.websiteLink &&
                                                        errors.websiteLink ? (
                                                        <FormFeedback type="invalid">
                                                            {errors.websiteLink}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xxl={2}>
                                    <FormGroup className='mt-2'>
                                        <LabelText htmlFor={"logo"} className={"text-danger"} displayText={"Logo"} />
                                        <div style={{ border: '1px dashed #ccc', padding: '38px', textAlign: 'center' }}>
                                            {logoFile && profile ? (
                                                <img src={imageOn && !profile ? URL.createObjectURL(logoFile) : logoFile} alt="Logo Preview" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                                            ) : (
                                                <label htmlFor="logoInput" style={{ cursor: 'pointer' }}>
                                                    <i className='ri-image-add-line align-middle me-1 fs-1'></i>
                                                </label>
                                            )}
                                            <input
                                                type="file"
                                                id="logoInput"
                                                name="logo"
                                                accept=".jpg, .jpeg, .png"
                                                style={{ display: 'none' }}
                                                onChange={handleFileChange}
                                                ref={fileInputRef}
                                                disabled={isCreate}
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col xxl={1} className='mt-2 p-0 text-center text-sm-center text-md-center text-lg-center text-xl-center align-self-center'>
                                    <Button onClick={handleRemoveLogo}>Remove</Button>
                                </Col>
                                <Col xxl={6} className=' mb-3'>
                                    <FormGroup >
                                        <LabelText htmlFor={"Address"} className={"text-danger"} displayText={"Address"} important={"*"} />
                                        <InputField name={"Address"} placeholder={"Enter your Address"} type={"text"} className={"form-control"} id={"Address"} handleChange={handleChange} handleBlur={handleBlur} value={values.Address}
                                            invalid={touched.Address && errors.Address
                                                ? true
                                                : false} />
                                        {touched.Address &&
                                            errors.Address ? (
                                            <FormFeedback type="invalid">
                                                {errors.Address}
                                            </FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col xxl={6} >
                                    <div>
                                        <LabelText htmlFor={"addressLine2"} className={"text-danger"} displayText={"Address 2"} />
                                        <InputField name={"addressLine2"} placeholder={"Enter your Address 2"} type={"text"} className={"form-control"} handleChange={handleChange} id={"addressLine2"} value={values.addressLine2} />
                                    </div>
                                </Col>
                                <Col xxl={3} >
                                    <div>
                                        <div>
                                            <LabelText htmlFor={"choices-single-default"} className={"text-danger"} displayText={"Country"} important={"*"} />
                                            <SelectField className={(errors.country && touched.country) || false ? 'error-input' : ''}
                                                value={initialValues.country ? options?.filter((country) => {
                                                    country.label === initialValues.country
                                                })
                                                    : values.country}
                                                placeholder={"Select your Country"}
                                                handleChange={handleChangeForCountry(setSelectedCountry, setFieldValue, setFieldTouched, setSelectedState, setSelectedCity, setCities)}
                                                isClearable={true}
                                                options={[
                                                    ...options,
                                                ]} />
                                            {touched.country && errors.country ? (
                                                <p className="text-danger">
                                                    {errors.country}
                                                </p>
                                            ) : null}
                                        </div>
                                    </div>
                                </Col>
                                <Col xxl={3} >
                                    <div>
                                        <FormGroup>
                                            <LabelText htmlFor={"choices-single-default"} className={"text-danger"} displayText={"State"} important={"*"} />
                                            <SelectField
                                                className={(errors.state && touched.state) ? 'error-input' : ''}
                                                value={initialValues.state ? optionState?.filter((state) => {
                                                    state.label === initialValues.state
                                                }) : values.state}
                                                placeholder="Select your State"
                                                handleChange={handleChangeForState(setSelectedState, setFieldValue)}
                                                options={[
                                                    ...optionState,
                                                ]}
                                                isClearable={true}
                                                isDisabled={!values.country}
                                            />
                                            {touched.state && errors.state ? (
                                                <p className="text-danger">
                                                    {errors.state}
                                                </p>
                                            ) : null}
                                        </FormGroup>
                                    </div>
                                </Col>
                                <Col xxl={3} >
                                    <div >
                                        <LabelText htmlFor={"choices-single-default"} className={"text-danger"} displayText={"City"} important={"*"} />
                                        <SelectField
                                            className={(errors.city && touched.city) || false ? 'error-input' : ''}
                                            value={initialValues.city ? optionCity?.filter((city) => {
                                                city.label === initialValues.city
                                            })
                                                : values.city}
                                            placeholder="Select your City"
                                            handleChange={handleChangeForCity(setSelectedCity, setFieldValue)}
                                            handleBlur={handleBlur}
                                            options={[
                                                ...optionCity,
                                            ]}
                                            isDisabled={!values.state}
                                            isClearable={true}
                                        />
                                        {touched.city && errors.city ? (
                                            <p className="text-danger">
                                                {errors.city}
                                            </p>
                                        ) : null}
                                    </div>
                                </Col>
                                <Col xxl={3} >
                                    <div>
                                        <FormGroup >
                                            <LabelText htmlFor={"zipCode"} className={"text-danger"} displayText={"Zip Code"} important={"*"} />
                                            <InputField name={"zipCode"} placeholder={"Enter your Zip Code"} type={"number"} className={"form-control"} id={"zipCode"} handleChange={handleChange} handleBlur={handleBlur} value={values.zipCode}
                                                invalid={touched.zipCode && errors.zipCode
                                                    ? true
                                                    : false} />
                                            {touched.zipCode &&
                                                errors.zipCode ? (
                                                <FormFeedback type="invalid">
                                                    {errors.zipCode}
                                                </FormFeedback>
                                            ) : null}
                                        </FormGroup>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};
export default BusinessProfileForm;
