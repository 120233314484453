import { DOCUMENT_UPLOAD_AND_EDIT_AND_DELETE_API } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { fetchPartnerDocumentUploadFailure, fetchPartnerDocumentUploadSuccess } from "./reducer";
import { setDocumentSuccessMessage } from "../../toastMessage/action";
import { toast } from "react-toastify";
import { viewDoc } from "../../customer/documentview/thunk";

export const partnerUploadDocument = (jsonObj, setLoading, PartnerId, businessName, partnerOrCustomer, setFileSelected) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${DOCUMENT_UPLOAD_AND_EDIT_AND_DELETE_API}`, 'POST', jsonObj).then(async (response) => {
        if (!response.error) {
            setLoading(false)
            setFileSelected(false)
            dispatch(fetchPartnerDocumentUploadSuccess(response))
            dispatch(viewDoc(setLoading, PartnerId, businessName, partnerOrCustomer))
            toast.success(
                'Logo Uploaded Successfully'
            )
            dispatch(setDocumentSuccessMessage(true))
        }
        if (response.error) {
            setLoading(false)
            toast.error(
                `${response.error.message}`
            )
            dispatch(fetchPartnerDocumentUploadFailure(response.error.message))
        }
        setLoading(false)
    }).catch((error) => {
        setLoading(false);
        toast.error("An error occurred. Please try again later.");
     })
}