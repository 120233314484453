import moment from "moment"
export const offerJsonObj = (values, selectedService, resourceData, state, globalState, serviceCode,serviceData,addOnState) => {
    let validityArray = {}
    let attributesArray = []
    let grantsArray = []
    let chargesArray = []
    let resourceIdArray = []
    let recurringFrequencyArray = []
    let resourceIdArrayForCharges = []
    let recurringFrequencyArrayForCharges = []
    let usageRatePlanArray = []
    let rateTier = []
    let serviceCodeForOffer = ""
    let selectService = []
    const roleName=localStorage.getItem('roleName')
    //telcoAdmin
    serviceData?.forEach((value, index) => {
        if (values?.serviceName?.value === value?.serviceCode) {
            serviceCodeForOffer = values?.serviceName?.value
            if ("provisioningAttributes" in value) {
                selectService.push(value?.provisioningAttributes)
            }
        }
    })
    let subscriptionValidityArray = {}
    const convertedStartDateTime = moment(values.offerStartsOn).startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]');
    validityArray.from = convertedStartDateTime
    if (!state) {
        const convertedEndDateTime = moment(values.offerEndsOn).endOf('day').format();
        const appendedZ = moment(convertedEndDateTime).utc().set({ hour: 23, minute: 59, second: 59 }).format();
        validityArray.to = appendedZ;
    }

    subscriptionValidityArray.startTimeType = values.subscriptionStartsOn.value
    subscriptionValidityArray.endTimeType = values.subscriptionEndsOn.value
    subscriptionValidityArray.relativeStartTimeUnit = parseInt(values.timePeriodOptionStart.value);
    subscriptionValidityArray.relativeStartTimeValue = parseInt(values.timePeriodStart)
    subscriptionValidityArray.relativeEndTimeUnit = parseInt(values.timePeriodOption.value);
    subscriptionValidityArray.relativeEndTimeValue = parseInt(values.timePeriod)

    let grantsMergedArray = [...values.grants, ...values.recurringGrants]
    let chargesMergedArray = [...values.charges, ...values.recurringCharges]

    const innerArray = selectService[0]
    innerArray?.forEach((value) => {
        attributesArray.push({
            "name": value.name,
            "value": value.default,
            "valueType": value.valueType
        })
    })

    resourceData.forEach((value, index) => {
        grantsMergedArray.forEach((val, idx) => {
            if (val.resourceType?.label === value.name) {
                resourceIdArray.push(resourceData[index].resourceId)
            }
        })
        chargesMergedArray.forEach((val, idx) => {
            if (val.resourceType?.label === value.name) {
                resourceIdArrayForCharges.push(resourceData[index].resourceId)
            }
        })
    })

    grantsMergedArray.forEach((val, index) => {
        recurringFrequencyArray.push(parseInt(val.recurringFrequency.value))
    })
    chargesMergedArray.forEach((val, index) => {
        recurringFrequencyArrayForCharges.push(parseInt(val.recurringFrequency.value))
    })

    grantsMergedArray.forEach((value, index) => {
        grantsArray.push({
            "amount": parseInt(value.resourceValue),
            "infinite": value?.infinite?.label === undefined ? "" : value?.infinite?.value,
            "prorate": value.prorate,
            "resourceName": value.resourceType.value,
            "resourceId": resourceIdArray[index],
            "type": parseInt(value.type),
            "recurringFrequency": recurringFrequencyArray[index]
        })
    })

    chargesMergedArray.forEach((value, index) => {
        chargesArray.push({
            "amount": parseInt(value.resourceValue),
            "infinite": value?.infinite?.label === undefined ? "" : value?.infinite?.value,
            "prorate": value.prorate,
            "resourceName": value.resourceType.value,
            "resourceId": resourceIdArrayForCharges[index],
            "type": parseInt(value.type),
            "recurringFrequency": recurringFrequencyArrayForCharges[index]
        })
    })

    values.usageRatePlan.forEach((value) => {
        const rateTier = [];
        const pricingModels = value.pricingModel.value === 'volume' && Array.isArray(value.pricingModelNew)
            ? value.pricingModelNew
            : [{}]; 
        pricingModels.forEach((pricingModel) => {
            rateTier.push({
                "flatRate": parseInt(value.flatRate),
                "id": 1,
                "maxQuantity": value.pricingModel.value === 'volume' ? parseInt(pricingModel.startingUnit) : 0,
                "minQuantity": value.pricingModel.value === 'volume' ? parseInt(pricingModel.endingUnit) : 0,
                "perUnitRate": value.pricingModel.value === 'volume' ? parseInt(pricingModel.pricePerUnit) : parseInt(value.perUnitRate),
                "tierType": value.pricingModel.value
            });
        });

        usageRatePlanArray.push({
            "eventType": value.eventType,
            "id": parseInt(value.usageId),
            "priority": parseInt(value.priority),
            "resourceName": value.resourceName.value,
            "uomName": value.unitOfMeasurement.value,
            "rateTier": rateTier
        });
    });
    const jsonObj = JSON.stringify({
        offerName: values.offerName,
        offerCode: values.offerCode,
        description: values.description,
        validity: validityArray,
        globalScope: globalState,
        addOnOffer:addOnState,
        serviceCode: serviceCodeForOffer,
        ...(values?.policyName && values?.policyName.value && { policySpecification: [values?.policyName?.value] }),
        ...(attributesArray.length !== 0 && {
            attributes: attributesArray,
        }),
        ...(grantsArray.length !== 0 && {
            Grants: grantsArray,
        }),
        ...(chargesArray.length !== 0 && {
            Charges: chargesArray,
        }),
        ...(usageRatePlanArray.length !== 0 && {
            usageRatePlan: usageRatePlanArray,
        }),
        subscriptionValidity: subscriptionValidityArray
    })
    return jsonObj;
}